import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CookieModule } from 'ngx-cookie';
import { CoreWebVitalsTrackingComponent } from './core-web-vitals-tracking.component';
import { FlTraceDirective } from './custom-sentry-tracing';
import { ErrorTrackingComponent } from './error-tracking.component';
import { HeartbeatTrackingComponent } from './heartbeat-tracking.component';
import { HotjarUserAttributesComponent } from './hotjar-user-attributes.component';
import { HotjarComponent } from './hotjar.component';
import { LongTaskTrackingComponent } from './long-task-tracking.component';
import { MemoryTrackingComponent } from './memory-tracking.component';
import { NavigationPerformanceTrackingComponent } from './navigation-performance-tracking.component';
import { PageViewTrackingComponent } from './page-view-tracking.component';
import { SpinnerHeartbeatDirective } from './spinner-heartbeat.directive';
import { SyntheticPerformanceTrackingComponent } from './synthetic-performance-tracking.component';
import { TrackingSectionDirective } from './tracking-section.directive';
import { TrackingComponent } from './tracking.component';
import { TrackingDirective } from './tracking.directive';
import { TranslationUsageTrackingComponent } from './translation-usage-tracking.component';
import { UserTimingTrackingComponent } from './user-timing-tracking.component';

@NgModule({
  imports: [HttpClientModule, CookieModule],
  declarations: [
    CoreWebVitalsTrackingComponent,
    ErrorTrackingComponent,
    HeartbeatTrackingComponent,
    HotjarComponent,
    HotjarUserAttributesComponent,
    LongTaskTrackingComponent,
    MemoryTrackingComponent,
    NavigationPerformanceTrackingComponent,
    PageViewTrackingComponent,
    SpinnerHeartbeatDirective,
    SyntheticPerformanceTrackingComponent,
    TrackingComponent,
    TrackingDirective,
    TrackingSectionDirective,
    TranslationUsageTrackingComponent,
    UserTimingTrackingComponent,
    FlTraceDirective,
  ],
  exports: [
    HeartbeatTrackingComponent,
    HotjarComponent,
    HotjarUserAttributesComponent,
    SpinnerHeartbeatDirective,
    TrackingComponent,
    TrackingDirective,
    TrackingSectionDirective,
    ErrorTrackingComponent,
    FlTraceDirective,
  ],
})
export class TrackingModule {}
