import {
  isPlatformBrowser,
  isPlatformServer,
  Location as NgLocation,
} from '@angular/common';
import {
  Inject,
  Injectable,
  Injector,
  Optional,
  PLATFORM_ID,
} from '@angular/core';
import type {
  NavigationExtras,
  UrlCreationOptions,
  UrlTree,
} from '@angular/router';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { TimeUtils } from '@freelancer/time-utils';
import { isEqual } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Request } from 'express';
import type { Observable, SubscriptionLike } from 'rxjs';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { REQUEST } from '../../express.tokens';
import {
  FREELANCER_LOCATION_AUTH_PROVIDER,
  FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER,
  FREELANCER_LOCATION_IN_APP_BROWSER_PROVIDER,
  FREELANCER_LOCATION_PWA_PROVIDER,
  FREELANCER_LOCATION_TRACKING_CONSENT_PROVIDER,
} from './location.config';

export interface LocationEvent {
  hash: string; // a '#' followed by the fragment identifier of the URL.
  hostname: string; // the domain of the URL
  href: string; // the entire URL
  origin: string; // the origin of the specific location (protocol + :// + host)
  pathname: string; // an initial '/' followed by the path of the URL
  search: string; // a '?' followed by the parameters or "querystring" of the URL
}

/*
 * Reactive & server-side friendly version of window.location
 * See https://developer.mozilla.org/en-US/docs/Web/API/Location
 *
 * NOTE: Keep this in sync with the LocationTesting service
 */
@UntilDestroy({ className: 'Location' })
@Injectable({
  providedIn: 'root',
})
export class Location {
  private initialHistoryState: {
    state: any;
    /**
     * The below is to say whether the initial state is a primary route such as
     * /login or /dashboard or /. The purpose of this behaviour is if the first
     * page the user accesses is none of the above pages, it will redirect back
     * to the primary route prior to exiting the app.
     */
    isPrimary: boolean;
  };
  private routeLoaded = false;
  private locationSubject$ = new ReplaySubject<LocationEvent>(1);

  constructor(
    private injector: Injector,
    private location: NgLocation,
    private router: Router,
    private timeUtils: TimeUtils,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request,
    @Inject(FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER)
    private freelancerLocationHttpBaseUrl: string,
  ) {}

  /**
   * Emits a stream of LocationEvent
   */
  valueChanges(): Observable<LocationEvent> {
    return this.locationSubject$.asObservable();
  }

  /**
   * Pushes a fake state to absorb a back button click.
   * Returns a subscription for calling `onPop` after the state is popped.
   *
   * PRIVATE: this is for modals/callouts and should not be needed in app code.
   * Discuss with Frontend Infrastructure if you need back button interactions.
   */
  _createBackButtonState({ onPop }: { onPop(): void }): SubscriptionLike {
    // keep track of the initial state
    const prevState = this.location.getState();
    // non-navigation that just pushes a state for back button compatibility
    this.location.go(this.location.path(), undefined, { fakeId: Date.now() });
    // return a subscription so it can be unsubscribed
    const sub = this.location.subscribe(({ state }) => {
      // if we're back at the original state, call the onPop
      if (isEqual(state, prevState)) {
        if (onPop) {
          onPop();
        }
        // unsubscribe after first pop event
        sub.unsubscribe();
      }
    });
    return sub;
  }

  /**
   * Navigates to the previous state in the route history,
   * returning a Promise that resolves when the navigation is complete
   */
  async back(): Promise<void> {
    if (isPlatformServer(this.platformId)) {
      throw new Error('Attempted to back() on the server');
    }

    if (
      this.initialHistoryState &&
      // we are about to pop before the first page
      isEqual(window.history.state, this.initialHistoryState.state) &&
      // and there is no previous page
      (window.history.length === 1 ||
        !document.referrer ||
        // or the previous page is not on our website
        new URL(document.referrer).origin !== this.origin)
    ) {
      // and the initial state was NOT a primary page
      if (!this.initialHistoryState.isPrimary) {
        // using navigate rather than replace means the user can still
        // reach the current page, although the history state is reversed
        // .then() removes the return value to make typescript happy
        return this.navigateByUrl('/', {
          initialStateIsPrimary: true,
        }).then();
      }

      const isAndroid =
        (await this.injector
          .get(FREELANCER_LOCATION_PWA_PROVIDER)
          .getPlatform()) === 'android';
      if (isAndroid) {
        return App.minimizeApp();
      }

      // TODO: T267853 - navigate to views contextually (eg. message thread => inbox)
    }

    // otherwise, use the natural location.back()
    if (this.routeLoaded) {
      return new Promise(async resolve => {
        // wait for the next `popstate event to be finished
        // otherwise if you try to back().then() navigate, it will be cancelled
        const sub = this.location.subscribe(() => {
          setTimeout(() => resolve());
          sub.unsubscribe();
        });

        this.location.back();

        // FIX ME: T290085 - Investigate why location.go pushes two states and
        // location.back doesn't properly clean top state in history

        // There are cases that location.back() doesn't trigger a popstate event
        // which results in the location.subscribe always listens to new popstate
        // event and never resolves the promise. We added a setTimeout for 100ms
        // to wait for a navigation to finish then resolve the promise and unsubscribes
        // the subscription. This is a workaround to resolve when location.back()
        // doesn't trigger a popstate event.

        this.timeUtils.setTimeout(() => {
          if (!sub.closed) {
            resolve();
            sub.unsubscribe();
          }
        }, 100);

        /**
         * T232870: This detects any PopStateEvents. In the case the PopStateEvent
         * navigates to the initialHistoryState, it will save the equivalent
         * history state it is replaced by.
         */
        const popStateEvent = await firstValueFrom(
          this.router.events.pipe(
            filter(event => event instanceof NavigationStart),
            untilDestroyed(this),
          ),
        );
        if (
          popStateEvent instanceof NavigationStart &&
          popStateEvent.navigationTrigger === 'popstate' &&
          popStateEvent.restoredState &&
          isEqual(this.initialHistoryState.state, popStateEvent.restoredState)
        ) {
          this.initialHistoryState.state = {
            navigationId: popStateEvent.id,
          };
        }
      });
    }
    // not `routeLoaded` implies compat layer,
    // where we don't track locationSubject$ and can't do the wait logic.
    this.location.back();
    return new Promise(resolve => {
      setTimeout(() => resolve());
    });
  }

  /**
   * SSR-friendly version of Router::navigateByUrl() without query parameters
   *
   * @remark
   *
   * WARNING: Please prefer using `router.navigate` if you need to pass in query
   * params to the router
   */
  navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationExtras & {
      isPermanentRedirect?: boolean;
      initialStateIsPrimary?: boolean;
    },
  ): Promise<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      return this.router
        .navigateByUrl(url, {
          replaceUrl:
            extras && (extras.replaceUrl || extras.initialStateIsPrimary),
          ...extras,
        })
        .then(async e => {
          /**
           * In the case that the first page the user used is a primary route
           * (e.g. /login, /dashboard, /), the app needs to check if any
           * redirections have occurred before setting the new initial state.
           */
          if (extras && extras.initialStateIsPrimary !== undefined) {
            /**
             * In the case e === true, it means that the app has finished
             * navigating and does not require any further redirecting, meaning
             * it is safe to mark initialState here.
             */
            if (e) {
              this.markInitialState(extras.initialStateIsPrimary);
            } else {
              /**
               * However, in the case there are some redirects in order, this
               * will listen until there are no further redirects, then mark
               * that as the new initialState.
               */
              await firstValueFrom(
                this.router.events.pipe(
                  filter(event => event instanceof NavigationEnd),
                  untilDestroyed(this),
                ),
              );
              this.markInitialState(extras.initialStateIsPrimary);
            }
          }
          return e;
        });
    }
    // In theory, all URL paths passed to navigateByUrl should start with a
    // leading /. In practice though, the Angular Router will automatically add
    // a leading slash if missing, this reproduces the same behavior on the
    // server for consistency.
    const slash = url.toString().startsWith('/') ? '' : '/';
    this.request._SSR_handleRedirect(
      `${slash}${url}`,
      extras?.isPermanentRedirect,
    );
    return this.router.navigateByUrl('/internal/blank');
  }

  /**
   * SSR-friendly version of Router::createUrlTree()
   */
  createUrlTree(
    commands: any[],
    navigationExtras?: UrlCreationOptions & {
      isPermanentRedirect?: boolean;
      initialStateIsPrimary?: boolean;
      /**
       * Allow this navigation to pass through without the server returning 301/302
       */
      skipSSRRedirection?: boolean;
    },
  ): UrlTree {
    const urlTree = this.router.createUrlTree(commands, navigationExtras);

    if (isPlatformBrowser(this.platformId)) {
      if (navigationExtras?.initialStateIsPrimary !== undefined) {
        /**
         * In the case there are some redirects in order, this
         * will listen until there are no further redirects, then mark
         * that as the new initialState.
         */
        firstValueFrom(
          this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            untilDestroyed(this),
          ),
        ).then(() => {
          this.markInitialState(navigationExtras.initialStateIsPrimary);
        });
      }

      return urlTree;
    }

    if (navigationExtras?.skipSSRRedirection) {
      return urlTree;
    }

    const url = this.router.serializeUrl(urlTree);
    this.request._SSR_handleRedirect(
      url,
      navigationExtras?.isPermanentRedirect,
    );
    return this.router.createUrlTree(['/internal/blank']);
  }

  /**
   * Sets the location to a provided url. This will leave the webapp, DO NOT
   * use it for internal redirects, use navigateByUrl instead
   *
   * @param url
   * @param replaceUrl When true, navigates while replacing the current state in history.
   */
  async redirect(url: string, replaceUrl: boolean = false): Promise<boolean> {
    const redirectUrl = new URL(url, this.origin);
    // Explicitly bypass the service worker if hard-redirecting to another
    // internal page (if it's external, the service worker won't kick in
    // anyway)
    let redirectUrlString: string;
    if (redirectUrl.origin === this.origin) {
      redirectUrl.searchParams.set('ngsw-bypass', '');
      redirectUrlString = `${redirectUrl.pathname}${redirectUrl.search}${redirectUrl.hash}`;
    } else {
      redirectUrlString = redirectUrl.toString();
    }
    if (isPlatformServer(this.platformId)) {
      this.request._SSR_handleRedirect(redirectUrlString);
      return this.router.navigateByUrl('/internal/blank');
    }
    // We can't use static injection here as the Pwa stuff uses the Location
    // service itself
    if (this.injector.get(FREELANCER_LOCATION_PWA_PROVIDER).isInstalled()) {
      const isInternalRedirect =
        redirectUrl.origin === this.origin ||
        redirectUrl.origin === this.freelancerLocationHttpBaseUrl;

      // If internal redirect on native, pop-up the exit modal
      if (this.injector.get(FREELANCER_LOCATION_PWA_PROVIDER).isNative()) {
        const trackingConsent = await this.injector
          .get(FREELANCER_LOCATION_TRACKING_CONSENT_PROVIDER)
          .getThirdPartyStatus();
        // Disable third-party tracking in in-app browsers if denied in the app
        if (trackingConsent !== 'authorized') {
          redirectUrl.searchParams.set('no_third_party_tracking', 'true');
        }

        // Internal redirections should still go into the old in-app browser to
        // allow auto route back to webapp functionality.
        if (isInternalRedirect) {
          // Opening the URL in a native in-app browser.
          const lastVisitedUrl = await this.injector
            .get(FREELANCER_LOCATION_IN_APP_BROWSER_PROVIDER)
            // The in-app browser service will transfer the auth state when it is open.
            .open(redirectUrl.toString())
            .closeOnWebapp();
          // When the in-app browser is closed due to navigating to a web app
          // route, carry on the navigation within the app.
          if (lastVisitedUrl) {
            return this.navigateByUrl(lastVisitedUrl);
          }
        } else {
          // For external URLs, just open it in the nice Capacitor browser.
          Browser.open({ url: redirectUrl.toString() });
        }
      } else if (redirectUrl.searchParams.get('peb') === 'true') {
        // Already on the PWA embedded browser (peb). User session should have been set, redirect on the embedded browser directly.
        redirectUrl.searchParams.delete('peb');
        window.location.replace(redirectUrl.toString());
        // Break the flow. location.back() will affect current redirection in PWA embedded browser.
        return false;
      } else {
        // We can't use static injection here as the Auth service
        // uses the Location service itself
        const authState = await firstValueFrom(
          this.injector
            .get(FREELANCER_LOCATION_AUTH_PROVIDER)
            .authState$.pipe(untilDestroyed(this)),
        );
        // to avoid redirect loop happens in Android standalone mode. see T291972#4764055
        if (isInternalRedirect && authState) {
          redirectUrl.searchParams.set('peb', 'true');
        }
        const authedRedirectUrl =
          isInternalRedirect && authState
            ? `${redirectUrl.origin}/internal/auth/callback?uid=${
                authState.userId
              }&token=${encodeURIComponent(
                authState.token,
              )}&next=${encodeURIComponent(
                redirectUrl.pathname + redirectUrl.search,
              )}`
            : redirectUrl.toString();
        window.open(authedRedirectUrl, '_blank');
      }
      // FIXME: T267853 - This is needed as returning false still creates a new
      // state, even though the navigation is cancelled, because of
      // urlUpdateStrategy: eager. Arguably though this is an Angular bug
      // & the new state should be reverted on cancelled navigations.
      setTimeout(() => this.location.back());
      return false;
    }
    if (this.routeLoaded && !replaceUrl) {
      window.location.assign(redirectUrl.toString());
    } else {
      window.location.replace(redirectUrl.toString());
    }
    await new Promise(resolve => {
      // Never return
    });
    return false;
  }

  /**
   * [Exported Private API] markInitialState
   * To be used only by the LocationComponent to mark what history states are
   * the bottom of the history stack.
   */
  markInitialState(isPrimary: boolean = false): void {
    this.routeLoaded = true;
    if (isPlatformBrowser(this.platformId)) {
      this.initialHistoryState = { state: window.history.state, isPrimary };
    }
  }

  /*
   * Returns the current origin in a platform friendly way
   */
  get origin(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.origin;
    }
    return `${this.request.protocol}://${this.host}`;
  }

  /*
   * Returns the current host, that is the hostname, a ':', and the port of the
   * URL, in a platform friendly way
   */
  get host(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.host;
    }
    // FIXME: T267853 - we should be using `req.host` here but Express incorrectly strips
    // off the port number, which breaks local dev. This has been fixed in
    // Express 5 -> https://expressjs.com/en/guide/migrating-5.html#req.host
    // Note: X-Forwarded-Host is normally only ever a single value, but Fastly
    // seems to append the values.
    const header =
      this.request.get('X-Forwarded-Host') ||
      (this.request.get('Host') as string);
    const index = header.indexOf(',');
    return index !== -1 ? header.substring(0, index).trim() : header.trim();
  }

  /*
   * Returns the current hostname, that is the domain of the URL, in a platform
   * friendly way
   */
  get hostname(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.hostname;
    }
    return this.request.hostname;
  }

  /*
   * Returns the current (absolute) URL in a platform friendly way
   */
  get href(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.href;
    }
    return `${this.origin}${this.request.originalUrl}`;
  }

  /*
   * Returns the current URL in a platform friendly way
   */
  get url(): string {
    if (isPlatformBrowser(this.platformId)) {
      return `${window.location.pathname}${window.location.search}`;
    }
    return this.request.originalUrl;
  }

  /*
   * Parses the url and returns with the search params.
   *
   * Details: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
   */
  get searchParams(): URLSearchParams {
    const url = new URL(this.href);
    return url.searchParams;
  }

  /**
   * Returns the current pathname in a platform friendly way
   */
  get pathname(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.pathname;
    }
    return this.request.path;
  }

  /**
   * Returns the current protocol in a platform friendly way
   */
  get protocol(): string {
    if (isPlatformBrowser(this.platformId)) {
      return window.location.protocol;
    }
    return this.request.protocol;
  }

  /**
   * [PRIVATE] Exported private API.
   * To be used only by the LocationComponent to push new locations
   */
  _setLocation(pathname: string, hash: string, search: string): void {
    this.locationSubject$.next({
      hash,
      hostname: this.hostname,
      href: `${this.origin}${pathname}`,
      origin: this.origin,
      pathname,
      search,
    });
  }
}
