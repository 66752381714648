import type {
  DatastoreCollectionType,
  DocumentWithMetadata,
  Ordering,
  Reference,
  UserCollectionStateSlice,
} from '@freelancer/datastore/core';
import {
  compareMultipleFields,
  documentWithMetadataMatchesQueryParams,
  isSearchQuery,
} from '@freelancer/datastore/core';
import { isDefined } from '@freelancer/utils';
import { debugConsoleWarn } from './datastore.helpers';
import type {
  FakeUserCollectionStateSlice,
  SearchTransformer,
} from './store.model';

/**
 * Gets a list of documents which match a particular query. Unlike the real
 * datastore's equivalent `selectDocumentsForReference`, this does not return
 * cached documents. Instead, it only filters the documents directly via query
 * parameters, after applying search transformers.
 *
 * @param storeSlice the object after collection and authUid has been indexed
 * @param ref the reference for the requested data.
 * @param defaultOrder the default order of the collection
 * @param searchTransformers transforms results for search queries only
 *
 * @returns An array of documents
 */
export function selectDocumentsForQuery<C extends DatastoreCollectionType>(
  storeSlice: FakeUserCollectionStateSlice<C>,
  ref: Reference<C>,
  defaultOrder: Ordering<C>,
  searchTransformers: Map<string, SearchTransformer<DatastoreCollectionType>>,
): readonly C['DocumentType'][] {
  const {
    query,
    path: { collection },
  } = ref;

  if (!storeSlice) {
    debugConsoleWarn(
      `'${collection}' collection has no documents. Did you intentionally omit creating a document?`,
    );
    return [];
  }

  let documents: readonly DocumentWithMetadata<C['DocumentType']>[];
  const searchTransformer = searchTransformers.get(collection);

  const queryOrder = ref.order as Ordering<DatastoreCollectionType> | undefined;

  if (isSearchQuery(query) && searchTransformer && query?.searchQueryParams) {
    // Apply the search transformer before filtering on query params
    const rawDocuments = Object.values(storeSlice.documents).map(
      documentWithMetadata => documentWithMetadata.rawDocument,
    );
    const now = Date.now();
    documents = searchTransformer(
      rawDocuments,
      query.searchQueryParams,
      queryOrder,
    ).map(document => ({
      rawDocument: document,
      timeFetched: now,
      timeUpdated: now,
    }));
  } else {
    documents = Object.values(storeSlice.documents);
  }

  let matchingObjects: C['DocumentType'][];

  // If the reference is only for `ids` without a query, return those
  if (
    ref.path.ids &&
    (!ref.query ||
      (!isSearchQuery(ref.query) &&
        (!ref.query.queryParams ||
          Object.keys(ref.query.queryParams).length === 0)))
  ) {
    matchingObjects = ref.path.ids
      .map(id => storeSlice.documents[id])
      .filter(isDefined)
      .map(documentWithMetadata => documentWithMetadata.rawDocument);
  } else {
    // Otherwise, it's a query - filter entities instead of the default list
    // (which the real datastore does)
    matchingObjects = documents
      .filter(documentWithMetadata =>
        documentWithMetadataMatchesQueryParams(
          documentWithMetadata,
          query && query.queryParams,
        ),
      )
      .map(documentWithMetadata => documentWithMetadata.rawDocument);
    // Don't apply the limit here, instead apply it inside the datastore.
    // This lets you easily calculate the total count for `approximateTotalCount`.
  }

  const order = queryOrder ?? defaultOrder;

  // Respect search transformer's order, otherwise fall back to the query.orderBy
  // clause, then the collection's backend defaultOrder
  return !isSearchQuery(query) || !searchTransformer
    ? matchingObjects.sort((document1, document2) =>
        compareMultipleFields<C['DocumentType']>(
          Array.isArray(order) ? order : [order],
        )(document1, document2),
      )
    : matchingObjects;
}

export function fetchSingleDocument<C extends DatastoreCollectionType>(
  storeSlice: UserCollectionStateSlice<C>,
  ref: Reference<C>,
  defaultOrder: Ordering<C>,
  searchTransformers: Map<string, SearchTransformer<DatastoreCollectionType>>,
): C['DocumentType'] | undefined {
  const {
    path: { collection, ids },
    query,
  } = ref;

  if (!storeSlice) {
    const idOrQuery = ids
      ? `id ${ids[0]}`
      : `query ${
          query &&
          query.queryParams &&
          JSON.stringify(Object.values(query.queryParams)[0])
        }`;
    debugConsoleWarn(
      `'${collection}' document with ${idOrQuery} does not exist. Did you intentionally omit creating a document?`,
    );

    return undefined;
  }

  if (ids) {
    return (
      storeSlice.documents[ids[0]] && storeSlice.documents[ids[0]].rawDocument
    );
  }

  const documents = selectDocumentsForQuery(
    storeSlice,
    ref,
    defaultOrder,
    searchTransformers,
  );
  return documents ? documents[0] : undefined;
}
