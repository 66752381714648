import { registerPlugin } from '@capacitor/core';

export interface BackgroundNotificationPlugin {
  // Get a list of background notifications(FCM data message)' payload.
  getBackgroundNotificationData(): Promise<{
    data: string[];
  }>;

  // Remove background notification data files from native storage
  deleteDataFiles(): Promise<void>;
}

export const BACKGROUND_NOTIFICATION_PLUGIN_NAME = 'BackgroundNotification';

// Disabling lint rule here to enable the below plugin to align with the
// conventions of naming Capacitor plugins.
// eslint-disable-next-line @typescript-eslint/naming-convention
export const BackgroundNotification =
  registerPlugin<BackgroundNotificationPlugin>(
    BACKGROUND_NOTIFICATION_PLUGIN_NAME,
  );
