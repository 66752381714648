import type { EnvironmentConfig } from './environment.types';

export const environment: EnvironmentConfig = {
  appName: 'main',
  environmentName: 'prod',
  production: true,
  useFakes: false,
  blankState: false,
  canvasHost: undefined,
  authConfig: {
    baseUrl: 'https://www.freelancer.com/auth',
    switchAccountHackyEndpoint:
      'https://www.freelancer.com/ajax-api/navigation/switchaccount.php',
    authHashCookie: 'GETAFREE_AUTH_HASH_V2',
    userIdCookie: 'GETAFREE_USER_ID',
    csrfCookie: 'XSRF-TOKEN',
    gdprCookie: 'requires_gdpr',
    authHeaderName: 'freelancer-auth-v2',
    referralCookie: 'referral_signup',
    authIosKeychainToken: 'freelancer-auth-ios-keychain',
  },
  adminAuthConfig: {
    baseUrl: undefined,
    authHashCookie: undefined,
    userIdCookie: undefined,
    authHeaderName: undefined,
  },
  captchaConfig: {
    recaptchaPublicKey: '6Lc1CCcTAAAAABxlulYmWJj_ZNAHHegrhLV3vS2Z',
  },
  uiConfig: {
    assetsBaseUrl: 'https://www.f-cdn.com/assets/main/en/assets',
    theme: undefined,
  },
  mapsConfig: {
    apiKey: 'AIzaSyAWB_zZMjdwNlu8-9xZ8RyNRbCERQTWSR8',
  },
  datastoreConfig: {
    webSocketUrl: 'https://notifications.freelancer.com',
    enableStoreFreeze: false,
  },
  freelancerHttpConfig: {
    baseUrl: 'https://www.freelancer.com',
    apiBaseUrl: 'https://www.freelancer.com/api',
    ajaxBaseUrl: 'https://www.freelancer.com/ajax-api',
    authBaseUrl: 'https://www.freelancer.com/auth',
    fileServiceBaseUrl: 'https://www.freelancer.com/fileservice/v4/upload',
  },
  threatmetrixConfig: {
    profilingDomain: 'cdn1.f-cdn.com',
    orgId: 'lar9e74p',
  },
  trackingConfig: {
    trackingEndpoint: 'https://t.freelancer.com/events',
    linkSessionsEndpoint: 'https://t.freelancer.com/link',
    sessionCookie: '_tracking_session',
    linkedSessionCookie: 'uniform_id_linked',
    clientKey: '_tracking_client_session',
    navigationPerformanceSamplingRate: 1,
    enableErrorTracking: true,
    sentryDsn: 'https://29cfb544702b48e0985e69cd3ef5e84f@sentry.io/1410063',
    tracesSampleRate: 0,
    gaTrackingId: 'UA-223765-6',
    ga4TrackingId: 'G-31ZQKFK760',
    awGoogleConversionId: undefined,
    facebookPixelId: 530_993_107_478_103,
    sentryEnvironment: 'production',
    kibanaBaseUrl: undefined,
    gaCustomDimensionMap: {
      dimension1: 'skills',
      dimension2: 'account_type',
      dimension3: 'ab_test_variation',
    },
    gAdsTrackingId: undefined,
    gAdsAllowedDomain: undefined,
    linkedInPixelId: '3045346',
    quoraPixelId: '6cfaf7caf340413ebf9b12da0deee3a2',
    hotjarId: 1_223_449,
    tiktokPixelId: 'CQ154HJC77U1C0R52830',
  },
  billingConfig: {
    baseUrl: 'https://api.freelancerbilling.com',
  },
  facebookConfig: {
    appId: '120131118061981',
  },
  languageCookies: {
    webappCookie: 'GETAFREE_LANGUAGE',
    nativeCookie: 'GETAFREE_MOBILELANGUAGE',
  },
  seoConfig: {
    linkTags: {
      rss: {
        rel: 'alternative',
        type: 'application/rss+xml',
        title: 'Latest Projects',
        href: '/rss.xml',
      },
    },
    metaTags: {
      robots: {
        name: 'robots',
        content: 'index, follow',
      },
      // /!\ DO NOT add google-site-verification tags here, use file vefication
      // instead.
      alexa: {
        name: 'alexaVerifyID',
        content: 'lOECm39vgYv4N9PwoW6nsSSLiUw',
      },
      bing: {
        name: 'msvalidate.01',
        content: '756854B52F963132E377E441F92F1136',
      },
    },
    defaultMetaImage: 'app-icons/share-make-it-real-thumbnail-1200x630.jpg',
  },
  whitelistCookie: 'IS_WHITELIST_USER',
  siteName: 'Freelancer',
  pwaConfig: {
    appIcon: 'freelancer-logo-icon.svg',
  },
  loginConfig: {
    loggedOutRestricted: false,
    defaultLogoutRedirectUrl: '/',
    ssoIntegration: undefined,
  },
  firebase: {
    apiKey: 'AIzaSyCk13ZnwcFgrMU1Ulr13IyyDlZLqS-UR-Q',
    projectId: 'freelancer-8cd88',
    messagingSenderId: '517773278714',
    appId: '1:517773278714:web:8b59a1922d63d6371af843',
  },
  googleSignInConfig: {
    clientId:
      '517773278714-366eq83sei2oc5sf2t4gn19pv376tnv6.apps.googleusercontent.com',
  },
  messaging: {
    aiChatUserId: 3_779_912,
  },
  lightGallery: {
    licenseKey: 'A6ACF584-D992-4C7C-B6EA-2CB459B194E8',
  },
  applePayConfig: {
    merchantIdentifier: 'merchant.com.adyen.FreelancerCOM',
  },
};
