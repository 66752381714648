import { ServiceOfferingShopCategoryServicePriceTypeApi } from 'api-typings/service_offerings/service_offerings';

/*
 A service offering with the shop category details included.
*/
export interface ServiceOfferingShopCategoryService {
  readonly id: number;
  readonly serviceOfferingId: number;
  readonly categoryId: number;
  readonly shopId?: number;
  readonly title?: string;
  readonly seoUrl?: string;
  readonly heroImageUuid?: string;
  readonly heroImageUrl?: string;
  readonly duration?: number;
  readonly price?: number;
  readonly priceType?: ServiceOfferingShopCategoryServicePriceType;
}

export enum ServiceOfferingShopCategoryServicePriceType {
  SUBSCRIPTION_ONLY_MONTHLY = ServiceOfferingShopCategoryServicePriceTypeApi.SUBSCRIPTION_ONLY_MONTHLY,
  SUBSCRIPTION_ONLY_ANNUALLY = ServiceOfferingShopCategoryServicePriceTypeApi.SUBSCRIPTION_ONLY_ANNUALLY,
  SUBSCRIPTION_ONLY_WEEKLY = ServiceOfferingShopCategoryServicePriceTypeApi.SUBSCRIPTION_ONLY_WEEKLY,
  SUBSCRIPTION_MONTHLY_AND_HOURLY = ServiceOfferingShopCategoryServicePriceTypeApi.SUBSCRIPTION_MONTHLY_AND_HOURLY,
  SUBSCRIPTION_ANNUALLY_AND_HOURLY = ServiceOfferingShopCategoryServicePriceTypeApi.SUBSCRIPTION_ANNUALLY_AND_HOURLY,
  SUBSCRIPTION_WEEKLY_AND_HOURLY = ServiceOfferingShopCategoryServicePriceTypeApi.SUBSCRIPTION_WEEKLY_AND_HOURLY,
  HOURLY_ONLY = ServiceOfferingShopCategoryServicePriceTypeApi.HOURLY_ONLY,
  HAS_FIXED_ITEMS = ServiceOfferingShopCategoryServicePriceTypeApi.HAS_FIXED_ITEMS,
}
