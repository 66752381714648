import type { HttpErrorResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { Params } from '@angular/router';
import { Router } from '@angular/router';
import type { AppleSignInResponse } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { ActivatedRoute } from '@freelancer/activated-route';
import type {
  AppleSignInError,
  SSOResponseData,
  SSOUser,
} from '@freelancer/auth';
import { AUTH_CONFIG, AppleSSO, Auth, AuthConfig } from '@freelancer/auth';
import type { CrossDomainSSODomainsGetResultAjax } from '@freelancer/auth/interface';
import type { Country } from '@freelancer/datastore/collections';
// FIXME: T235847
// eslint-disable-next-line local-rules/validate-freelancer-imports
import type { TotpMethod } from '@freelancer/datastore/collections/two-factor-details';
import type {
  FacebookAuthResponse,
  FacebookSignInError,
} from '@freelancer/facebook';
import { Facebook } from '@freelancer/facebook';
import type {
  ErrorResponseData,
  ResponseData,
  SuccessResponseData,
} from '@freelancer/freelancer-http';
import { FreelancerHttp } from '@freelancer/freelancer-http';
import type { GoogleAuthResponse } from '@freelancer/google-sign-in';
import {
  GoogleSignInService,
  type GoogleSignInError,
} from '@freelancer/google-sign-in';
import { Location } from '@freelancer/location';
import { Pwa } from '@freelancer/pwa';
import { ThreatmetrixService } from '@freelancer/threatmetrix';
import { ToastAlertService } from '@freelancer/ui/toast-alert';
import { assertNever, isDefined } from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ClientTypeApi } from 'api-typings/auth/user';
import type { RoleApi } from 'api-typings/common/common';
import { ErrorCodeApi } from 'api-typings/errors/errors';
import type { GafExceptionCodesApi } from 'api-typings/gaf/gaf';
import type { UserCreateResultApi } from 'api-typings/users/users';
import { CookieService } from 'ngx-cookie';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import type {
  AuthReactivationResponseAjax,
  AuthResponseAjax,
  AuthSuccessResponseAjax,
  ChangeUserPasswordError,
  CrossDomainSsoPostResultAjax,
  GoogleSSOResponseAjax,
  LoginError,
  QuickLoginFenceGetResultAjax,
  RedirectUrlGetResultAjax,
  ResetPasswordError,
  ResetUserPasswordError,
  ResetUserPasswordPostResultAjax,
  ResetUserPasswordValidatePostResultAjax,
  SSOResponseAjax,
  TwoFactorBackoffTimeLeftGetResultAjax,
  TwoFactorResponseAjax,
  UserCheckError,
} from './login-signup.backend-model';
import type {
  AuthResponse,
  SSOActionResponse,
  TwoFactorResponse,
} from './login-signup.model';
import {
  transformAuthResponse,
  transformResponseData,
  transformTwoFactorResponse,
  transformUserCreateResonse,
} from './login-signup.transformers';

interface RedirectParams {
  redirectDisabled?: boolean;
  queryParams?: Params;
}

type SavedState =
  | {
      user: string;
      password: string;
    }
  | {
      ssoAuthPromise?: Promise<
        | (ResponseData<any, string> & {
            provider: 'apple' | 'facebook' | 'google';
          })
        | undefined
      >;
      ssoDetailsPromise?: Promise<SSOUser | undefined>;
      /**
       * The original response from the appropriate FLN login endpoint
       * Should always be an `SSOActionResponse` but is typed as AuthResponse
       * to reduce type messiness in the usages
       */
      ssoActionPromise?: Promise<
        ResponseData<AuthResponse, LoginError> | undefined
      >;
    };

/**
 * Service which provides an interface to backend endpoints for the login/signup funnel
 */
@UntilDestroy({ className: 'LoginSignupService' })
@Injectable({
  providedIn: 'root',
})
export class LoginSignupService {
  private savedState: SavedState = {
    user: '',
    password: '',
  };

  constructor(
    private auth: Auth,
    @Inject(AUTH_CONFIG) private authConfig: AuthConfig,
    private rawHttp: HttpClient,
    private freelancerHttp: FreelancerHttp,
    private activatedRoute: ActivatedRoute,
    private facebook: Facebook,
    private apple: AppleSSO,
    private google: GoogleSignInService,
    private location: Location,
    private cookies: CookieService,
    private router: Router,
    private pwa: Pwa,
    private threatMetrix: ThreatmetrixService,
    private toastAlertService: ToastAlertService,
  ) {}

  login(
    user: string,
    password: string,
    captcha?: string,
  ): Promise<ResponseData<AuthResponse, LoginError>> {
    return this.auth.getDeviceToken().then(deviceToken => {
      // return a custom error because passing undefined
      // to the backend will result in `InvalidInput`
      if (!deviceToken) {
        return {
          status: 'error',
          errorCode: ErrorCodeApi.AUTH_DEVICE_TOKEN_INVALID,
        };
      }
      return firstValueFrom(
        this.freelancerHttp
          .post<AuthResponseAjax, LoginError>(
            'auth/login.php',
            {
              user,
              password,
              device_token: deviceToken,
              captcha,
            },
            {
              isGaf: true,
              serializeBody: true,
            },
          )
          .pipe(
            map(responseData =>
              transformResponseData(responseData, transformAuthResponse),
            ),
            untilDestroyed(this),
          ),
      );
    });
  }

  verify(
    preLoginToken: string,
    password: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return this.auth.getDeviceToken().then(deviceToken => {
      if (!deviceToken) {
        return {
          status: 'error',
          errorCode: ErrorCodeApi.AUTH_DEVICE_TOKEN_INVALID,
        };
      }
      return firstValueFrom(
        this.freelancerHttp
          .post<AuthSuccessResponseAjax, LoginError>(
            'auth/verify2FALogin.php',
            {
              totp: password,
              device_token: deviceToken,
              pre_login_token: preLoginToken,
            },
            {
              isGaf: true,
              serializeBody: true,
            },
          )
          .pipe(untilDestroyed(this)),
      );
    });
  }

  resend2FACode(
    preLoginToken: string,
    method?: TotpMethod,
  ): Promise<ResponseData<TwoFactorResponse, LoginError>> {
    return this.auth.getDeviceToken().then(deviceToken => {
      if (!deviceToken) {
        return {
          status: 'error',
          errorCode: ErrorCodeApi.AUTH_DEVICE_TOKEN_INVALID,
        };
      }

      return firstValueFrom(
        this.freelancerHttp
          .post<TwoFactorResponseAjax, LoginError>(
            'auth/send2FAToken.php',
            {
              '2fa_method': method,
              device_token: deviceToken,
              pre_login_token: preLoginToken,
            },
            {
              isGaf: true,
              serializeBody: true,
            },
          )
          .pipe(
            map(responseData =>
              transformResponseData(responseData, transformTwoFactorResponse),
            ),
            untilDestroyed(this),
          ),
      );
    });
  }

  getBackoffTimeLeft(
    preLoginToken: string,
  ): Promise<ResponseData<TwoFactorBackoffTimeLeftGetResultAjax, LoginError>> {
    return this.auth.getDeviceToken().then(deviceToken => {
      if (!deviceToken) {
        return {
          status: 'error',
          errorCode: ErrorCodeApi.AUTH_DEVICE_TOKEN_INVALID,
        };
      }

      return firstValueFrom(
        this.freelancerHttp
          .get<TwoFactorBackoffTimeLeftGetResultAjax, LoginError>(
            'auth/mfa/backoff.php',
            {
              isGaf: true,
              params: {
                pre_login_token: preLoginToken,
              },
            },
          )
          .pipe(untilDestroyed(this)),
      );
    });
  }

  async appleLogin(
    authResponse: AppleSignInResponse,
  ): Promise<
    ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
  > {
    const { id: appId } = await firstValueFrom(
      this.pwa.getCapacitorAppInfo().pipe(untilDestroyed(this)),
    );

    const response = await firstValueFrom(
      this.freelancerHttp
        .post<SSOResponseAjax, LoginError>(
          'auth/appleLogin.php',
          {
            code: authResponse.authorizationCode,
            client_type: 1,
            client_id: appId,
          },
          {
            isGaf: true,
            serializeBody: true,
          },
        )
        .pipe(untilDestroyed(this)),
    );

    if (response.status === 'error' || 'token' in response.result) {
      return response as ResponseData<AuthSuccessResponseAjax, LoginError>;
    }

    if (response.result.action === 'user_apple_link') {
      return {
        status: 'success',
        result: {
          action: 'link',
          ssoToken: response.result.apple_identity_token,
        },
      };
    }
    if (response.result.action === 'user_apple_signup') {
      return {
        status: 'success',
        result: {
          action: 'signup',
          ssoToken: response.result.apple_identity_token,
        },
      };
    }
    assertNever(response.result.action);
  }

  async appleLoginLink(
    email: string,
    password: string,
    appleIdentityToken: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    const { id: appId } = await firstValueFrom(
      this.pwa.getCapacitorAppInfo().pipe(untilDestroyed(this)),
    );

    return firstValueFrom(
      this.freelancerHttp
        .post<AuthSuccessResponseAjax, LoginError>(
          'auth/appleLinkLogin.php',
          {
            email,
            password,
            apple_identity_token: appleIdentityToken,
            client_type: 1,
            client_id: appId,
          },
          {
            isGaf: true,
            serializeBody: true,
          },
        )
        .pipe(untilDestroyed(this)),
    );
  }

  facebookLogin(
    authResponse: FacebookAuthResponse,
  ): Promise<
    ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
  > {
    return firstValueFrom(
      this.freelancerHttp
        .post<AuthSuccessResponseAjax, LoginError>(
          'auth/facebookLogin.php',
          {
            // mobile and desktop facebook sso take slightly different params
            // due to differences in the auth response from the Facebook SDK
            // mobile has no signed response and uses the facebook ID instead
            mobile: !isDefined(authResponse.signedRequest),
            app_id: this.facebook.appId,
            credentials: authResponse.signedRequest,
            access_token: authResponse.accessToken,
            facebook_id: authResponse.userID,
          },
          {
            isGaf: true,
            serializeBody: true,
            errorWhitelist: [
              ErrorCodeApi.AUTH_FACEBOOK_EMAIL_EXISTS, // need to link
              ErrorCodeApi.AUTH_USER_MISSING, // need to signup
              ErrorCodeApi.FACEBOOK_EMAIL_NOT_FOUND, // can signup by using their own email
            ],
          },
        )
        .pipe(untilDestroyed(this)),
    ).then(response => {
      if (response.status === 'success') {
        return response;
      }

      switch (response.errorCode) {
        case ErrorCodeApi.AUTH_FACEBOOK_EMAIL_EXISTS:
          return {
            status: 'success',
            result: {
              action: 'link',
            },
          };

        case ErrorCodeApi.AUTH_USER_MISSING:
        case ErrorCodeApi.FACEBOOK_EMAIL_NOT_FOUND:
          return {
            status: 'success',
            result: {
              action: 'signup',
            },
          };
        default:
          return response;
      }
    });
  }

  facebookLinkLogin(
    email: string,
    password: string,
    authResponse: FacebookAuthResponse,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return firstValueFrom(
      this.freelancerHttp
        .post<AuthSuccessResponseAjax, LoginError>(
          'auth/facebookLinkLogin.php',
          {
            mobile: !isDefined(authResponse.signedRequest),
            email,
            password,
            credentials: authResponse.signedRequest,
            access_token: authResponse.accessToken,
            facebook_id: authResponse.userID,
          },
          {
            isGaf: true,
            serializeBody: true,
          },
        )
        .pipe(untilDestroyed(this)),
    );
  }

  async googleLogin(
    authResponse: GoogleAuthResponse,
  ): Promise<
    ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
  > {
    const response = await firstValueFrom(
      this.freelancerHttp
        .post<GoogleSSOResponseAjax, LoginError>(
          'auth/googleLogin.php',
          {
            google_id_token: authResponse.credential,
            // We use WEB client_type for android because
            // our android app uses the web client id
            client_type: (this.pwa.getPlatform() === 'ios'
              ? ClientTypeApi.CAPACITOR_IOS
              : ClientTypeApi.WEB
            ).toUpperCase(),
          },
          {
            isGaf: true,
          },
        )
        .pipe(untilDestroyed(this)),
    );

    if (response.status === 'error' || 'token' in response.result) {
      return response as ResponseData<AuthSuccessResponseAjax, LoginError>;
    }

    if (response.result.action === 'user_google_link') {
      return {
        status: 'success',
        result: {
          action: 'link',
          ssoToken: response.result.google_identity_token,
        },
      };
    }

    if (response.result.action === 'user_google_signup') {
      return {
        status: 'success',
        result: {
          action: 'signup',
          ssoToken: response.result.google_identity_token,
        },
      };
    }

    assertNever(response.result.action);
  }

  async googleLinkLogin(
    email: string,
    password: string,
    authResponse: GoogleAuthResponse,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return firstValueFrom(
      this.freelancerHttp
        .post<AuthSuccessResponseAjax, LoginError>(
          'auth/googleLinkLogin.php',
          {
            email,
            password,
            google_id_token: authResponse.credential,
            client_type: (this.pwa.getPlatform() === 'ios'
              ? ClientTypeApi.CAPACITOR_IOS
              : ClientTypeApi.WEB
            ).toUpperCase(),
          },
          {
            isGaf: true,
          },
        )
        .pipe(untilDestroyed(this)),
    );
  }

  checkUserDetails(
    user: {
      username?: string;
      email?: string;
    },
    password?: string,
  ): Promise<ResponseData<undefined, UserCheckError>> {
    return firstValueFrom(
      this.freelancerHttp
        .post<undefined, UserCheckError>('users/0.1/users/check', {
          user,
          password,
        })
        .pipe(untilDestroyed(this)),
    );
  }

  signup({
    email,
    username,
    role,
    password,
    privacyConsent,
    personalUse,
    ssoDetails,
    ssoResponseToken,
    captcha,
    emailVerifyGoto,
    firstName,
    lastName,
    phoneCountry,
    phoneNumber,
    companyName,
    ssoProvider,
  }: {
    email: string;
    username: string;
    role: RoleApi;
    // This should probably use a ternary type (one for SSO, one for password)
    // but it messes up TS type inferencing
    password?: string;
    privacyConsent: boolean;
    personalUse?: boolean;
    // TODO: T267853 - Make this facebookOauthDetails and appleOauthToken
    ssoDetails?:
      | FacebookAuthResponse
      | AppleSignInResponse
      | GoogleAuthResponse;
    ssoResponseToken?: string;
    captcha?: {
      challenge: string;
      response: string;
    };
    /* this is where the email verification email will link to */
    emailVerifyGoto?: string;
    firstName?: string;
    lastName?: string;
    phoneCountry?: Country;
    phoneNumber?: string;
    companyName?: string;
    ssoProvider?: 'facebook' | 'apple' | 'google';
  }): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    const {
      project_collaboration_invitation,
      contest_collaboration_invitation,
    } = this.activatedRoute.snapshot.queryParams;
    const referralSignup = this.authConfig.referralCookie
      ? this.cookies.get(this.authConfig.referralCookie)
      : undefined;

    let endpoint: string;
    switch (ssoProvider) {
      case 'facebook':
        endpoint = 'users/0.1/users/facebook';
        break;
      case 'apple':
        endpoint = 'users/0.1/users/apple';
        break;
      case 'google':
        endpoint = 'users/0.1/users/google';
        break;
      default:
        endpoint = 'users/0.1/users';
        break;
    }
    const thmSession = this.threatMetrix.getSession();
    return firstValueFrom(
      this.freelancerHttp
        .post<UserCreateResultApi, LoginError>(
          endpoint,
          {
            user: {
              first_name: firstName,
              last_name: lastName,
              marketing_mobile_number: phoneNumber
                ? {
                    phone_number: phoneNumber,
                    country_code: phoneCountry?.phoneCode?.toString(),
                  }
                : null,
              company: companyName || null,
              email,
              username,
              role,
            },
            password,
            captcha,
            signup_meta: {
              privacy_consent: privacyConsent,
            },
            facebook_id:
              ssoDetails && 'userID' in ssoDetails
                ? ssoDetails.userID
                : undefined,
            access_token:
              ssoDetails && 'userID' in ssoDetails
                ? ssoDetails.accessToken
                : undefined,
            apple_identity_token:
              ssoProvider === 'apple' ? ssoResponseToken : undefined,
            client_type:
              ssoProvider === 'google'
                ? this.pwa.getPlatform() === 'ios'
                  ? ClientTypeApi.CAPACITOR_IOS
                  : ClientTypeApi.WEB
                : undefined,
            google_id_token:
              ssoProvider === 'google' &&
              ssoDetails &&
              'credential' in ssoDetails
                ? ssoDetails.credential
                : undefined,
            // submit recovery email when needed for apple email
            recovery_email: ssoResponseToken ? email : undefined,
            extra: {
              referral_signup: referralSignup,
              project_collaboration_invitation,
              contest_collaboration_invitation,
              business_user:
                // use value if set; defaults to false
                isDefined(personalUse) && !personalUse
                  ? // this endpoint only accepts strings
                    'true'
                  : 'false',
              // send this here as well because facebook signup doesn't use signup_meta
              privacy_consent:
                // use value if set; defauls to true
                isDefined(privacyConsent) && !privacyConsent ? 'false' : 'true',
              thm_session: thmSession,
              goto: emailVerifyGoto,
            },
          },
          {},
        )
        .pipe(
          map(response =>
            transformResponseData(response, transformUserCreateResonse),
          ),
          untilDestroyed(this),
        ),
    );
  }

  /**
   * Handle successful authentication, be it on login or signup
   */
  handleSuccess(
    action: 'login' | 'signup',
    userId: number,
    authToken: string,
    rememberLogin: boolean,
    redirectParams?: RedirectParams,
  ): Promise<boolean | undefined> {
    // set auth session (automatically sets cookies)
    this.auth.setSession(userId.toString(), authToken);
    // clear referral signup cookies if set
    if (this.authConfig.referralCookie) {
      this.cookies.remove(this.authConfig.referralCookie);
    }

    // Close the login session expired alert after login
    this.toastAlertService.close('login-session-expired');

    const extraCookiesPromise = this.setExtraCookies();

    const crossDomainSSOPromise = this.setCrossDomainSSO(
      userId,
      authToken,
      rememberLogin,
    );

    const redirectPromise = this.getRedirectUrl(action, redirectParams);

    // wait for both reqest chains to finish, then redirect
    return Promise.all([
      redirectPromise,
      crossDomainSSOPromise,
      extraCookiesPromise,
    ]).then(([redirectUrl]) => {
      if (!redirectUrl) {
        return;
      }

      // absolute URL: do a hard navigate
      if (redirectUrl.startsWith('http')) {
        return this.location.redirect(redirectUrl);
      }

      // parse query params and fragments in the nextUrl
      const urlTree = this.router.parseUrl(redirectUrl);

      return this.location.navigateByUrl(urlTree, {
        initialStateIsPrimary: true,
      });
    });
  }

  /**
   * Sets additional cookies after successful login and signup
   */
  async setExtraCookies(): Promise<void> {
    // Sets a cookie to identify users who have logged in
    // at least once, separating them "from new_users"
    const notNewExpires = new Date();
    notNewExpires.setDate(notNewExpires.getDate() + 365);
    this.cookies.put('GETAFREE_NOTNEW', 'true', {
      expires: notNewExpires,
    });

    // Sets a quick login fence cookie for quick login links
    const quickLoginFence = await this.getQuickLoginFenceCookie();
    if (quickLoginFence) {
      const quickLoginFenceExpires = new Date();
      quickLoginFenceExpires.setTime(
        quickLoginFenceExpires.getTime() + quickLoginFence.qfence.ttl * 1000,
      );
      this.cookies.put('qfence', quickLoginFence.qfence.value, {
        expires: quickLoginFenceExpires,
        secure: true,
      });
    }
  }

  /**
   * Fetches the quick login fence cookie for quick login links
   */
  getQuickLoginFenceCookie(): Promise<
    QuickLoginFenceGetResultAjax | undefined
  > {
    return firstValueFrom(
      this.freelancerHttp
        .get<QuickLoginFenceGetResultAjax>(`signup/get-quick-login-fence.php`, {
          isGaf: true,
        })
        .pipe(untilDestroyed(this)),
    ).then(response =>
      response.status === 'success' ? response.result : undefined,
    );
  }

  /**
   * Logs a user in on other domains that they should be logged in on.
   * Fetches domains from `getCrossDomainSSODomains.php`, then sends requests
   * to those domains to set cookies on them.
   */
  setCrossDomainSSO(
    userId: number,
    authToken: string,
    rememberLogin: boolean,
  ): Promise<void> {
    // this is equivalent to the `serializeBody` option for freelancerHttp
    // needs to be manually done since we're using raw httpClient for the other domains
    const formBody = this.freelancerHttp._serialize({
      userId,
      authToken,
      rememberLogin,
    });

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/x-www-form-urlencoded',
    );

    return firstValueFrom(
      this.freelancerHttp
        .get<CrossDomainSSODomainsGetResultAjax>(
          'auth/getCrossDomainSSODomains.php',
          { isGaf: true },
        )
        .pipe(untilDestroyed(this)),
    ).then(response => {
      if (response.status === 'error') {
        return;
      }
      return Promise.all(
        response.result.domains.map(domain =>
          firstValueFrom(
            this.rawHttp
              .post<CrossDomainSsoPostResultAjax>(
                `${domain}/ajax-api/auth/crossDomainSSO.php`,
                formBody,
                {
                  headers,
                  withCredentials: true, // send and receive cookies
                },
              )
              .pipe(
                // silence any errors
                catchError(_ => Promise.resolve()),
                untilDestroyed(this),
              ),
          ),
        ),
      ).then();
    });
  }

  /**
   * Returns the next route to load after logging in or signing up
   *
   * Note that this is only called on successful logins and other redirections
   * may still occur for failed attempts.
   *
   * Eg. Suspended users may be redirected to the suspended account page.
   */
  getRedirectUrl(
    action: 'login' | 'signup',
    { redirectDisabled = false, queryParams }: RedirectParams = {},
  ): Promise<string> {
    const {
      next,
      goto,
      project_collaboration_invitation,
      contest_collaboration_invitation,
    } = queryParams || this.activatedRoute.snapshot.queryParams;

    const endpoint =
      action === 'login'
        ? 'auth/getLoginRedirectUrl.php'
        : 'auth/getSignupRedirectUrl.php';

    // TODO: T267853 - go to new-freelancer for freelancers on signup.
    const defaultUrl = 'dashboard';

    return firstValueFrom(
      this.freelancerHttp
        .post<RedirectUrlGetResultAjax>(
          endpoint,
          {
            redirect_disabled: redirectDisabled.toString(),
            next: next && decodeURIComponent(next),
            goto,
            project_collaboration_invitation,
            contest_collaboration_invitation,
          },
          { isGaf: true, serializeBody: true },
        )
        .pipe(untilDestroyed(this)),
    ).then(response =>
      response.status === 'success' ? response.result.redirect_url : defaultUrl,
    );
  }

  /*
   * Submits a request to reset the user's password
   */
  triggerPasswordReset(
    email: string,
  ): Promise<
    | SuccessResponseData<AuthReactivationResponseAjax | undefined>
    | ErrorResponseData<ResetPasswordError>
  > {
    return firstValueFrom(
      this.rawHttp
        .post<SuccessResponseData<AuthReactivationResponseAjax | undefined>>(
          `${this.authConfig.baseUrl}/forgot`,
          this.freelancerHttp._serialize({ email }),
        )
        .pipe(
          catchError((e: HttpErrorResponse) =>
            of({
              status: 'error',
              errorCode: e.error.error_code,
              requestId: e.error.request_id,
            } as ErrorResponseData<ResetPasswordError>),
          ),
          untilDestroyed(this),
        ),
    );
  }

  /*
   * Resets a user's password
   */
  resetPassword(
    newPassword: string,
    confirmPassword: string,
    token: string,
    userId: number,
  ): Promise<
    ResponseData<
      ResetUserPasswordPostResultAjax,
      'UNKNOWN_ERROR' | ResetUserPasswordError
    >
  > {
    return firstValueFrom(
      this.freelancerHttp
        .post<ResetUserPasswordPostResultAjax, ResetUserPasswordError>(
          'users/resetUserPassword.php',
          {
            newPassword,
            confirmPassword,
            token,
            userId,
            csrf_token: this.auth.getCSRFToken(),
          },
          {
            isGaf: true,
            serializeBody: true,
            errorWhitelist: [
              ErrorCodeApi.INTERNAL_SERVER_ERROR, // Valid request but failed to change password
            ],
          },
        )
        .pipe(untilDestroyed(this)),
    );
  }

  /*
   * Validates a request (token and userId) to reset password
   */
  validatePasswordReset(
    token: string,
    userId: string,
  ): Promise<
    ResponseData<
      ResetUserPasswordValidatePostResultAjax,
      'UNKNOWN_ERROR' | ResetUserPasswordError
    >
  > {
    return firstValueFrom(
      this.freelancerHttp
        .post<ResetUserPasswordValidatePostResultAjax, ResetUserPasswordError>(
          'users/resetUserPasswordValidateRequest.php',
          {
            token,
            userId,
          },
          {
            isGaf: true,
            serializeBody: true,
            errorWhitelist: [
              ErrorCodeApi.BAD_REQUEST,
              ErrorCodeApi.UNAUTHORIZED,
            ],
          },
        )
        .pipe(untilDestroyed(this)),
    );
  }

  closeAccount({
    reason,
    canContact,
    feedback,
  }: {
    reason: string;
    canContact: boolean;
    feedback?: string;
  }): Promise<ResponseData<undefined, GafExceptionCodesApi.UNKNOWN_ERROR>> {
    return firstValueFrom(
      this.freelancerHttp
        .put<undefined, GafExceptionCodesApi.UNKNOWN_ERROR>('users/0.1/self', {
          action: 'close_account',
          reason,
          feedback,
          allow_contact: canContact,
        })
        .pipe(untilDestroyed(this)),
    );
  }

  deleteAccount({
    reason,
    canContact,
    feedback,
  }: {
    reason: string;
    canContact: boolean;
    feedback?: string;
  }): Promise<ResponseData<undefined, GafExceptionCodesApi.UNKNOWN_ERROR>> {
    return firstValueFrom(
      this.freelancerHttp
        .put<undefined, GafExceptionCodesApi.UNKNOWN_ERROR>('users/0.1/self', {
          action: 'delete_account',
          reason,
          feedback,
          allow_contact: canContact,
        })
        .pipe(untilDestroyed(this)),
    );
  }

  /**
   * Change user password (new function from aco)
   */
  changePassword({
    currentPassword,
    newPassword,
  }: {
    currentPassword: string;
    newPassword: string;
  }): Promise<ResponseData<undefined, ChangeUserPasswordError>> {
    return firstValueFrom(
      this.freelancerHttp
        .post<undefined, ChangeUserPasswordError>(
          `user/updateUserPassword.php`,
          {
            oldPassword: currentPassword,
            newPassword,
            newPasswordConfirmation: newPassword,
          },
          {
            withCredentials: true,
            isGaf: true,
            serializeBody: true,
          },
        )
        .pipe(untilDestroyed(this)),
    );
  }

  /**
   * Triggers an SSO login flow.
   *
   * Calls the appropriate SDK to authenticate the user,
   * then fetches user details and sends the data to our auth backend.
   */
  triggerSSOLogin(provider: 'facebook' | 'apple' | 'google'): {
    /**
     * Promise that resolves when the user has submitted or cancelled their login,
     * returning either a successful auth response or an error
     */
    authPromise: Promise<
      | SSOResponseData<'facebook', FacebookAuthResponse>
      | SSOResponseData<'apple', AppleSignInResponse>
      | SSOResponseData<'google', GoogleAuthResponse>
    >;
    /**
     * Promise that fetches a user's details through the SSO provider
     * Errors if the initial authPromise errored
     */
    detailsPromise: Promise<SSOUser | undefined>;
    /**
     * Promise that logs the user in via the FLN auth backend
     * Errors if the initial authPromise errored
     */
    loginPromise: Promise<
      ResponseData<AuthSuccessResponseAjax | SSOActionResponse, LoginError>
    >;
  } {
    let ssoService: Facebook | AppleSSO | GoogleSignInService;
    switch (provider) {
      case 'facebook':
        ssoService = this.facebook;
        break;
      case 'google':
        ssoService = this.google;
        break;
      default:
        ssoService = this.apple;
        break;
    }
    // trigger the authentication request to sso providers
    const authPromise: Promise<
      | SSOResponseData<'facebook', FacebookAuthResponse, FacebookSignInError>
      | SSOResponseData<'apple', AppleSignInResponse, AppleSignInError>
      | SSOResponseData<'google', GoogleAuthResponse, GoogleSignInError>
    > = ssoService.login();

    // get user details after login
    const detailsPromise = authPromise.then(response => {
      if (response.status === 'error') {
        return undefined;
      }
      // casting `response` here is safe
      // TS is confused because authPromise is typed as facebookresponse | appleresponse
      // but really it can only be whatever type the ssoService is parametrised with
      return ssoService.getUserDetails((response as any).result);
    });

    // try to log in via our backend
    const loginPromise = authPromise.then(response => {
      if (response.status === 'error') {
        // forward error from SSO attempt
        return response;
      }
      if (response.provider === 'facebook') {
        return this.facebookLogin(response.result);
      }
      if (response.provider === 'google') {
        return this.googleLogin(response.result);
      }
      return this.appleLogin(response.result);
    });

    return {
      authPromise,
      detailsPromise,
      loginPromise,
    };
  }

  /**
   * Saves state that needs to be shared across login / signup when switching routes
   */
  saveState(state: LoginSignupService['savedState']): void {
    this.savedState = state;
  }

  /**
   * Returns and clears the saved state.
   */
  getSavedState(): SavedState {
    const state = this.savedState;
    this.savedState = {
      user: '',
      password: '',
    };
    return state;
  }

  confirmPromptTwoFa(
    uuid: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return firstValueFrom(
      this.freelancerHttp
        .post<AuthSuccessResponseAjax, LoginError>(
          'auth/prompt-two-fa.php',
          {
            uuid,
          },
          {
            isGaf: true,
            serializeBody: true,
          },
        )
        .pipe(untilDestroyed(this)),
    );
  }

  getConfirmedPromptTwoFa(
    preLoginToken: string,
  ): Promise<ResponseData<AuthSuccessResponseAjax, LoginError>> {
    return firstValueFrom(
      this.freelancerHttp
        .get<AuthSuccessResponseAjax, LoginError>('auth/prompt-two-fa.php', {
          isGaf: true,
          params: { preLoginToken },
        })
        .pipe(untilDestroyed(this)),
    );
  }
}
