/**
 * These functions make pseudo-random numbers.
 * The difference here is that they have a fixed seed
 * so they always return the same values given the same inputs.
 *
 * This is necessary for stable tests.
 */
export function generateNumbersInRangeWithDuplicates(
  min: number,
  max: number,
  count: number,
  seed: string,
): readonly number[] {
  const smallBatchSize = Math.floor(count / 3);

  const biggerList = generateNumbersInRange(min, max, count - smallBatchSize);
  const smallerList = randomiseList(biggerList, seed).slice(smallBatchSize);
  return randomiseList([...smallerList, ...biggerList].sort(), seed);
}

export function generateNumbersInRange(
  min: number,
  max: number,
  count: number,
): readonly number[] {
  return count === 1
    ? [min]
    : Array.from(
        { length: count },
        (v, i) => min + ((i % count) * (max - min)) / (count - 1),
      );
}

export function generateArrayWithValues<T>(
  length: number,
  value: T,
): readonly T[] {
  return Array.from({ length }, () => value);
}

export function generateIntegersInRange(
  min: number,
  max: number,
  count: number,
): readonly number[] {
  if (max - min + 1 < count) {
    throw new Error(
      `Cannot generate ${count} integers between ${min} and ${max}.`,
    );
  }
  return generateNumbersInRange(min, max, count).map(x => Math.floor(x));
}

/* eslint-disable no-bitwise */
/* Based on https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript */
function hashCode(s: string): number {
  let hash = 0;
  for (let i = 0; i < s.length; i++) {
    hash = ((hash << 5) - hash + s.charCodeAt(i)) | 0;
  }
  return hash;
}
/* eslint-enable no-bitwise */

/**
 * A highly-pseudo seedable, random number generator
 * Based on code from https://stackoverflow.com/a/19303725
 *
 * This function should probably not be exported, and instead
 * developers should prefer functions such as `randomiseList`.
 */
function random(index: number, seed: string): number {
  const x = Math.sin(index + 1 + hashCode(seed)) * 10_000;
  return x - Math.floor(x);
}

export function randomiseList<T>(
  list: readonly T[],
  seed: string,
): readonly T[] {
  return list
    .map((value, index) => [value, random(index, seed)] as const)
    .sort(([, a], [, b]) => b - a)
    .map(([a]) => a);
}

export const generateId = (() => {
  let id = 0;
  let index = 0;

  return () => {
    id += Math.ceil(random(index++, 'ids') * 1000);
    if (id > Number.MAX_SAFE_INTEGER) {
      throw new Error(
        `Ran out of ids, they have gone above the max safe integer ${Number.MAX_SAFE_INTEGER}`,
      );
    }
    return id;
  };
})();

export function average(values: readonly number[]): number | undefined {
  if (values.length === 0) {
    return undefined;
  }

  return values.reduce((a, b) => a + b, 0) / values.length;
}
