/**
 * The purpose of the notifications events are to notify our users, and
 * to indicate state changes around our platform. However the notifications
 * event type has been around for some time and not all events defined here
 * service the purpose of "notifying" users.
 *
 * Websocket notifications events are websocket events whose `parent_type`
 * is `notifications`.
 *
 * Event models that live here: `{ parent_type: 'notifications' }`
 */
import type { EmptyObject, UndefinedFieldsToNull } from '@freelancer/types';
import type { AiTaskApi } from 'api-typings/ai/ai';
import type {
  CurrencyApi,
  ReactionApi,
  TimezoneApi,
} from 'api-typings/common/common';
import type { ContestUpgradeApi } from 'api-typings/contests/contests';
import type { FeedItemApi } from 'api-typings/feed/feed';
import type {
  GroupApi,
  GroupMemberApi,
  GroupRolePermissionSelfGetResultApi,
} from 'api-typings/groups/groups';
import type { ThreadApi } from 'api-typings/messages/messages_types';
import type {
  CartItemApi,
  CreditCardApi,
  HourlyContractApi,
  InvoiceStatusApi,
  PaypalApi,
  PaytmReferenceApi,
  VirtualPaymentMethodReferenceApi,
} from 'api-typings/payments/payments';
import type { PostApi } from 'api-typings/posts/posts';
import type {
  BidAwardStatusApi,
  BidCompleteStatusApi,
  BidEditRequestStatusApi,
  IpContractApi,
  MilestoneCancelRequestReasonApi,
  MilestoneStatusApi,
  ProjectCollaborationApi,
  ProjectCollaborationPermissionApi,
  ProjectCollaborationStatusApi,
  ProjectQuestionApi,
  ProjectStatusApi,
  ProjectSubStatusApi,
  ProjectTitleEditRequestStatusApi,
} from 'api-typings/projects/projects';
import type { EnterpriseMetadataValueApi } from 'api-typings/resources/metadata';
import type { TaskApi } from 'api-typings/tasklist/tasklist';
import type { SessionApi } from 'api-typings/timetracker/timetracker';
import type {
  BalanceApi,
  NetPromoterScoreSourceApi,
  NetPromoterScoreTypeApi,
  SecurePhoneGetResultApi,
  ShowcaseSourceTypeApi,
  UserStatusApi,
} from 'api-typings/users/users';
import type { TimeSeconds } from '../time/time.model';
import type { BaseServerData } from './server.types';

export enum KYCStatus {
  APPROVED = 'approved',
  DECLINED = 'declined',
  PENDING = 'pending',
  RECOMMENDED = 'recommended',
  REQUIRED = 'required',
}

export enum NotificationProjectType {
  HIRE_ME = 'hireMe',
  NORMAL = 'normal',
}

export enum QHPCreateType {
  INLINE_REHIRE = 'inline-rehire',
  NORMAL = 'normal',
}

export enum GroupNotificationContextType {
  COMMENT = 'comment',
  POST = 'post',
  CHECK_IN_POST = 'check_in_post', // Used when the check-in_post notification diverges from normal posts.
}

/**
 * Base Notifications event definition which all notifications events should
 * extend from.
 */
export interface BaseServerNotificationsData<C> extends BaseServerData<C> {
  readonly parent_type: 'notifications';
}

/**
 * Base Messages event definition which all messages events should
 * extend from.
 */
export interface BaseServerMessagesEvent<C> extends BaseServerData<C> {
  readonly parent_type: 'messages';
}

/**
 * Unsupported notifications events to be ignored.
 */
export interface WebsocketDeprecatedNotificationsEvent
  extends BaseServerNotificationsData<any> {
  readonly type:
    | 'bidHidden' // don't remove this unless elastic search is cleaned
    | 'contactRequestReceived'
    | 'contactRequestSent'
    | 'sitesBinCreated'
    | 'sitesHandOverMessageSent'
    | 'sitesListingStatusChanged'
    | 'sitesMessageSent'
    | 'sitesOfferCreated'
    | 'tasklist_create' // Renamed to tasklistCreateV1
    | 'linkedProjectAwardReminderTest'
    | 'reviewActivateTest'
    | 'reviewActivateTest2'
    | 'rejectedTest';
}

/**
 * Events that are unknown, or have yet to be defined.
 *
 * Basically everything in `event_notify.php` that doesn't have a
 * corresponding type in this file ends up here.
 */
export interface WebsocketUnknownNotificationsEvent
  extends BaseServerNotificationsData<any> {
  readonly type:
    | 'aborted'
    | 'adminWarningUserBidRestriction'
    | 'banUserFromBidding'
    | 'bidsRunningOut'
    | 'buyFreeMarketService'
    | 'collaboratorAddedViaChat'
    | 'collaboratorsUpdatedRefreshChat'
    | 'communityPromoNoti'
    | 'contactsAutoAddedReceiver'
    | 'contactsAutoAddedSender'
    | 'contestCompleteSellEntryUpsell'
    | 'contestLockedEmployer'
    | 'contestLockedFreelancer'
    | 'contestUpsellFeaturedUpgrade'
    | 'counterOffer'
    | 'deleteFile'
    | 'downgradeToFreeMembershipNoti'
    | 'draftContestRealtime'
    | 'employerWelcome'
    | 'examPassedUpsell'
    | 'examRetakingDiscount'
    | 'expertsPromoNoti'
    | 'firstEarning'
    | 'freeMarketServiceExpired'
    | 'freeMarketServiceIsExpired'
    | 'generic'
    | 'hostingRequest'
    | 'hostingShare'
    | 'inviteFriends'
    | 'invoiceAcceptedPartial'
    | 'invoicePartialPaid'
    | 'localJobsActivation'
    | 'negotiatedProjectStatusChange'
    | 'pmb'
    | 'pmb_public'
    | 'prehireProjectCreated'
    | 'projectStatusChange'
    | 'promoteContest'
    | 'removeUserFromDirectory'
    | 'reviewPending'
    | 'reviewposted'
    | 'secondBidPlusTrialUpsell'
    | 'sellFreeMarketService'
    | 'serviceLimitReached'
    | 'serviceProjectEndedBuyer'
    | 'serviceProjectEndedSeller'
    | 'serviceProjectPostedBuyer' // possible union with 'serviceProjectPostedSeller'?
    | 'serviceSellerPromotion'
    | 'serviceSubmitted'
    | 'showcasePromoNoti'
    | 'sitesBidCreated'
    | 'specialLoyaltyDiscount'
    | 'supportGetHelpNewsfeed'
    | 'tombstoneNoti'
    | 'tombstoneNotiEmployer'
    | 'tombstoneNotiFreelancer'
    | 'upsellAssistedUpgrade'
    | 'upsellFeatureUpgrade'
    | 'upsellIntro'
    | 'userSideAction'
    | 'userSideActionFeedback'
    | 'yahooMailAlert';
}

// This models the data from getContestData() in event_notify.php
export interface WebsocketContestData extends TimeSeconds {
  readonly id: string | number;
  readonly actionText: any;
  readonly appended_descr: string;
  readonly buyer: string;
  readonly buyerUrl: string;
  readonly imgUrl: string;
  readonly isTest: boolean;
  readonly jobs: readonly number[] | readonly string[];
  readonly jobString: string;
  readonly linkUrl: string;
  readonly title: string;
  readonly userId: string;
  readonly userName: string;
}

// This models the data from pack_project() in event_notify.php
export interface WebsocketProjectData {
  readonly id: number;
  readonly actionText: string;
  readonly appended_descr: string;
  readonly imgUrl: string;
  readonly isTest: boolean;
  readonly jobs: readonly string[] | null;
  readonly jobString: string;
  readonly linkUrl: string;
  readonly recruiter: boolean;
  readonly text: string;
  readonly title: string;
  readonly userId: string | number;
  readonly userName: string;
}

interface WebsocketServiceData {
  readonly id: number;
  readonly service_id: string | number;
  readonly service_name: string;
  readonly service_url: string;
  readonly service_duration: string;
  readonly service_cost: string | number;
  readonly currency_sign: string;
  readonly currency_code: string;
  readonly service_img: string; // A URL
}

interface WebsocketArticleCommentEventData {
  readonly article_author: string | number; // The ID of author
  readonly article_name: string;
  readonly article_type: string; // Some enum?
  readonly comment_by: string | number;
  readonly comment_date:
    | string
    | {
        readonly timezone_type: number;
        readonly date: string;
        readonly timezone: string;
      };
  readonly comment_id: string | number;
  readonly comment: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly publicName: string;
  readonly user: string;
}

export interface WebsocketArticleCommentEvent
  extends BaseServerNotificationsData<WebsocketArticleCommentEventData> {
  readonly type: 'articleCommentReceived';
}

interface WebsocketSponsorBidEventData {
  readonly id: string | number; // project id
  readonly object: {
    readonly bid: {
      readonly id: string | number;
      // readonly awardTime: string | number | null ,
      // readonly award_status: string | false,
      // readonly buyer_comment: string | null,
      // readonly buyer_rating: number | null,
      // readonly complete_status: string | null,
      // readonly descr: string | null,
      // readonly firstsubmitdate: string,
      // readonly hidden: boolean,
      // readonly hide_reason: null,
      // readonly highlight: boolean,
      // readonly loaded: boolean,
      // readonly milestone_percentage: string | number,
      // readonly notifylowerbids: boolean,
      // readonly paid_status: string | null,
      // readonly period: string | number,
      // readonly project_id: number,
      // readonly retracted: boolean,
      // readonly seller: null, // TODO: fix type
      // readonly sellers_notified: boolean,
      // readonly sponsored: null, // TODO: fix type
      // readonly submitdate: string
      readonly sum: string | number; // sponsor amount
      readonly users_id: number;
    };
  };
}

export interface WebsocketSponsorBidEvent
  extends BaseServerNotificationsData<WebsocketSponsorBidEventData> {
  readonly type: 'sponsor_bid';
}

interface WebsocketAwardEventData {
  readonly acceptByTime?: number;
  readonly actionText?: string;
  readonly apiMessage: {
    readonly bid: {
      readonly amount: number;
      readonly award_status: BidAwardStatusApi.PENDING;
      readonly bidder_id: number;
      readonly id: number;
      readonly period: number;
    };
    readonly project: {
      readonly currency: { readonly code: string; readonly sign: string };
      readonly id: number;
      readonly owner_id: number;
      readonly title: string;
      readonly type: 'fixed' | 'hourly';
    };
    readonly users: { readonly [id: string]: { readonly username: string } };
  };
  readonly appended_descr: string;
  readonly awardeeId?: number;
  readonly awardeeName?: string;
  readonly bid: {
    readonly bidAmount: string;
    readonly bidDescr: string | null;
    readonly bidId: number;
    readonly bidPeriod: string;
    readonly id: number;
  };
  readonly categoryName?: string;
  readonly currencycode: string;
  readonly currencysign: string;
  readonly id: number;
  readonly imgUrl?: string;
  readonly isTest?: boolean;
  readonly jobs: readonly string[];
  readonly jobString: string;
  readonly linkUrl?: string;
  readonly publicName: string;
  readonly projIsHourly: boolean;
  readonly text?: string;
  readonly title: string;
  readonly userId: number;
  readonly userName: string;
}

export interface WebsocketAwardEvent
  extends BaseServerNotificationsData<WebsocketAwardEventData> {
  readonly type: 'award';
  readonly state?: string;
}

interface WebsocketAwardBadgeEventData {
  readonly descr: string;
  readonly id: number;
  readonly linkUrl: string;
  readonly name: string;
}

export interface WebsocketAwardBadgeEvent
  extends BaseServerNotificationsData<WebsocketAwardBadgeEventData> {
  readonly type: 'awardBadge';
}

interface WebsocketAwardCreditEventData {
  readonly amount: string | number;
  readonly id: string | number;
  readonly linkUrl: string;
}

export interface WebsocketAwardCreditEvent
  extends BaseServerNotificationsData<WebsocketAwardCreditEventData> {
  readonly type: 'awardCredit';
}

interface WebsocketAwardReminderEventData {
  // Note, this doesn't actually have an `id`.
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly userName: string;
  readonly bid: { readonly id: string | number };
  // TODO: T37457 There's more in event_notify.php but currently the navigation only needs this.
}

export interface WebsocketAwardReminderEvent
  extends BaseServerNotificationsData<WebsocketAwardReminderEventData> {
  readonly type: 'awardReminder';
}

interface WebsocketInternalLinkedProjectAwardReminderEventData {
  readonly internalProjectId: number;
  readonly internalProjectUrl: string;
  readonly internalProjectName: string;
  readonly externalProjectIds: readonly number[];
  readonly externalProjectsStates: { readonly [index: number]: string };
  readonly enterpriseId: number;
}

export interface WebsocketInternalLinkedProjectAwardReminderEvent
  extends BaseServerNotificationsData<WebsocketInternalLinkedProjectAwardReminderEventData> {
  readonly type: 'internalLinkedProjectAwardReminder';
}

interface WebsocketAwardMilestoneReminderEventData {
  readonly appended_descr: string;
  readonly currencycode: string;
  readonly currencyid: string | number;
  readonly currencysign: string;
  readonly id: string | number;
  readonly imgUrl: string;
  // isAutomaticPayments: boolean;
  readonly linkUrl: string;
  readonly projIsHourly: boolean;
  // TODO: T243830 Frontend should be able to use isInsource straight from the backend. Currently we're doing in the transformers as a temporary measure for MVP.
  readonly projIsToken: boolean;
  readonly publicName?: string;
  readonly title: string;
  readonly userId: string | number;
  readonly userName: string;
  readonly enterpriseId?: number;
  // TODO: T37457 There's more in event_notify.php but currently the navigation only needs this.
}

export interface WebsocketAwardMilestoneReminderEvent
  extends BaseServerNotificationsData<WebsocketAwardMilestoneReminderEventData> {
  readonly type: 'awardMilestoneReminder';
}

interface WebsocketAwardXpEventData {
  readonly amount: string | number;
  readonly descr: string;
  readonly game: 'xp';
  readonly id: string | number;
  readonly linkUrl: string;
}

export interface WebsocketAwardXpEvent
  extends BaseServerNotificationsData<WebsocketAwardXpEventData> {
  readonly type: 'awardXp';
}

interface WebsocketBalanceUpdateEventData {
  readonly balances: readonly BalanceApi[];
  readonly primary_currency: CurrencyApi;
}

export interface WebsocketBalanceUpdateEvent
  extends BaseServerNotificationsData<WebsocketBalanceUpdateEventData> {
  readonly type: 'balanceUpdate';
}

interface WebsocketBidEventProjectCount {
  readonly all: number;
  readonly incomplete_reviews: number;
  readonly complete: number;
  readonly completion_rate: number;
  readonly reviews: number;
  readonly earnings: number;
  readonly incomplete: number;
}

interface WebsocketBidEventReputation {
  readonly com: number;
  readonly exp: number;
  readonly hag: number;
  readonly onb: number;
  readonly ont: number;
  readonly overall: number;
  readonly pos: number;
  readonly pro: number;
  readonly qua: number;
}

interface WebsocketBidEventSellerRating {
  readonly count: number;
  readonly projectCount: {
    readonly last12months: WebsocketBidEventProjectCount;
    readonly last3months: WebsocketBidEventProjectCount;
    readonly entire_history: WebsocketBidEventProjectCount;
  };
  readonly completionRate: number;
  readonly avg: number;
  readonly reputation: {
    readonly last12months: WebsocketBidEventReputation;
    readonly last3months: WebsocketBidEventReputation;
    readonly entire_history: WebsocketBidEventReputation;
  };
}

interface WebsocketBidretractedEventData {
  readonly bid_amount: number;
  readonly bid_id: number;
  readonly project_id: number;
  readonly seller_id: number;
}

export interface WebsocketBidretractedEvent
  extends BaseServerNotificationsData<WebsocketBidretractedEventData> {
  readonly type: 'bidretracted';
}

interface WebsocketBidEventData {
  readonly accountId?: number;
  readonly amount: string;
  readonly award?: string;
  readonly bidAvg?: string | number | false;
  readonly bidCount?: number;
  readonly bidHidden: boolean;
  readonly bidNo?: string | false | null;
  readonly buyerFeePerDollar?: string;
  readonly buyerMinProjectFee?: string;
  readonly categoryName?: string;
  readonly currency: string;
  readonly currencyCode: string;
  readonly currencyId: string;
  readonly descr: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly initMilestone?: number;
  readonly linkUrl?: string;
  readonly period: string;
  readonly pmb: any;
  readonly projectId: string | number;
  readonly projIsFullTime: boolean;
  readonly projIsHourly: boolean;
  readonly projIsToken: boolean;
  readonly projName: string;
  readonly publicName?: string;
  readonly showAwardFee: boolean;
  readonly text?: string;
  readonly title: string;
  readonly userId: string | number;
  readonly userName: string;
  readonly userBalances?: {
    readonly all:
      | readonly {
          readonly currency: {
            readonly code: string;
            readonly country: string;
            readonly exchangerate: string;
            readonly id: string;
            readonly is_external?: string;
            readonly name: string;
            readonly seq: string;
            readonly sign: string;
          };
          readonly balance: string;
        }[]
      | null;
    readonly relevant: number;
  } | null;
  readonly bid: {
    readonly award_score?: number;
    readonly completed?: boolean;
    readonly completion_score?: number;
    readonly descr_html?: string;
    readonly descr_short_html?: string;
    readonly descr_short?: string;
    readonly descr?: string | null;
    // expert_guarantee?: ExpertGuaranteeApi | null; // These fields in ExpertGuaranteeApi can be strings in the actualy websocket
    readonly highlighted?: boolean | null;
    readonly id: string | number;
    readonly isShortlisted?: boolean | null;
    readonly milestone_percentage?: string | number;
    readonly no?: string | false | null;
    readonly paid_status?: false | null;
    readonly period?: string;
    readonly pitchId?: string;
    readonly project_id?: string | number;
    readonly r2?: number;
    readonly ranking?: number;
    readonly score?: number;
    readonly sponsored?: boolean | null;
    readonly submitdate_f?: string;
    readonly submitdate_f2?: string;
    readonly submitdate_ts?: number;
    readonly submitdate?: string;
    readonly sum?: string;
    readonly users_id?: number;
    readonly user?: {
      readonly address: {
        readonly country: string | null;
        readonly code: string;
        readonly city: string | null;
      };
      readonly company: string | null;
      readonly currency: number;
      readonly earnings: string | number;
      readonly flag_class: string;
      readonly flag_name: string | null;
      readonly flag: { readonly name: string | null; readonly icon: string };
      readonly gold: number;
      readonly hourlyrate: number | null;
      readonly id: number;
      readonly insignia: readonly {
        readonly name: string;
        readonly title: string;
        readonly examEnabled: string | null;
        readonly examId: string | null;
        readonly id: string;
        readonly description: string;
      }[];
      readonly isPortfolioCreated: boolean;
      readonly jobs: readonly string[];
      readonly logo_Url?: string;
      readonly normalMemberclassLogo: string;
      readonly online?: string | null | false;
      readonly profile_logo_Url?: string;
      readonly provider_rating: {
        readonly count: number;
        readonly avg: string | number;
      };
      readonly publicName?: string;
      readonly rating: {
        readonly count: number;
        readonly avg: string | number;
      };
      readonly reg_date: string;
      readonly reputation_rate: number;
      readonly timezone_info?: TimezoneApi; // This will be missing in old newsfeed objects.
      readonly tooltipMemberclassLogo: string;
      readonly Url?: string;
      readonly username: string;
      readonly seller_rating: WebsocketBidEventSellerRating;
    };
  };
  readonly time: number;
}

export interface WebsocketBidEvent
  extends BaseServerNotificationsData<WebsocketBidEventData> {
  readonly type: 'bid' | 'bid_updated' | 'bid_self';
}

interface BidWebsocketApiMessage {
  readonly bid: {
    readonly amount?: number;
    readonly award_status: BidAwardStatusApi;
    readonly complete_status: BidCompleteStatusApi;
    readonly bidder_id: number;
    readonly id: number;
    readonly period: number;
  };
  readonly project: {
    readonly currency?: {
      readonly code: string;
      readonly id: number;
      readonly sign: string;
    };
    readonly id: number;
    readonly owner_id: number;
    readonly title?: string;
  };
  readonly users?: {
    readonly [index: string]: { readonly username: string };
  };
}

interface WebsocketAcceptedEventData {
  readonly apiMessage: BidWebsocketApiMessage;
  readonly bid: {
    readonly retracted: boolean;
    readonly descr: string | null;
    readonly id: number;
  };
  readonly id: number;
  readonly img: {
    readonly profile_logo_url: string;
  };
  readonly isAutomaticPayments: boolean;
  readonly isHourly: boolean;
  readonly isToken: boolean;
  readonly milestoneCreated: boolean;
  readonly name: string;
  readonly publicName: string;
  readonly seoUrl: string | number | null;
  readonly userName: string;
  readonly userId: number;
  readonly awardeeName: string;
  readonly awardeeId: number;
  readonly bidId: number;
  readonly milestoneIds: readonly number[];
  readonly projectType: NotificationProjectType;
}

export interface WebsocketAcceptedEvent
  extends BaseServerNotificationsData<WebsocketAcceptedEventData> {
  readonly type: 'accepted';
}

interface WebsocketRevokedEventData {
  readonly apiMessage: {
    readonly project: {
      readonly id: number;
      readonly owner_id: number;
      readonly title: string;
      readonly seo_url: string;
      readonly hireme: boolean;
    };
    readonly bid: {
      readonly id: number;
      readonly bidder_id: number;
      readonly award_status: BidAwardStatusApi;
    };
  };
  readonly awardeeId: number;
  readonly bidId: string | number;
  readonly imgUrl: string;
  readonly projectId: number;
}

export interface WebsocketRevokedEvent
  extends BaseServerNotificationsData<WebsocketRevokedEventData> {
  readonly type: 'revoked';
}

interface WebsocketBookmarkedProjectAwardedEventData {
  readonly bidAmount: string;
  readonly currencyCode: string;
  readonly projectId: number;
  readonly projectSeoUrl: string;
  readonly projIsHourly: boolean;
  readonly publicName: string;
  readonly title: string;
  readonly userId: number;
  readonly username: string;
}

export interface WebsocketBookmarkedProjectAwardedEvent
  extends BaseServerNotificationsData<WebsocketBookmarkedProjectAwardedEventData> {
  readonly type: 'bookmarkedProjectAwarded';
}

interface WebsocketCancelMilestoneEventData {
  readonly id: number;
  readonly tranId: string | number;
}

export interface WebsocketCancelMilestoneEvent
  extends BaseServerNotificationsData<WebsocketCancelMilestoneEventData> {
  readonly type: 'cancelMilestone';
}

interface WebsocketProjectCompletedEventData {
  readonly amount: string | number;
  readonly categoryName: string;
  readonly completeStatus: string; // TODO: T37445 Make this a string enum
  readonly currencycode: string;
  readonly currencyid: string | number;
  readonly currencysign: string;
  readonly employerId: string | number;
  readonly employerPublicName?: string;
  readonly employerUsername: string;
  readonly expiredTimestamp: string | null;
  readonly freelancerId: string | number;
  readonly freelancerPublicName?: string;
  readonly freelancerUsername: string;
  readonly id: string | number;
  readonly img_e: { readonly profile_logo_url: string }; // TODO: T37457 There's more in event_notify.php but currently the navigation only needs this.
  readonly img_f: { readonly profile_logo_url: string }; // TODO: T37457 There's more in event_notify.php but currently the navigation only needs this.
  readonly name: string;
  // projIsHourly: boolean;
  readonly selectionId: string | number;
  readonly seoUrl: string | number | null;
  readonly state?: string; // TODO: T37445 Make this a string enum
  readonly submitDate: string;
  readonly bidId?: string | number | null;
}

export interface WebsocketProjectCompletedEvent
  extends BaseServerNotificationsData<WebsocketProjectCompletedEventData> {
  readonly type: 'completed';
  readonly state?: string;
}

interface WebsocketContestEventData extends WebsocketContestData {
  readonly currency?: string;
  readonly currencyCode: string;
  readonly exchangerate: string;
  readonly extended: boolean;
  readonly featured: boolean;
  readonly free_bid_until?: number;
  readonly highlight: any;
  readonly maxbudget?: string | number;
  readonly private: boolean;
  readonly sealed: boolean;
  readonly submitDate: string;
}

export interface WebsocketContestEvent
  extends BaseServerNotificationsData<WebsocketContestEventData> {
  readonly type: 'contest';
}

interface WebsocketContestAwardedToEmployerEventData {
  readonly contestName: string;
  readonly contestSEOURL?: string;
  readonly currencyCode: string;
  readonly currencySign: string;
  readonly entry_id: string;
  readonly entry_number: string;
  readonly entryCount: string;
  readonly freelancerCount: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly isBought: boolean;
  readonly linkUrl: string;
  readonly prize: string | number;
  readonly publicName?: string;
  readonly thumb: string;
  readonly userId: number;
  readonly userName: string;
}

export interface WebsocketContestAwardedToEmployerEvent
  extends BaseServerNotificationsData<WebsocketContestAwardedToEmployerEventData> {
  readonly type: 'contestAwardedToEmployer';
}

interface WebsocketContestAwardedToFreelancerEventData {
  readonly contestName: string;
  readonly contestSEOURL: string;
  readonly currencyCode: string;
  readonly currencySign: string;
  readonly entry_id: string | number;
  readonly entry_number: string | number;
  readonly entryCount: string | number;
  readonly freelancerCount: string | number;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly prize: string | number;
  readonly publicName?: string;
  readonly userId: string | number;
  readonly userName: string;
  readonly thumb: string;
}

export interface WebsocketContestAwardedToFreelancerEvent
  extends BaseServerNotificationsData<WebsocketContestAwardedToFreelancerEventData> {
  readonly type: 'contestAwardedToFreelancer';
}

interface WebsocketContestEntryBoughtToEmployerEventData {
  readonly contestName: string;
  readonly entry_id: string | number;
  readonly entry_number: string | number;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly publicName?: string;
  readonly userName: string;
}

export interface WebsocketContestEntryBoughtToEmployerEvent
  extends BaseServerNotificationsData<WebsocketContestEntryBoughtToEmployerEventData> {
  readonly type: 'contestEntryBoughtToEmployer';
}

interface WebsocketContestEntryBoughtToFreelancerEventData {
  readonly contestName: string;
  readonly entry_id: string | number;
  readonly entry_number: string | number;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly publicName?: string;
  readonly userName: string;
}

interface WebsocketProjectCommentCreateEventData {
  readonly commentCreatorId?: number | null;
  readonly commentCreatorUsername?: string | null;
  readonly commentId: number;
  readonly contextId: number;
  readonly imgUrl?: string | null;
  readonly isFromProjectOwner: boolean;
  readonly projectTitle: string;
  readonly linkUrl: string;
}

export interface WebsocketProjectCommentCreateEvent
  extends BaseServerNotificationsData<WebsocketProjectCommentCreateEventData> {
  readonly type: 'projectCommentCreate';
}

export interface WebsocketContestEntryBoughtToFreelancerEvent
  extends BaseServerNotificationsData<WebsocketContestEntryBoughtToFreelancerEventData> {
  readonly type: 'contestEntryBoughtToFreelancer';
}

interface WebsocketContestEntryEventData {
  readonly contestHolder: string;
  readonly contestId: string;
  readonly contestName: string;
  readonly firstUser?: string;
  readonly firstUserPublicName?: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly secondUser?: string | null;
  readonly secondUserPublicName?: string | null;
  readonly userId: string | number;
}

export interface WebsocketContestEntryEvent
  extends BaseServerNotificationsData<WebsocketContestEntryEventData> {
  readonly type: 'contestEntry';
}

interface WebsocketContestEntryRatedToFreelancerEventData {
  readonly action: string;
  readonly contestId: string;
  readonly contestName: string;
  readonly employerId: string;
  readonly entriesUrl: string;
  readonly entryId: string;
  readonly entryNumber: string;
  readonly entryS3path: string;
  readonly entryThumb: string;
  readonly entryUrl: string;
  readonly freelancerId: string;
  readonly hasOtherEntriesRatedOrRejected: boolean;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly ratedOrRejectedEntriesCount: number;
  readonly rating: string;
}

export interface WebsocketContestEntryRatedToFreelancerEvent
  extends BaseServerNotificationsData<WebsocketContestEntryRatedToFreelancerEventData> {
  readonly type: 'contestEntryRatedToFreelancer';
}

interface WebsocketContestEntryRejectedToFreelancerEventData {
  readonly action: string;
  readonly contestId: string;
  readonly contestName: string;
  readonly employerId: string;
  readonly entriesUrl: string;
  readonly entryId: string;
  readonly entryNumber: string;
  readonly entryS3path: string;
  readonly entryThumb: string;
  readonly entryUrl: string;
  readonly freelancerId: string;
  readonly hasOtherEntriesRatedOrRejected: boolean;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly ratedOrRejectedEntriesCount: number;
  readonly rating: string;
}

export interface WebsocketContestEntryRejectedToFreelancerEvent
  extends BaseServerNotificationsData<WebsocketContestEntryRejectedToFreelancerEventData> {
  readonly type: 'contestEntryRejectedToFreelancer';
}

interface WebsocketContestEntryReconsideredToFreelancerEventData {
  readonly action: string;
  readonly contestId: string;
  readonly contestName: string;
  readonly employerId: string;
  readonly entriesUrl: string;
  readonly entryId: string;
  readonly entryNumber: string;
  readonly entryS3path: string;
  readonly entryThumb: string;
  readonly entryUrl: string;
  readonly freelancerId: string;
  readonly hasOtherEntriesRatedOrRejected: boolean;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly ratedOrRejectedEntriesCount: number;
  readonly rating: string;
}

export interface WebsocketContestEntryReconsideredToFreelancerEvent
  extends BaseServerNotificationsData<WebsocketContestEntryReconsideredToFreelancerEventData> {
  readonly type: 'contestEntryReconsideredToFreelancer';
}

interface WebsocketContestEntryCommentedToFreelancerEventData {
  readonly contestId: string;
  readonly contestName: string;
  readonly entryNumber: string;
  readonly id: string;
  readonly imgUrl: string;
  readonly linkUrl: string;
}

export interface WebsocketContestEntryCommentedToFreelancerEvent
  extends BaseServerNotificationsData<WebsocketContestEntryCommentedToFreelancerEventData> {
  readonly type: 'contestEntryCommentedToFreelancer';
}

interface WebsocketContestExpiredEventData {
  readonly contest_end_date: number;
  readonly contest_name: string;
  readonly contest_url: string;
  readonly isExtended: '0' | '1' | boolean;
  readonly judgingPeriodDays?: number;
}

export interface WebsocketContestExpiredEvent
  extends BaseServerNotificationsData<WebsocketContestExpiredEventData> {
  readonly type: 'contestExpired';
}

interface WebsocketContestPCBNotificationEventData {
  readonly contestHolder: string;
  readonly contestId: string;
  readonly contestName: string;
  readonly firstUser?: string;
  readonly firstUserPublicName?: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly secondUser?: string | null;
  readonly secondUserPublicName?: string | null;
  readonly userId: number;
  readonly userCount: number;
}

export interface WebsocketContestPCBNotificationEvent
  extends BaseServerNotificationsData<WebsocketContestPCBNotificationEventData> {
  readonly type: 'contestPCBNotification';
}

interface WebsocketContestPCBNotificationFullViewEventData {
  readonly contestHolder: string | number;
  readonly contestName: string;
  readonly entryNumber: string | number | null;
  readonly firstUser: string;
  readonly firstUserPublicName?: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly secondUser?: string | null;
  readonly secondUserPublicName?: string | null;
  readonly userId: string | number;
  readonly userCount: number;
}

export interface WebsocketContestPCBNotificationFullViewEvent
  extends BaseServerNotificationsData<WebsocketContestPCBNotificationFullViewEventData> {
  readonly type: 'contestPCBNotificationFullView';
}

interface WebsocketContestUpgradedEventData {
  readonly contest_id: number;
  readonly new_upgrade: ContestUpgradeApi;
  readonly seo_url: string;
}

export interface WebsocketContestUpgradedEvent
  extends BaseServerNotificationsData<WebsocketContestUpgradedEventData> {
  readonly type: 'contestUpgraded';
}

interface WebsocketCorporateTeamBidPlacedEventData {
  readonly bidderId: number;
  readonly bidderName: string;
  readonly bidderUsername: string;
  readonly projectId: number;
  readonly projectSeoUrl: string;
  readonly projectTitle: string;
}

export interface WebsocketCorporateTeamBidPlacedEvent
  extends BaseServerNotificationsData<WebsocketCorporateTeamBidPlacedEventData> {
  readonly type: 'corporateTeamBidPlaced';
}

interface WebsocketDeniedEventData {
  readonly apiMessage: {
    readonly project: {
      readonly id: number;
      readonly title: string;
      readonly owner_id: number;
      readonly currency: {
        readonly sign: string;
        readonly code: string;
      };
    };
    readonly bid: {
      readonly bidder_id: number;
      readonly award_status: 'rejected';
    };
    readonly users: {
      readonly [userId: string]: { readonly username: string };
    };
  };
  readonly autoRepostProjectId?: string | number;
  readonly autoRepostProjectName?: string;
  readonly bidId: string | number;
  readonly currencycode: string;
  readonly currencyId: string | number;
  readonly currencysign: string;
  readonly id: string | number;
  // img: ???;
  readonly imgUrl: string;
  readonly jobsURL: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly projectOfferId?: number;
  readonly projectType: NotificationProjectType;
  readonly projIsHourly: any;
  readonly projIsToken: boolean;
  readonly publicName?: string;
  readonly reason: string;
  readonly seoUrl: string | number | null;
  readonly userId: string | number;
  readonly userName: string;
  readonly poolIds: readonly number[];
  readonly enterpriseIds: readonly number[];
}

export interface WebsocketDeniedEvent
  extends BaseServerNotificationsData<WebsocketDeniedEventData> {
  readonly type: 'denyed';
}

interface WebsocketDraftContestEventData {
  readonly contestEditUrl?: string;
  readonly contestId: string;
  readonly contestName: string;
  readonly contestUrl: string;
  readonly imgUrl: string;
  readonly isPublished: boolean;
}

export interface WebsocketDraftContestEvent
  extends BaseServerNotificationsData<WebsocketDraftContestEventData> {
  readonly type: 'draftContest';
}

interface WebsocketGiveGetChildReceivedSignUpBonusEventData {
  readonly parentId: number;
  readonly childId: number;
  readonly bonus: number;
  readonly currencyCode: string;
  readonly imgUrl: string;
  readonly linkUrl: string;
}

export interface WebsocketGiveGetChildReceivedSignUpBonusEvent
  extends BaseServerNotificationsData<WebsocketGiveGetChildReceivedSignUpBonusEventData> {
  readonly type: 'giveGetChildReceivedSignupBonus';
}

interface WebsocketGiveGetChildSignUpEventData {
  readonly parentId: number;
  readonly childId: number;
  readonly childName: string;
  readonly bonus: number;
  readonly bonusRequirement: number;
  readonly currencyCode: string;
  readonly imgUrl: string;
  readonly linkUrl: string;
}

export interface WebsocketGiveGetChildSignUpEvent
  extends BaseServerNotificationsData<WebsocketGiveGetChildSignUpEventData> {
  readonly type: 'giveGetChildSignUp';
}

interface WebsocketGiveGetChildPartialMilestoneReleaseData {
  readonly parentId: number;
  readonly childId: number;
  readonly childName: string;
  readonly bonusRequirement: number;
  readonly currencyCode: string;
  readonly imgUrl: string;
  readonly linkUrl: string;
}

export interface WebsocketGiveGetChildPartialMilestoneRelease
  extends BaseServerNotificationsData<WebsocketGiveGetChildPartialMilestoneReleaseData> {
  readonly type: 'giveGetChildPartialMilestoneRelease';
}

interface WebsocketGiveGetParentBonusEventData {
  readonly parentId: number;
  readonly bonus: number;
  readonly currencyCode: string;
  readonly imgUrl: string;
  readonly linkUrl: string;
}

export interface WebsocketGiveGetParentBonusEvent
  extends BaseServerNotificationsData<WebsocketGiveGetParentBonusEventData> {
  readonly type: 'giveGetParentBonus';
}

interface WebsocketHighLtvAnnualDiscountEventData {
  readonly id: string | number;
  readonly userDisplayName: string;
  readonly linkUrl: string;
}

export interface WebsocketHighLtvAnnualDiscountEvent
  extends BaseServerNotificationsData<WebsocketHighLtvAnnualDiscountEventData> {
  readonly type: 'highLtvAnnualDiscount';
}

interface WebsocketHireMeEventData {
  readonly acceptByTime?: number;
  readonly appended_descr: string;
  readonly currencyCode: string;
  readonly currencySign: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly jobString: string;
  readonly linkUrl: string;
  readonly period?: string | number;
  readonly projIsHourly: boolean;
  readonly publicName?: string;
  readonly sum: string | number;
  readonly title: string;
  readonly userId: number;
  readonly userName: string;
  // accountId: $project->getAccountId();
  // readonly bid: {
  //   readonly id: string | number;
  // };
  // readonly currency?: { readonly code: string; readonly sign: string };
  // featured: $project->isFeatured();
  // img: $img;
  // name: string;
  // seoUrl: $project->getSeoUrl();
}

export interface WebsocketHireMeEvent
  extends BaseServerNotificationsData<WebsocketHireMeEventData> {
  readonly type: 'hireMe';
  // NOTE: doesn't have isTest, so can't use WebsocketProjectData
  readonly state?: string;
}

interface WebsocketInviteUserBidEventData extends WebsocketProjectData {
  readonly currencycode: string;
  readonly currencysign: string;
  readonly inviteeId: string | number;
  readonly minBudget?: number; // The only place with camelCase min/maxBudget
  readonly maxBudget?: number | false; // TODO: T267853 - use BudgetMinMaxBackend if possible (requires backend changes)
  readonly inviteeName: string;
  readonly projIsHourly: boolean;
  readonly publicName?: string;
}

export interface WebsocketInviteUserBidEvent
  extends BaseServerNotificationsData<WebsocketInviteUserBidEventData> {
  readonly type: 'inviteUserBid';
}

interface WebsocketInviteToContestEventData {
  readonly buyer: string | number; // The ID
  readonly buyerName: string;
  readonly buyerPublicName?: string;
  readonly buyerUrl: string;
  readonly contest_recommended_skills: string;
  readonly currencycode: string;
  readonly currencysign: string;
  readonly description: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly prize: string | number;
  readonly sellerId: string | number;
  readonly sellerName: string;
  readonly sellerPublicName?: string;
  readonly title: string;
  readonly type: string | null;
}

export interface WebsocketInviteToContestEvent
  extends BaseServerNotificationsData<WebsocketInviteToContestEventData> {
  readonly type: 'inviteToContest';
}

interface WebsocketInvoiceUpdatedEventData {
  readonly projectId: number;
  readonly bidId: number;
  readonly buyerId: number;
  readonly sellerId: number;
  readonly invoiceId: number;
  readonly status: InvoiceStatusApi;
  readonly isExternal: boolean;
  readonly isAutomatic: boolean;
  readonly currency: CurrencyApi;
  readonly timeRaised: number;
  readonly periodStart: number;
  readonly periodEnd: number;
  readonly milestoneId: number | null;
  readonly totalHours: number;
  readonly totalHoursAmount: number;
  readonly totalResultAmount: number;
  readonly totalOtherAmount: number;
  readonly totalMilestoneAmount: number;
  readonly latestUnreviewedByEmployer: boolean;
}

export interface WebsocketInvoiceUpdatedEvent
  extends BaseServerNotificationsData<WebsocketInvoiceUpdatedEventData> {
  readonly type: 'invoiceUpdated';
}

interface WebsocketInvoiceRequestedEventData {
  readonly accountId: string | number;
  readonly amount: string | number;
  readonly bidId: string | number;
  readonly currencyCode: string;
  readonly currencySign: string;
  readonly dueDate: string;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly invoiceId: string | number;
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName?: string;
  readonly sellerId: string | number;
  readonly seoUrl: string;
  readonly state?: string; // TODO: T37445 Make this a string enum
  readonly userId: string | number;
  readonly userName: string;
}

export interface WebsocketInvoiceRequestedEvent
  extends BaseServerNotificationsData<WebsocketInvoiceRequestedEventData> {
  readonly type: 'invoiceRequested';
}

interface WebsocketLevelUpEventData {
  readonly id: string | number;
  readonly level: string;
  readonly membership: boolean;
  readonly perks: readonly {
    readonly description: string;
    readonly item: boolean;
    readonly magnitude: number;
    readonly name?: 'BidRefreshRate' | 'item';
    readonly symbol: string;
  }[];
  readonly username: string;
  readonly linkUrl: string;
  readonly game: 'level';
}

export interface WebsocketLevelUpEvent
  extends BaseServerNotificationsData<WebsocketLevelUpEventData> {
  readonly type: 'levelUp';
}

export interface WebsocketLogoutEvent
  extends BaseServerNotificationsData<EmptyObject> {
  readonly type: 'logout';
}

export interface WebsocketNotifyFollowerEvent
  extends BaseServerNotificationsData<WebsocketProjectData> {
  readonly type: 'notifyfollower';
}

interface WebsocketNPSSubmittedEventData {
  readonly netPromoterScore: {
    readonly id: number;
    readonly from_user_id: number;
    readonly type: NetPromoterScoreTypeApi;
    readonly score: number;
    readonly comment: string;
    readonly source: NetPromoterScoreSourceApi;
    readonly source_id: number;
    readonly timestamp: number | null;
    readonly to_entity_id: number;
  };
}

export interface WebsocketNPSSubmittedEvent
  extends BaseServerNotificationsData<WebsocketNPSSubmittedEventData> {
  readonly type: 'npsSubmitted';
}

interface WebsocketQuickHireProjectEventData extends WebsocketProjectData {
  readonly bidderId: string | number;
  readonly bidderName: string;
  readonly currency?: { readonly code: string; readonly sign: string };
  // featured: boolean; // TODO: T37447 Are these boolean or something different?
  // fulltime: boolean; // TODO: T37447 Are these boolean or something different?
  // hideBids: boolean; // TODO: T37447 Are these boolean or something different?
  // nonpublic: boolean; // TODO: T37447 Are these boolean or something different?
  readonly period?: string | number;
  readonly projIsHourly: boolean;
  readonly publicName?: string;
  readonly submitDate: string;
  readonly sum?: string | number;
}

export interface WebsocketQuickHireProjectEvent
  extends BaseServerNotificationsData<WebsocketQuickHireProjectEventData> {
  readonly type: 'quickHireProject';
}

interface WebsocketReleaseMilestoneEventData {
  readonly accountId: string | number;
  readonly amount: string | number;
  readonly currencycode: string;
  readonly currencysign: string;
  readonly id: string | number;
  // img: ???;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly otherReason: string;
  readonly publicName?: string;
  readonly seoUrl: string | number | null;
  readonly submitDate: string;
  readonly title: string;
  readonly userId: string | number;
  readonly userName: string;
  readonly milestoneId: string | number;
}

export interface WebsocketReleaseMilestoneEvent
  extends BaseServerNotificationsData<WebsocketReleaseMilestoneEventData> {
  readonly type: 'releaseMilestone';
}

interface WebsocketRequestEndProjectEventData {
  readonly accountId: string | number;
  readonly bidId: string | number;
  readonly categoryName: string;
  readonly dayCount: string | number;
  readonly id: string | number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName?: string;
  readonly seoUrl: string | number | null;
  readonly submitDate: string;
  readonly userId: string | number;
  readonly userName: string;
}

export interface WebsocketRequestEndProjectEvent
  extends BaseServerNotificationsData<WebsocketRequestEndProjectEventData> {
  readonly type: 'requestEndProject';
}

interface WebsocketRequestMilestoneEventData {
  readonly accountId: string | number;
  readonly amount: string | number;
  readonly bidId: string | number;
  readonly currencyCode: string;
  readonly currencyId: string | number;
  readonly currencySign: string;
  readonly description: string;
  readonly id: string | number;
  // img: ???;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName?: string;
  readonly requestId: string | number;
  readonly sellerId: string | number;
  readonly seoUrl: string | number | null;
  readonly state?: string; // TODO: T37445 Make this a string enum
  readonly submitDate: string;
  readonly userId: string | number;
  readonly userName: string;
  readonly isReminder?: boolean;
  readonly requestTime?: number | null;
}

export interface WebsocketRequestMilestoneEvent
  extends BaseServerNotificationsData<WebsocketRequestMilestoneEventData> {
  readonly type: 'requestMilestone';
}

interface WebsocketRequestToReleaseEventData {
  readonly accountId: string | number;
  readonly amount: string | number;
  readonly bidId: string | number;
  readonly currencycode: string;
  readonly currencysign: string;
  readonly descr: string;
  readonly id: string | number;
  // img: ???;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName?: string;
  readonly seoUrl: string | number | null;
  readonly state?: string; // TODO: T37445 Make this a string enum
  readonly tranId: string | number;
  readonly userId: string | number;
  readonly userName: string;
  readonly isReminder?: boolean;
  readonly releaseRequestTime?: number;
}

export interface WebsocketRequestToReleaseEvent
  extends BaseServerNotificationsData<WebsocketRequestToReleaseEventData> {
  readonly type: 'requestToRelease';
}

export interface WebsocketServiceApprovedEvent
  extends BaseServerNotificationsData<WebsocketServiceData> {
  readonly type: 'serviceApproved';
}

interface WebsocketServiceProjectEndingSellerEventData {
  readonly bid_id: string | number;
  readonly buyer_id: string | number;
  readonly buyer_name: string;
  readonly hours_remaining: number;
  readonly id: string | number;
  readonly order_start_date: string;
  readonly project_id: string | number;
  readonly project_name: string;
  readonly project_url: string;
  readonly seller_id: string | number;
  readonly seller_name: string;
  readonly service_id: string | number;
  readonly service_name: string;
}

export interface WebsocketServiceProjectEndingSellerEvent
  extends BaseServerNotificationsData<WebsocketServiceProjectEndingSellerEventData> {
  readonly type: 'serviceProjectEndingSeller';
}

interface WebsocketServiceProjectPostedSellerEventData {
  readonly bid_id: string | number;
  readonly buyer_id: string | number;
  readonly buyer_img_url: string;
  readonly buyer_name: string;
  readonly buyer_public_name?: string;
  readonly currency_code: string;
  readonly currency_sign: string;
  // Not used or sent from the back-end.
  // readonly id: string | number;
  readonly order_end_date: string;
  readonly order_start_date: string;
  readonly project_cost: string | number;
  // project_duration: ???;
  readonly project_id: string | number;
  // project_milestones: ???;
  readonly project_name: string;
  readonly project_url: string;
  readonly seller_id: string | number;
  readonly seller_img_url: string;
  readonly seller_name: string;
  readonly seller_public_name: string;
  readonly service_id: string | number;
  readonly service_name: string;
  readonly service_url: string;
}

export interface WebsocketServiceProjectPostedSellerEvent
  extends BaseServerNotificationsData<WebsocketServiceProjectPostedSellerEventData> {
  readonly type: 'serviceProjectPostedSeller';
}

interface WebsocketServiceStatusChangedEventData {
  readonly id: string | number;
  // ???
}

export interface WebsocketServiceStatusChangedEvent
  extends BaseServerNotificationsData<WebsocketServiceStatusChangedEventData> {
  readonly type: 'serviceStatusChanged';
}

export interface WebsocketServiceRejectedEvent
  extends BaseServerNotificationsData<WebsocketServiceData> {
  readonly type: 'serviceRejected';
}

interface WebsocketSignUpFreeTrialUpsellEventData {
  readonly id: string | number;
  readonly linkUrl: string;
  readonly trialPackageName: string;
}

export interface WebsocketSignUpFreeTrialUpsellEvent
  extends BaseServerNotificationsData<WebsocketSignUpFreeTrialUpsellEventData> {
  readonly type: 'signUpFreeTrialUpsell';
}

interface WebsocketUpsellCrowdsourceFindingEventData {
  readonly id: string | number;
  readonly project_name: string;
}

export interface WebsocketUpsellCrowdsourceFindingEvent
  extends BaseServerNotificationsData<WebsocketUpsellCrowdsourceFindingEventData> {
  readonly type: 'upsellCrowdsourceFinding';
}

interface WebsocketXpContestEventData extends WebsocketContestData {
  readonly currency: string;
  readonly currencyCode: string;
  readonly maxbudget: string | number;
}

export interface WebsocketXpContestEvent
  extends BaseServerNotificationsData<WebsocketXpContestEventData> {
  readonly type: 'xpContest';
}

interface WebsocketTaskCommentCreateEventData {
  readonly commentCreatorId?: number | null;
  readonly commentCreatorUsername?: string | null;
  readonly commentId: number;
  readonly contextId: number;
  readonly imgUrl?: string | null;
  readonly linkUrl: string;
  readonly taskTitle: string;
}

export interface WebsocketTaskCommentCreateEvent
  extends BaseServerNotificationsData<WebsocketTaskCommentCreateEventData> {
  readonly type: 'taskCommentCreate';
}

interface WebsocketTaskEventData extends TaskApi {
  readonly imgUrl: string;
  readonly isAssignee?: boolean;
  readonly linkUrl: string;
  readonly oldTaskTitle?: string | null;
  readonly taskEditorId?: number;
  readonly taskEditorUsername?: string;
}

export interface WebsocketTaskCreateEvent
  extends BaseServerNotificationsData<WebsocketTaskEventData> {
  readonly type: 'tasklistCreateV1';
}

export interface WebsocketTaskUpdateEvent
  extends BaseServerNotificationsData<WebsocketTaskEventData> {
  readonly type: 'tasklistUpdateV1';
}

export interface WebsocketTaskMoveEvent
  extends BaseServerNotificationsData<TaskApi> {
  readonly type: 'taskMove';
}

interface WebsocketTaskGroupEventData {
  readonly id: number;
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly taskGroupEditorPublicName: string;
  readonly taskGroupEditorUsername: string;
  readonly taskGroupName: string;
}

export interface WebsocketTaskGroupNewUserEvent
  extends BaseServerNotificationsData<WebsocketTaskGroupEventData> {
  readonly type: 'tasklistNewUser';
}

interface WebsocketContestEntryRatedEventData {
  readonly contestId: string | number;
  readonly contestName: string;
  readonly contestSeoUrl: string;
  readonly employerImg?: { readonly logo_url?: string | boolean };
  readonly employerName: string;
  readonly employerPublicName: string;
  readonly entryId: string | number;
  readonly entryNumber: string;
  readonly entryThumb: string;
  readonly entryUrl: string;
  readonly rating: string;
  readonly time: number;
  // TODO: T37457 There's more in event_notify.php
  // readonly entryName: string;
  // TODO: T267853 - Enum -> 'Withdrawn', 'Won', 'Eliminated', 'Active'
  // readonly status: string;
  // readonly employerId: string;
  // readonly entriesUrl: string;
  // readonly entryS3path: string;
  // readonly freelancerId: string;
  // readonly img: string;
  // readonly userId: string;
  // readonly userName: string;
}

export interface WebsocketContestEntryRatedEvent
  extends BaseServerNotificationsData<WebsocketContestEntryRatedEventData> {
  readonly type: 'contestEntryRated';
}

interface WebsocketReviewPostedEventData {
  readonly img: {
    readonly profile_logo_url: string;
  };
  readonly id: number; // Project id
  readonly invoice_id?: number;
  readonly name: string; // Project name
  readonly projIsHourly: boolean;
  readonly publicName: string; // Freelancer public name
  readonly seoUrl: string | number | null; // Project url
  readonly userId: number; // User who wrote review
  readonly userName: string; // Freelancer username
  // TODO: T37457 There's more in event_notify.php
  // readonly accountId: number;
  // readonly amount: string;
  // readonly currencycode: string;
  // readonly currencyid: string;
  // readonly currencysign: string;
  // readonly expiredTimestamp: string;
  // readonly fromUserId: number;
  readonly isTest: boolean;
}

export interface WebsocketReviewPostedEvent
  extends BaseServerNotificationsData<WebsocketReviewPostedEventData> {
  readonly type: 'reviewpostednew';
  readonly state?: string;
}

interface WebsocketMembershipBenefit {
  readonly benefit_value: number;
  // TODO: T37457 There's more in event_notify.php
  //   readonly benefit: {
  //     readonly display_name: string;
  //     readonly duplicate_rule: number;
  //     readonly id: number;
  //     readonly internal_name: string;
  //     readonly value: number;
  //   };

  //   readonly id: number;
  //   readonly package_id: number;
}

interface WebsocketUpsellPlusTrialEventData {
  // TODO: T37457 There's more in event_notify.php
  // readonly claim_url: string;
  readonly currency_code: string;
  readonly currency_sign: string;
  readonly extendPrice: string;
  readonly img: {
    readonly profile_logo_url: string;
  };
  readonly isBoth: boolean;
  readonly isEmployer: boolean;
  readonly isFreelancer: boolean;
  readonly price: number;
  readonly taxName: string;
  readonly trialBenefits: {
    readonly bids_limit: WebsocketMembershipBenefit;
    readonly offsite_invoice: WebsocketMembershipBenefit;
    readonly skills_limit: WebsocketMembershipBenefit;
    // readonly bid_on_premium_projects: WebsocketMembershipBenefit;
    // readonly bids_for_reviews: WebsocketMembershipBenefit;
    // readonly buyer: WebsocketMembershipBenefit;
    // readonly buyer_hour: WebsocketMembershipBenefit;
    // readonly contest_entry_sold: WebsocketMembershipBenefit;
    // readonly contest_prize: WebsocketMembershipBenefit;
    // readonly cover_photo: WebsocketMembershipBenefit;
    // readonly employer_following: WebsocketMembershipBenefit;
    // readonly escrow_other: WebsocketMembershipBenefit;
    // readonly free_exams: WebsocketMembershipBenefit;
    // readonly free_extend: WebsocketMembershipBenefit;
    // readonly free_hidden_bids: WebsocketMembershipBenefit;
    // readonly free_highlighted_contest_entries: WebsocketMembershipBenefit;
    // readonly free_priority_projects: WebsocketMembershipBenefit;
    // readonly free_sealed_contest_entries: WebsocketMembershipBenefit;
    // readonly freelancer_reward: WebsocketMembershipBenefit;
    // readonly initiate_chat: WebsocketMembershipBenefit;
    // readonly preferred_freelancer: WebsocketMembershipBenefit;
    // readonly premium_project_review_minimum: WebsocketMembershipBenefit;
    // readonly project_watching: WebsocketMembershipBenefit;
    // readonly same_day_withdrawal: WebsocketMembershipBenefit;
    // readonly seller: WebsocketMembershipBenefit;
    // readonly seller_hour: WebsocketMembershipBenefit;
    // readonly seller_hour_pfp: WebsocketMembershipBenefit;
    // readonly seller_pfp: WebsocketMembershipBenefit;
    // readonly seller_rewards: WebsocketMembershipBenefit;
    // readonly sender_escrow_other: WebsocketMembershipBenefit;
    // readonly sender_transfer_other: WebsocketMembershipBenefit;
    // readonly service_posting_limit: WebsocketMembershipBenefit;
    // readonly skills_change_limit: WebsocketMembershipBenefit;
    // readonly transfer_other: WebsocketMembershipBenefit;
    // readonly transfer_tip: WebsocketMembershipBenefit;
  };
  readonly trialPackage: {
    readonly package_id: number;
    readonly package?: {
      readonly upper_name: string;
    };
    readonly pkg?: {
      readonly upper_name: string;
      // readonly benefits: {
      //   readonly benefit: {
      //     readonly display_name: string;
      //     readonly duplicate_rule: number;
      //     readonly id: number;
      //     readonly internal_name: string;
      //     readonly value: number;
      //   };
      //   readonly benefit_value: number;
      //   readonly id: number;
      //   readonly package_id: number;
      // };
      // readonly category_id: number;
      // readonly display_name: string;
      // readonly enabled: boolean;
      // readonly id: number;
      // readonly internal_name: string;
      // readonly order: number;
      // readonly prices: {
      //   readonly amount: number;
      //   readonly cerrency_id: number;
      //   readonly contract_quantity: number;
      //   readonly country: string;
      //   readonly currency_id: number;
      //   readonly duration: {
      //     readonly cycle: number;
      //     readonly id: number;
      //     readonly type: number;
      //   };
      //   readonly package_id: number;
      //   readonly price_id: number;
      //   readonly time_valid_end: number;
      //   readonly time_valid_start: number;
      // };
      // readonly duration: {
      //   readonly cycle: number;
      //   readonly id: number;
      //   readonly type: number;
      // };
      // readonly eligible: boolean;
      // readonly id: number;
      // readonly is_default: boolean;
    };
    // readonly time_valid_start: number;
    // readonly trial_duration: {
    //   readonly cycle: number;
    //   readonly id: number;
    //   readonly type: number;
    // };
    // readonly trial_landing_url: string;
    // readonly trial_landing_url_params: {
    //   readonly TrialUpsell2: boolean;
    //   readonly duration_cycle: number;
    //   readonly duration_type: number;
    //   readonly package: number;
    //   readonly trial: string;
    // };
    // readonly trial_package: {
    //   readonly category_id: number;
    //   readonly display_name: string;
    //   readonly enabled: boolean;
    //   readonly id: number;
    //   readonly internal_name: string;
    //   readonly order: number;
    // };
    // readonly trial_package_id: number;
    // readonly type: string;
  };
  readonly trialPrice: string;
  readonly withTax: boolean;
  readonly with_GST: boolean;
}

export interface WebsocketUpsellPlusTrialEvent
  extends BaseServerNotificationsData<WebsocketUpsellPlusTrialEventData> {
  readonly type: 'upsellPlusTrial';
}

interface WebsocketCompleteReviewEventData {
  readonly id: number; // Project id
  readonly img: {
    readonly profile_logo_url: string;
  };
  readonly invoice_id: number | null;
  // readonly isEmployer: boolean;
  readonly message: string;
  // readonly myRating?: string;
  readonly name: string;
  readonly private: boolean;
  readonly publicName: string;
  readonly rating: string;
  readonly seoUrl: string | number | null;
  readonly userName: string;
  // readonly accountId: number;
  // readonly categoryName: string;
  // readonly isHourly: boolean;
  readonly userId: number;
}

export interface WebsocketCompleteReviewEvent
  extends BaseServerNotificationsData<WebsocketCompleteReviewEventData> {
  readonly type: 'completeReview';
}

interface WebsocketSuggestionForFreelancerAfterReceiveReviewEventData {
  readonly projectId: number;
  readonly projectName: string;
  readonly seoUrl: string | number | null;
  readonly img: {
    readonly profile_logo_url?: string;
  };
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly aggregate: string;
  // readonly userId: number;
}

export interface WebsocketSuggestionForFreelancerAfterReceiveReviewEvent
  extends BaseServerNotificationsData<WebsocketSuggestionForFreelancerAfterReceiveReviewEventData> {
  readonly type: 'suggestionForFreelancerAfterReceiveReview';
}

interface WebsocketUpgradeToNonFreeMembershipEventData {
  readonly autoRenew: boolean | null;
  readonly currencyCode: string;
  readonly currentPlan: string | null;
  readonly freeTrial: boolean | null;
  readonly newPlan: string;
  readonly price: string | number | null;
  readonly refundedAmount: number | null;
  readonly start: string | null;
  readonly end: string | null;
  readonly tax: string | null;
  readonly timeUnit: string | null;
  readonly type: string;
  // readonly membership_mid: boolean;
  // readonly remainingBids: string;
  // readonly showBiddingPromotion: boolean;
  // readonly ctaUrl: string;
  // readonly isDowngrade: boolean;
  // readonly trialDays: number;
}

export interface WebsocketUpgradeToNonFreeMembershipEvent
  extends BaseServerNotificationsData<WebsocketUpgradeToNonFreeMembershipEventData> {
  readonly type: 'upgradeToNonFreeMembership';
}

interface WebsocketContestReviewPostedEventData {
  readonly id: string | number; // Project id
  readonly img?: { readonly logo_url?: string | boolean };
  readonly name: string;
  readonly publicName: string;
  readonly seoUrl: string;
  readonly userId: number;
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly accountId: number; // Review receiver user id
  // readonly assign_to?: string; // TODO: Enum -> 'Freelancer'
  // readonly contest_review_id: number; // Even contest id => freelancer to employer
  // readonly time: number;
}

export interface WebsocketContestReviewPostedEvent
  extends BaseServerNotificationsData<WebsocketContestReviewPostedEventData> {
  readonly type: 'contestreviewposted';
  readonly state?: string;
}

interface WebsocketActivateFreelancerEventData {
  readonly id: number; // User id
  readonly key: string;
  readonly updatedProfile: string; // 'false'
  readonly verifiedEmail: string; // 'false'
}

export interface WebsocketActivateFreelancerEvent
  extends BaseServerNotificationsData<WebsocketActivateFreelancerEventData> {
  readonly type: 'activateFreelancer';
}

interface WebsocketContestCompleteEventData {
  // TODO: T37457 There's more in event_notify.php
  readonly contest_name: string;
  readonly contest_url: string;
  readonly entry_id: string;
  readonly imgUrl: string;
  readonly public_name: string;
  readonly review_id: string;
  readonly to_freelancer: boolean;
  readonly user_name: string;
  // readonly contest_id: string;
  // readonly to_user_id: string | number;
  // readonly from_user_id: string;
  // readonly id: string;
  // readonly time: number;
  // readonly user_profile_url: string;
}

export interface WebsocketContestCompleteEvent
  extends BaseServerNotificationsData<WebsocketContestCompleteEventData> {
  readonly type: 'contestComplete';
}

interface WebsocketContestEntryEngagedEventData {
  readonly contestId: number;
  readonly totalEngageableEntries: number;
  readonly unratedEntries: number;
  readonly engagementRatio: number;
}

export interface WebsocketContestEntryEngagedEvent
  extends BaseServerNotificationsData<WebsocketContestEntryEngagedEventData> {
  readonly type: 'contestEntryEngaged';
}

interface WebsocketKYCEventData {
  readonly country?: string;
  readonly isCorporate?: string; // 'true', 'false'
  readonly status: KYCStatus;
}

export interface WebsocketKYCEvent
  extends BaseServerNotificationsData<WebsocketKYCEventData> {
  readonly type: 'kyc';
}

interface WebsocketVerificationRequestEventData {
  readonly verificationFileIds: readonly string[];
}

export interface WebsocketVerificationRequestEvent
  extends BaseServerNotificationsData<WebsocketVerificationRequestEventData> {
  readonly type: 'verificationRequestRejected';
}

interface WebsocketReviewActivateEventData {
  readonly currencyId: string | number;
  readonly enterpriseId?: number | null;
  readonly enterpriseLinkedInternalProjectId?: number | null;
  readonly enterpriseLinkedInternalProjectTitle?: string | null;
  readonly enterpriseLinkedInternalProjectSeoUrl?: string | null;
  readonly enterpriseLinkedExternalProjectIds?: readonly number[] | null;
  readonly id: number;
  readonly img: { readonly profile_logo_url: string };
  readonly isAutomaticPayments: boolean;
  readonly isHourly: boolean;
  readonly isMilestoneCreated: boolean;
  readonly isQHP: boolean | null;
  readonly name: string;
  readonly notShowEmployerFee: boolean | null;
  readonly projectCreateType: QHPCreateType | null;
  readonly quickhireFreelancerId: string | number | null;
  readonly quickhireFreelancerPublicName: string | null;
  readonly quickhireFreelancerUsername: string | null;
  readonly seoUrl: string | number | null;
  // TODO: T37457 There's more in event_notify.php
  // readonly currencySign: string;
  // readonly categoryName: string;
  // readonly currencyCode: string;
}

export interface WebsocketReviewActivateEvent
  extends BaseServerNotificationsData<WebsocketReviewActivateEventData> {
  readonly type: 'reviewActivate';
}

interface WebsocketContestPCBEventData {
  readonly contestName: string;
  readonly seoUrl: string;
  readonly userPublicName: string;
  readonly username: string;
  readonly imgUrl?: string;
  readonly contestId: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly hash: string;
  // readonly id: number;
  // readonly userUrl: string;
}

export interface WebsocketContestPCBEvent
  extends BaseServerNotificationsData<WebsocketContestPCBEventData> {
  readonly type: 'contest_pcb';
}

interface WebsocketContestCommentEventData {
  readonly commentId: number;
  readonly parentCommentId?: number;
}

export interface WebsocketContestCommentEvent
  extends BaseServerNotificationsData<WebsocketContestCommentEventData> {
  readonly type:
    | 'contestCommentPosted'
    | 'contestCommentDeleted'
    | 'contestCommentUndeleted';
}

interface WebsocketCustomAdminNotificationEventData {
  readonly link_text: string;
  readonly link_url: string;
  readonly noti_description: string;
  readonly noti_text: string;
  readonly id: string; // Usually an empty string
  // readonly weight: string | null;
}

export interface WebsocketCustomAdminNotificationEvent
  extends BaseServerNotificationsData<WebsocketCustomAdminNotificationEventData> {
  readonly type: 'customAdminNotification';
}

interface WebsocketSendDisputeMessageEventData {
  readonly dispute_id: string;
  readonly id: number; // Project id
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly message: string;
  readonly name: string; // Project name
  readonly publicName: string;
  readonly userName: string;
  readonly userId: string;
}

export interface WebsocketSendDisputeMessageEvent
  extends BaseServerNotificationsData<WebsocketSendDisputeMessageEventData> {
  readonly type: 'sendDisputeMessage';
}

interface WebsocketUpdateMilestoneEventData {
  readonly accountId: number;
  readonly amount: number;
  readonly bidId: number;
  readonly currencyCode: string;
  readonly currencySign: string;
  readonly description: string;
  readonly id: number; // Project id
  readonly img?: { readonly logo_url?: string | boolean };
  readonly isSelected: boolean;
  readonly name: string;
  readonly old_amount: number;
  readonly publicName: string;
  readonly requestId: number;
  readonly sellerId: string;
  readonly seoUrl: string | number | null;
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly submitDate: string;
  // readonly time: number;
  // readonly userId: string;
}

export interface WebsocketUpdateMilestoneEvent
  extends BaseServerNotificationsData<WebsocketUpdateMilestoneEventData> {
  readonly type: 'updateMilestone';
}

interface WebsocketWelcomeEventData {
  readonly siteName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly img: string;
  // readonly userId: number;
}

export interface WebsocketWelcomeEvent
  extends BaseServerNotificationsData<WebsocketWelcomeEventData> {
  readonly type: 'welcome';
}

interface WebsocketCompleteContestReviewEventData {
  // TODO: T37457 There's more in event_notify.php
  readonly userId: number; // From User Id (same as accountId)
  readonly userName: string; // From Username
  readonly name: string; // Contest Name
  readonly publicName: string; // Fallback to username on PHP side
  readonly message: string;
  readonly rating: string;
  readonly img?: { readonly logo_url?: string | boolean };
  readonly seoUrl?: string;
  // readonly id?: string | number; // Project id
  // readonly contest_review_id: string | null;
  // readonly categoryName: string;
  // readonly private: boolean;
  // readonly accountId: number;
}

export interface WebsocketCompleteContestReviewEvent
  extends BaseServerNotificationsData<WebsocketCompleteContestReviewEventData> {
  readonly type: 'completeContestReview';
}

interface WebsocketRejectedEventData {
  readonly addtionalMsg?: string;
  readonly id: number;
  readonly enterpriseId?: number | null;
  readonly enterpriseLinkedInternalProjectId?: number | null;
  readonly enterpriseLinkedExternalProjectIds?: readonly number[] | null;
  readonly enterpriseLinkedInternalProjectTitle?: string | null;
  readonly enterpriseLinkedInternalProjectSeoUrl?: string | null;
  readonly name: string;
  readonly rejectReasonConfig?: readonly { readonly descr?: string }[];
  readonly img?: { readonly logo_url?: boolean | string };
  // TODO: T37457 There's more in event_notify.php
  // readonly rejectReasonConfig: {
  //   readonly descr: string;
  //   readonly descr_appendage: string;
  //   readonly id: string;
  //   readonly order_id: string;
  // };
  // readonly linkUrl: string;
  // readonly seoUrl: string | number | null;
}

export interface WebsocketRejectedEvent
  extends BaseServerNotificationsData<WebsocketRejectedEventData> {
  readonly type: 'rejected';
}

interface WebsocketPrizeDispersedFreelancerEventData {
  readonly contest_name: string;
  readonly contest_url: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly employer_name: string;
  // readonly employer_profile_url: string;
  // readonly http_pic_url_head: string;
  // readonly https_pic_url_head: string;
  // readonly pic_url_tail: string;
  // readonly time: number;
}

export interface WebsocketPrizeDispersedFreelancerEvent
  extends BaseServerNotificationsData<WebsocketPrizeDispersedFreelancerEventData> {
  readonly type: 'prizeDispersedFreelancer';
}

interface WebsocketNewDisputeEventData {
  readonly accountId: number;
  readonly dispute_id: number;
  readonly id: number;
  readonly imgUrl?: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName: string;
  readonly reason?: string;
  readonly time: number;
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly currencycode: string;
  // readonly currencysign: string;
  // readonly userId: string;
}

export interface WebsocketNewDisputeEvent
  extends BaseServerNotificationsData<WebsocketNewDisputeEventData> {
  readonly type: 'newDispute';
}

interface WebsocketUploadFileEventData {
  readonly file: {
    readonly id: string | number;
    readonly name: string;
    readonly size: string;
    readonly isDriveFile?: boolean;
  };
  readonly id: string; // Bid id
  readonly img?: { readonly logo_url?: string | boolean };
  readonly projectId: number;
  readonly name: string;
  readonly publicName: string;
  readonly userName: string;
  readonly userId: number;
  readonly seoUrl: string | number | null;
  readonly time: number;
  // TODO: T37457 There's more in event_notify.php
  // readonly accountId: number;
  // readonly file: {
  //   readonly delete_type: string;
  //   readonly delete_url: string;
  //   readonly error: string;
  //   readonly filesize: number;
  //   readonly id: number;
  //   readonly isDriveFile: boolean;
  //   readonly name: string;
  //   readonly s3_obj: string;
  //   readonly submitdate: string;
  //   readonly thumb_obj: string;
  //   readonly thumbnail_url: string;
  //   readonly type: string;
  //   readonly url: string;
  // };
}

export interface WebsocketUploadFileEvent
  extends BaseServerNotificationsData<WebsocketUploadFileEventData> {
  readonly type: 'uploadFile';
}

interface WebsocketReleasePartMilestoneEventData {
  readonly accountId: number;
  readonly amount: string;
  readonly bidId: number;
  readonly currencycode: string;
  readonly currencysign: string;
  readonly id: number; // Project id
  readonly img: { readonly profile_logo_url?: string };
  readonly leftAmount: number;
  readonly linkUrl: string;
  readonly name: string;
  readonly otherReason: string;
  readonly publicName: string;
  readonly tranId: number;
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly seoUrl: string | number | null;
  // readonly reason: string;
  // readonly sellerId: string;
  // readonly submitDate: string;
  // readonly userId: number;
}

export interface WebsocketReleasePartMilestoneEvent
  extends BaseServerNotificationsData<WebsocketReleasePartMilestoneEventData> {
  readonly type: 'releasePartMilestone';
}

interface WebsocketInvoicePaidEventData {
  readonly amount: string;
  readonly currencyCode: string;
  readonly currencySign: string;
  readonly id: string; // Project id
  readonly img: { readonly profile_logo_url: string };
  readonly invoiceId: string | number;
  readonly name: string;
  readonly publicName: string;
  readonly seoUrl: string;
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly accountId: string;
  // readonly amountPayable: string;
  // readonly amountReceived: string;
  // readonly amountUnpaid: string;
  // readonly dueDate: string;
  // readonly projIsHourly: boolean;
  // readonly sellerId: string;
  // readonly userId: string;
}

export interface WebsocketInvoicePaidEvent
  extends BaseServerNotificationsData<WebsocketInvoicePaidEventData> {
  readonly type: 'invoicePaid';
}

interface WebsocketInvoiceFeedbackEventData {
  readonly accountId: string;
  readonly id: string; // Project id
  readonly invoiceId?: number;
  readonly img: { readonly profile_logo_url: string };
  readonly name: string;
  readonly seoUrl: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly amount: string;
  // readonly currencyCode: string;
  // readonly currencyId: string;
  // readonly currencySign: string;
  // readonly dueDate: string;
  // readonly sellerId: string;
  // readonly userName: string;
}

export interface WebsocketInvoiceFeedbackEvent
  extends BaseServerNotificationsData<WebsocketInvoiceFeedbackEventData> {
  readonly type: 'invoiceFeedback';
}

interface WebsocketPendingFundsEventData {
  readonly amount: string;
  readonly currencycode: string;
  readonly currencysign: string;
  readonly descr: string;
  readonly message: string;
  readonly reason: string;
}

export interface WebsocketPendingFundsEvent
  extends BaseServerNotificationsData<WebsocketPendingFundsEventData> {
  readonly type: 'pendingFunds';
}

interface WebsocketEmailChangeEventData {
  readonly img: { readonly profile_logo_url?: string };
  readonly new_email: string;
  readonly requestId: number;
  readonly ipAddress: string | null;
}

export interface WebsocketEmailChangeEvent
  extends BaseServerNotificationsData<WebsocketEmailChangeEventData> {
  readonly type: 'emailChange';
}

interface WebsocketSecurePhoneUpdatedEventData {
  readonly id_verification_required: boolean;
  readonly secure_phone: SecurePhoneGetResultApi;
}

export interface WebsocketSecurePhoneUpdatedEvent
  extends BaseServerNotificationsData<WebsocketSecurePhoneUpdatedEventData> {
  readonly type: 'securePhoneUpdated';
}

interface WebsocketEmailChangeConfirmedEventData {
  readonly newEmail: string;
  readonly requestId: number;
}

export interface WebsocketEmailChangeConfirmedEvent
  extends BaseServerNotificationsData<WebsocketEmailChangeConfirmedEventData> {
  readonly type: 'emailChangeConfirmed';
}

interface WebsocketMakeDisputeOfferEventData {
  readonly amount: string | number;
  readonly currency: {
    readonly code: string;
    readonly sign: string;
  };
  readonly dispute_id: string;
  readonly id: number; // Project id
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName: string;
  readonly sender_role: string;
  readonly userName: string;
  // readonly time: number;
  // readonly userId: string;
}

export interface WebsocketMakeDisputeOfferEvent
  extends BaseServerNotificationsData<WebsocketMakeDisputeOfferEventData> {
  readonly type: 'makeDisputeOffer';
}

interface WebsocketProjectHireMeExpiredEventData {
  readonly id: number; // Project id
  readonly img: {
    readonly profile_logo_url: string;
  };
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName: string;
  readonly userName: string;
  readonly autoRepostProjectId?: number;
  readonly autoRepostProjectName?: string;
  readonly jobsURL: string;
  readonly seoUrl: string;
  readonly userId: number;
}

export interface WebsocketProjectHireMeExpiredEvent
  extends BaseServerNotificationsData<WebsocketProjectHireMeExpiredEventData> {
  readonly type: 'projectHireMeExpired';
}

interface WebsocketProjectInviteToBidData {
  readonly projectId: number;
  readonly freelancerId: number;
}

export interface WebsocketProjectInviteToBid
  extends BaseServerNotificationsData<WebsocketProjectInviteToBidData> {
  readonly type: 'projectInviteToBid';
}

export interface WebsocketEmailVerifiedEvent
  extends BaseServerNotificationsData<EmptyObject> {
  readonly type: 'emailVerified';
}

interface WebsocketAcceptDisputeOfferEventData {
  readonly dispute_id: string;
  readonly id: number;
  readonly linkUrl: string;
  readonly name: string;
  readonly publicName: string;
  readonly userId: string;
  readonly userName: string;
}

export interface WebsocketAcceptDisputeOfferEvent
  extends BaseServerNotificationsData<WebsocketAcceptDisputeOfferEventData> {
  readonly type: 'acceptDisputeOffer';
}

interface WebsocketEscalateDisputeEventData {
  // readonly currencycode: string;
  // readonly currencysign: string;
  readonly dispute_id: string;
  // readonly id?: number; // Not in ES schema but sent from event_notify
  readonly imgUrl: string;
  readonly linkUrl: string;
  readonly name: string; // project name
  readonly publicName: string;
  readonly time: number;
  readonly userName: string;
  readonly hasPaidArbitration?: boolean;
}

export interface WebsocketEscalateDisputeEvent
  extends BaseServerNotificationsData<WebsocketEscalateDisputeEventData> {
  readonly type: 'escalateDispute';
}

interface WebsocketProjectEmailVerificationRequiredEventData {
  readonly userId: number;
  readonly projectId?: number;
}

export interface WebsocketProjectEmailVerificationRequiredEvent
  extends BaseServerNotificationsData<WebsocketProjectEmailVerificationRequiredEventData> {
  readonly type: 'projectEmailVerificationRequiredEvent';
}

interface WebsocketWinningDesignReadyForReviewEventData {
  readonly autoReleasePrizeTime: number;
  readonly contest_name: string;
  readonly contest_url: string;
  readonly entry_id: string;
  readonly imgUrl: string;
  readonly winner_name: string;
  readonly winner_profile_url: string;
  readonly winner_publicName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly http_pic_url_head: string;
  // readonly https_pic_url_head: string;
  // readonly id: string; // Entry id
  // readonly pic_url_tail: string;
  // readonly time: number;
  // readonly userId: string;
  // readonly userName: string;
}

export interface WebsocketWinningDesignReadyForReviewEvent
  extends BaseServerNotificationsData<WebsocketWinningDesignReadyForReviewEventData> {
  readonly type: 'winningDesignReadyForReview';
}

interface WebsocketEditAwardedBidAcceptedEventData {
  // TODO: T37457 There's more in event_notify.php
  readonly buyerName: string;
  readonly buyerPublicName: string;
  readonly currencyCode: string;
  readonly currencySign: string;
  readonly projName: string;
  readonly editBidDetails: {
    readonly newAmount: number;
    readonly newPeriod: number;
    readonly oldAmount: number;
    readonly oldPeriod: number;
    readonly bidID: number;
    readonly comment: string;
    // readonly formattedRequestTime: string;
    // readonly formattedResponseTime: boolean;
    readonly id: number;
    readonly requestTime: number;
    readonly responseTime?: number | false;
    readonly status: BidEditRequestStatusApi;
  };
  readonly id: number; // Project id
  readonly img: {
    readonly profile_logo_url: string;
  };
  readonly linkUrl: string;
  // readonly userName: string;
  // readonly bid: {
  //   readonly abtest_enable_exam_link: boolean;
  //   readonly bid_based_project_status: {
  //     readonly description: string;
  //     readonly name: string;
  //   };
  //   readonly completed: boolean;
  //   readonly descr: string;
  //   readonly descr_html: string;
  //   readonly descr_short: string;
  //   readonly descr_short_html: string;
  //   readonly expert_guarantee: {
  //     readonly amount: string;
  //     readonly bid_id: string;
  //     readonly bidder_id: string;
  //     readonly currency: {
  //       readonly code: string;
  //       readonly exchange_rate: string;
  //       readonly id: string;
  //       readonly name: string;
  //       readonly sign: string;
  //     };
  //     readonly id: string;
  //     readonly project_id: string;
  //     readonly status: string;
  //     readonly time_created: number;
  //   };
  //   readonly fix_project_hourly_rate: number;
  //   readonly highlighted: boolean;
  //   readonly id: number;
  //   readonly milestone_percentage: string;
  //   readonly no: string;
  //   readonly paid_status: boolean;
  //   readonly period: string;
  //   readonly project_id: string;
  //   readonly submitdate: string;
  //   readonly submitdate_f2: string;
  //   readonly submitdate_f: string;
  //   readonly submitdate_ts: number;
  //   readonly sum: string;
  //   readonly users_id: string;
  // };
  // readonly currencyId: string;
  // readonly descr: string;
  // readonly imgUrl: string;
  // readonly projectId: number;
  // readonly publicName: string;
  // readonly text: string;
  // readonly time: number;
  // readonly title: string;
  // readonly userId: string;
}

export interface WebsocketEditAwardedBidAcceptedEvent
  extends BaseServerNotificationsData<WebsocketEditAwardedBidAcceptedEventData> {
  readonly type: 'editAwardedBidAccepted';
}

export interface WebsocketEditAwardedBidCreatedEvent
  extends BaseServerNotificationsData<WebsocketEditAwardedBidAcceptedEventData> {
  readonly type: 'editAwardedBidRequest';
}

export interface WebsocketEditAwardedBidDeclinedEvent
  extends BaseServerNotificationsData<WebsocketEditAwardedBidAcceptedEventData> {
  readonly type: 'editAwardedBidDeclined';
}

interface WebsocketAdminForceVerifyPhoneEventData {
  readonly img: {
    readonly profile_logo_url: string;
  };
}

export interface WebsocketAdminForceVerifyPhoneEvent
  extends BaseServerNotificationsData<WebsocketAdminForceVerifyPhoneEventData> {
  readonly type: 'adminForceVarifyPhone';
}

interface WebsocketRemindUseBidsEventData {
  readonly img: {
    readonly profile_logo_url: string;
  };
  readonly username: string;
  readonly bids_remaining: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly currency_code: string;
  // readonly currency_sign: string;
  // readonly membership: string; // This is an array in event_notify
  // readonly membership_package_id: string | number;
  // readonly time: number;
}

export interface WebsocketRemindUseBidsEvent
  extends BaseServerNotificationsData<WebsocketRemindUseBidsEventData> {
  readonly type: 'remindUseBids';
}

interface WebsocketContestCreatedEventData {
  // TODO: T37457 There's more in event_notify.php
  readonly contest_name: string;
  readonly contest_url: string;
  readonly contest_id: string;
  // readonly id: string; // Contest id
}

export interface WebsocketAiFrameworkData {
  readonly userId: number;
  readonly result: readonly any[];
  readonly id: string;
  readonly task: AiTaskApi;
  readonly hasErrored: boolean;
  readonly error: string | null;
}

export interface WebsocketAiFrameworkEvent
  extends BaseServerNotificationsData<WebsocketAiFrameworkData> {
  readonly type: 'aiFramework';
}

export interface WebsocketContestCreatedEvent
  extends BaseServerNotificationsData<WebsocketContestCreatedEventData> {
  readonly type: 'contestCreated';
}

interface WebsocketProjectSharedWithYouEventData {
  readonly actingInviterPublicName?: string | null;
  readonly actingInviterUsername?: string | null;
  readonly employerPublicName: string;
  readonly employerUsername: string;
  readonly projectId: number;
  readonly projectName: string;
}

export interface WebsocketProjectSharedWithYouEvent
  extends BaseServerNotificationsData<WebsocketProjectSharedWithYouEventData> {
  readonly type: 'projectSharedWithYou';
}

interface WebsocketPostDraftProjectEventData {
  readonly projectId: number;
  readonly projectName: string;
}

export interface WebsocketPostDraftProjectEvent
  extends BaseServerNotificationsData<WebsocketPostDraftProjectEventData> {
  readonly type: 'postDraftProject';
}

interface WebsocketPrizeDispersedEmployerEventData {
  readonly contest_name: string;
  readonly contest_url: string;
  readonly freelancer_publicname?: string;
  readonly freelancer_username?: string;
  readonly hasWinner: boolean;
  // TODO: T37457 There's more in event_notify.php
  // readonly freelancer_url?: string;
}

export interface WebsocketPrizeDispersedEmployerEvent
  extends BaseServerNotificationsData<WebsocketPrizeDispersedEmployerEventData> {
  readonly type: 'prizeDispersedEmployer';
}

interface WebsocketDirectTransferDoneEventData {
  // TODO: T37457 There's more in event_notify.php
  readonly amount: string;
  readonly currencycode: string;
  // readonly currencysign: string;
  readonly id: number; // Project id
  readonly img: { readonly profile_logo_url: string };
  readonly name: string;
  readonly otherReason: string;
  readonly publicName: string;
  readonly seoUrl: string | number | null;
  readonly userName: string;
  readonly userId: string;
}

export interface WebsocketDirectTransferDoneEvent
  extends BaseServerNotificationsData<WebsocketDirectTransferDoneEventData> {
  readonly type: 'directTransferDone';
}

interface WebsocketInvoiceRequestedChangeEventData {
  readonly amount: string | number;
  readonly bidId: string | number;
  readonly currencyCode: string;
  readonly img: { readonly profile_logo_url: string };
  readonly name: string;
  readonly publicName?: string;
  readonly seoUrl: string;
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly accountId: string | number;
  // readonly currencySign: string;
  // readonly dueDate: string;
  // readonly id: string | number;
  // readonly imgUrl: string;
  // readonly invoiceId: string | number;
  // readonly linkUrl: string;
  // readonly sellerId: string | number;
  // readonly state?: string;
  // readonly userId: string | number;
}

export interface WebsocketInvoiceRequestedChangeEvent
  extends BaseServerNotificationsData<WebsocketInvoiceRequestedChangeEventData> {
  readonly type: 'invoiceRequestChange';
}

interface WebsocketInvoiceWithdrawnEventData {
  readonly amount: string | number;
  readonly currencyCode: string;
  readonly img: { readonly profile_logo_url: string };
  readonly name: string;
  readonly publicName?: string;
  readonly seoUrl: string;
  readonly userName: string;
  // TODO: T37457 There's more in event_notify.php
  // readonly accountId: string | number;
  // readonly bidId: string | number;
  // readonly dueDate: string;
  // readonly id: string | number;
  // readonly imgUrl: string;
  // readonly invoiceId: string | number;
  // readonly linkUrl: string;
  // readonly sellerId: string | number;
  // readonly state?: string;
  // readonly userId: string | number;
  // readonly currencySign: string;
}

export interface WebsocketInvoiceWithdrawnEvent
  extends BaseServerNotificationsData<WebsocketInvoiceWithdrawnEventData> {
  readonly type: 'invoiceWithdrawn';
}

interface WebsocketGroupChatEventData {
  readonly action: 'add' | 'remove';
  readonly payload: {
    readonly members: readonly number[];
    readonly thread: number;
  };
}

export interface WebsocketGroupChatEvent
  // This "should" be messages but it is send from GAF, via event_notify.php,
  // and not from the messages service.
  extends BaseServerNotificationsData<WebsocketGroupChatEventData> {
  readonly type: 'groupchat';
}

interface WebsocketShowcaseClientNotificationEventData {
  readonly linkUrl: string;
  readonly userName: string;
}

export interface WebsocketShowcaseClientNotificationEvent
  extends BaseServerNotificationsData<WebsocketShowcaseClientNotificationEventData> {
  readonly type: 'showcaseClientNotification';
}

interface WebsocketShowcaseSourceApprovalEventData {
  readonly linkUrl: string;
  readonly projectId: number;
  readonly projectTitle: string;
  readonly projectType: ShowcaseSourceTypeApi;
  readonly isTest: boolean;
}

export interface WebsocketShowcaseSourceApprovalEvent
  extends BaseServerNotificationsData<WebsocketShowcaseSourceApprovalEventData> {
  readonly type: 'showcaseSourceApproval';
}

interface WebsocketMilestoneStatusUpdateEventData {
  readonly sellerId: number;
  readonly buyerId: number;
  readonly projectId: number;
  readonly bidId: number | null;
  readonly milestoneId: number;
  readonly milestoneStatus: MilestoneStatusApi;
}

export interface WebsocketMilestoneStatusUpdateEvent
  extends BaseServerNotificationsData<WebsocketMilestoneStatusUpdateEventData> {
  readonly type: 'milestoneStatusUpdate';
}

interface WebsocketRequestCancelMilestoneEventData {
  readonly milestoneId: number;
  readonly actionReason: MilestoneCancelRequestReasonApi;
  readonly actionReasonText: string;
}

export interface WebsocketRequestCancelMilestoneEvent
  extends BaseServerNotificationsData<WebsocketRequestCancelMilestoneEventData> {
  readonly type: 'requestCancelMilestone';
}

interface WebsocketInvoiceSummaryUpdateEventData {
  readonly invoiceId: number;
  readonly totalHours: number;
  readonly hoursAmount: number;
  readonly resultAmount: number;
  readonly otherAmount: number;
}

export interface WebsocketInvoiceSummaryUpdateEvent
  extends BaseServerNotificationsData<WebsocketInvoiceSummaryUpdateEventData> {
  readonly type: 'invoiceSummaryUpdate';
}

interface WebsocketBidHourlyCurrentCycleSummaryUpdateEventData {
  readonly bidId: number;
  readonly totalTrackedTime: number;
  readonly totalUninvoicedTime: number;
}

export interface WebsocketBidHourlyCurrentCycleSummaryUpdateEvent
  extends BaseServerNotificationsData<WebsocketBidHourlyCurrentCycleSummaryUpdateEventData> {
  readonly type: 'bidHourlyCurrentCycleSummaryUpdate';
}

interface WebsocketQuotationItemsCurrencyUpdateEventData {
  readonly quotation_item_currency_list: readonly {
    readonly item_version_id: number;
    readonly currency_id: number;
  }[];
}

export interface WebsocketQuotationItemsCurrencyUpdateEvent
  extends BaseServerNotificationsData<WebsocketQuotationItemsCurrencyUpdateEventData> {
  readonly type: 'quotationItemsCurrencyUpdate';
}

interface WebsocketIpContractUpdateEventData {
  readonly ipContract: IpContractApi;
}

export interface WebsocketIpContractUpdateEvent
  extends BaseServerNotificationsData<WebsocketIpContractUpdateEventData> {
  readonly type: 'ipContractUpdate';
}

interface WebsocketIPAgreementUpgradeEventData {
  readonly projectId: number;
}

export interface WebsocketIPAgreementUpgradeEvent
  extends BaseServerNotificationsData<WebsocketIPAgreementUpgradeEventData> {
  readonly type: 'ipAgreementUpgrade';
}

interface WebsocketNDAUpgradeEventData {
  readonly projectId: number;
}

export interface WebsocketNDAUpgradeEvent
  extends BaseServerNotificationsData<WebsocketNDAUpgradeEventData> {
  readonly type: 'ndaUpgrade';
}

interface WebsocketNDASignedEventData {
  readonly projectId: number;
  readonly time: number;
  readonly userId: number;
}

export interface WebsocketNDASignedEvent
  extends BaseServerNotificationsData<WebsocketNDASignedEventData> {
  readonly type: 'ndaSigned';
}

interface WebsocketUserReportsActionBidRestrictionEventData {
  readonly id: number;
  readonly action: string;
  readonly startDate: number;
  readonly endDate: number;
}

export interface WebsocketUserReportsActionBidRestrictionEvent
  extends BaseServerNotificationsData<WebsocketUserReportsActionBidRestrictionEventData> {
  readonly type: 'userReportsActionBidRestriction';
}

interface WebsocketHourlyContractUpdateEventData {
  readonly hourlyContract: UndefinedFieldsToNull<HourlyContractApi>;
}

export interface WebsocketHourlyContractUpdateEvent
  extends BaseServerNotificationsData<WebsocketHourlyContractUpdateEventData> {
  readonly type: 'hourlyContractUpdate';
}

interface WebsocketTimeTrackingSessionCreateEventData {
  readonly id: number;
  readonly time_started: number;
  readonly invoice_id?: number;
  readonly time_last_updated: number;
  readonly manually_tracked: boolean;
  readonly user_id: number;
  readonly project_id: number;
  readonly trackee_id?: number;
  readonly bid_id: number;
  readonly note?: string;
  readonly invoiceable_time: number;
}

export interface WebsocketTimeTrackingSessionCreateEvent
  extends BaseServerNotificationsData<WebsocketTimeTrackingSessionCreateEventData> {
  readonly type: 'timeTrackingSessionCreate';
}

interface WebsocketTimeTrackingSessionDeleteEventData {
  readonly id: number;
}

export interface WebsocketTimeTrackingSessionDeleteEvent
  extends BaseServerNotificationsData<WebsocketTimeTrackingSessionDeleteEventData> {
  readonly type: 'timeTrackingSessionDelete';
}

export interface WebsocketTimeTrackingSessionUpdateEvent
  extends BaseServerNotificationsData<SessionApi> {
  readonly type: 'timeTrackingSessionUpdate';
}

export interface WebsocketAddProjectCollaboratorEvent
  extends BaseServerNotificationsData<ProjectCollaborationApi> {
  readonly type: 'addProjectCollaborator';
  readonly data: ProjectCollaborationApi;
}

interface WebsocketEditProjectCollaboratorPermissionsEventData {
  readonly id: number;
  readonly projectId: number;
  readonly permissions: readonly ProjectCollaborationPermissionApi[];
}

export interface WebsocketEditProjectCollaboratorPermissionsEvent
  extends BaseServerNotificationsData<WebsocketEditProjectCollaboratorPermissionsEventData> {
  readonly type: 'editProjectCollaboratorPermissions';
}

interface WebsocketOpenHireMeForBiddingEventData {
  readonly projectId: number;
}

export interface WebsocketOpenHireMeForBiddingEvent
  extends BaseServerNotificationsData<WebsocketOpenHireMeForBiddingEventData> {
  readonly type: 'openHireMeForBidding';
}

interface WebsocketRevokeProjectCollaboratorPermissionsEventData {
  readonly id: number;
}

export interface WebsocketRevokeProjectCollaboratorPermissionsEvent
  extends BaseServerNotificationsData<WebsocketRevokeProjectCollaboratorPermissionsEventData> {
  readonly type: 'revokeProjectCollaborator';
}

interface WebsocketAcceptProjectCollaboratorEventData {
  readonly id: number;
  readonly userId: number;
  readonly projectId: number;
  readonly status: ProjectCollaborationStatusApi;
}

export interface WebsocketAcceptProjectCollaboratorEvent
  extends BaseServerNotificationsData<WebsocketAcceptProjectCollaboratorEventData> {
  readonly type: 'acceptProjectCollaborator';
}

interface WebsocketRejectMilestoneCancellationEventData {
  readonly id: number;
}

export interface WebsocketRejectMilestoneCancellationEvent
  extends BaseServerNotificationsData<WebsocketRejectMilestoneCancellationEventData> {
  readonly type: 'rejectMilestoneCancellation';
}

interface WebsocketProjectStatusChangeEventData {
  readonly id: number;
  readonly newStatus: {
    readonly deleted?: boolean;
    readonly formattedState: {
      readonly desc: string;
      readonly name: string;
    };
    readonly info: {
      readonly opened: boolean;
      readonly closed: boolean;
      readonly closed_cancelled?: boolean;
      readonly closed_expired?: boolean;
      readonly closed_accepted?: boolean;
      readonly frozen: boolean;
      readonly frozen_bidtimeout?: boolean;
      readonly frozen_waiting?: boolean;
      readonly pending: boolean;
      readonly rejected: boolean;
    };
    // project state from db table
    readonly state: 'A' | 'C' | 'F' | 'D' | 'P' | 'R';
    readonly status: ProjectStatusApi;
    readonly subStatus:
      | ProjectSubStatusApi
      | false
      | null
      | 'requires_correction';
  };
}

export interface WebsocketProjectStatusChangeEvent
  extends BaseServerNotificationsData<WebsocketProjectStatusChangeEventData> {
  readonly type: 'projectStatusChange';
}

export interface WebsocketTrackPoint {
  readonly id: number;
  readonly trackId: number;
  readonly latitude: number;
  readonly longitude: number;
  readonly timeCreated: number;
}

export interface WebsocketTrackUpdateEvent
  extends BaseServerNotificationsData<WebsocketTrackPoint> {
  readonly type: 'trackUpdate';
}

interface WebsocketTrackCreateEventData {
  readonly id: number;
  readonly userId: number;
  readonly projectId: number;
  readonly timeStarted: number;
  readonly timeFinished?: number;
  readonly duration: number;
  readonly invoiceId: number | null;
  readonly trackPoints: readonly WebsocketTrackPoint[];
}

export interface WebsocketTrackCreateEvent
  extends BaseServerNotificationsData<WebsocketTrackCreateEventData> {
  readonly type: 'trackCreate';
}

interface WebsocketProfileNameUpdateEventData {
  readonly firstName: string;
  readonly lastName: string;
}

export interface WebsocketProfileNameUpdateEvent
  extends BaseServerNotificationsData<WebsocketProfileNameUpdateEventData> {
  readonly type: 'profileNameUpdate';
}

interface WebsocketProfilePictureUpdateEventData {
  readonly avatar: string;
  readonly avatarLarge: string;
  readonly avatarXLarge?: string;
}

export interface WebsocketProfilePictureUpdateEvent
  extends BaseServerNotificationsData<WebsocketProfilePictureUpdateEventData> {
  readonly type: 'profilePictureUpdate';
}

export interface GroupsCommentUpdateEvent {
  readonly updateType: 'comment.update';
  readonly payload: {
    readonly commentFeed: ThreadApi;
  };
}

export interface GroupsCommentCreateEvent {
  readonly updateType: 'comment.create';
  readonly payload: {
    readonly commentFeed: ThreadApi;
  };
}

export interface GroupsPostCreateEvent {
  readonly updateType: 'post.create';
  readonly payload: {
    readonly feedItem: FeedItemApi;
    readonly post: PostApi;
    readonly postOwner?: GroupMemberApi;
    readonly ownerUserId: number | undefined;
  };
}

export interface GroupsUpdateEvent {
  readonly updateType: 'group.update';
  readonly payload: {
    readonly group: GroupApi;
  };
}

export interface GroupMemberRemoveEvent {
  readonly updateType: 'groupMember.remove';
  readonly payload: {
    readonly groupMember: GroupMemberApi;
  };
}

export interface GroupMemberAddedEvent {
  readonly updateType: 'groupMember.added';
  readonly payload: {
    readonly groupMember: GroupMemberApi;
  };
}

export interface GroupsCommentReactEvent {
  readonly updateType: 'comment.react';
  readonly payload: {
    readonly reaction: ReactionApi;
  };
}

export interface GroupsCommentUnreactEvent {
  readonly updateType: 'comment.unreact';
  readonly payload: {
    readonly reaction: ReactionApi;
  };
}

export interface GroupsFeedPostReactEvent {
  readonly updateType: 'post.react';
  readonly payload: {
    readonly reaction: ReactionApi;
  };
}

export interface GroupsFeedPostUnreactEvent {
  readonly updateType: 'post.unreact';
  readonly payload: {
    readonly reaction: ReactionApi;
  };
}

interface WebsocketGroupsPermissionUpdateEventData {
  readonly groupPermissions: GroupRolePermissionSelfGetResultApi;
}

export interface WebsocketGroupsPermissionUpdateEvent
  extends BaseServerNotificationsData<WebsocketGroupsPermissionUpdateEventData> {
  readonly type: 'groupPermissions';
}

interface WebsocketGroupMemberAddEventData {
  readonly groupMember: GroupMemberApi;
  readonly group: GroupApi;
}

export interface WebsocketGroupMemberAddEvent
  extends BaseServerNotificationsData<WebsocketGroupMemberAddEventData> {
  readonly type: 'groupMemberAdd';
}

export interface ResourceEventPayload {
  readonly payload:
    | GroupMemberRemoveEvent
    | GroupMemberAddedEvent
    | GroupsCommentCreateEvent
    | GroupsCommentReactEvent
    | GroupsCommentUnreactEvent
    | GroupsCommentUpdateEvent
    | GroupsFeedPostReactEvent
    | GroupsFeedPostUnreactEvent
    | GroupsPostCreateEvent
    | GroupsUpdateEvent;
}

export interface WebsocketGroupsResourceEvent
  extends BaseServerNotificationsData<ResourceEventPayload> {
  readonly type: 'resource';
}

interface WebsocketSubmitOnBehalfProjectEventData {
  readonly onBehalfProjectId: number;
  readonly projectTitle: string;
  readonly posterId: number;
  readonly posterUsername: string;
  readonly posterDisplayName: string;
  readonly nominatedEmail: string;
  readonly nominatedUserDisplayName?: string;
  readonly nominatedUserUsername?: string;
  readonly imgUrl?: string;
}

export interface WebsocketSubmitOnBehalfProjectEvent
  extends BaseServerNotificationsData<WebsocketSubmitOnBehalfProjectEventData> {
  readonly type: 'submitOnBehalfProject';
}

interface WebsocketPostOnBehalfProjectEventData {
  readonly projectTitle: string;
  readonly projectId: number;
  readonly projectOwnerDisplayName: string;
  readonly projectOwnerUsername: string;
  readonly imgUrl?: string;
}

export interface WebsocketPostOnBehalfProjectEvent
  extends BaseServerNotificationsData<WebsocketPostOnBehalfProjectEventData> {
  readonly type: 'postOnBehalfProject';
}

interface WebsocketRejectOnBehalfProjectEventData {
  readonly onBehalfProjectId: number;
  readonly projectTitle: string;
  readonly nominatedUserDisplayName: string;
  readonly nominatedUserUsername: string;
  readonly nominatedUserId: number;
  readonly imgUrl?: string;
}

export interface WebsocketRejectOnBehalfProjectEvent
  extends BaseServerNotificationsData<WebsocketRejectOnBehalfProjectEventData> {
  readonly type: 'rejectOnBehalfProject';
}

interface WebsocketCartItemsUpdateEventData {
  readonly cartItems: readonly CartItemApi[];
}

export interface WebsocketCartItemsUpdateEvent
  extends BaseServerNotificationsData<WebsocketCartItemsUpdateEventData> {
  readonly type: 'cartItemsUpdate';
}

interface WebsocketUpdateBillingAgreementEventData {
  readonly userId: number;
  readonly token: string;
  readonly currencyId: number;
  readonly depositMethod: string;
  readonly lastSuccessTimestamp: number;
  readonly creditCard?: CreditCardApi;
  readonly paypal?: PaypalApi;
  readonly paytm?: PaytmReferenceApi;
  readonly virtualPaymentMethod?: VirtualPaymentMethodReferenceApi;
  readonly isPreferred: boolean;
  readonly isVerified: boolean;
}

export interface WebsocketUpdateBillingAgreementEvent
  extends BaseServerNotificationsData<WebsocketUpdateBillingAgreementEventData> {
  readonly type: 'updateBillingAgreement';
}

interface WebsocketUserStatusUpdateEventData {
  readonly status: UserStatusApi;
}

export interface WebsocketUserStatusUpdateEvent
  extends BaseServerNotificationsData<WebsocketUserStatusUpdateEventData> {
  readonly type: 'userStatusUpdate';
}

interface WebsocketProjectTitleEditRequestEventData {
  readonly id: number;
  readonly project_id: number;
  readonly new_title: string;
  readonly requested_by: number;
  readonly status: ProjectTitleEditRequestStatusApi;
  readonly old_title: string;
}

export interface WebsocketProjectTitleEditRequestEvent
  extends BaseServerNotificationsData<WebsocketProjectTitleEditRequestEventData> {
  readonly type: 'projectTitleEditRequest';
}

interface WebsocketAcceptedProjectTitleEditRequestEventData {
  readonly id: number;
  readonly project_id: number;
  readonly new_title: string;
  readonly requested_by: number;
  readonly status: ProjectTitleEditRequestStatusApi;
  readonly old_title: string;
}

export interface WebsocketAcceptedProjectTitleEditRequestEvent
  extends BaseServerNotificationsData<WebsocketAcceptedProjectTitleEditRequestEventData> {
  readonly type: 'acceptedProjectTitleEditRequest';
}

interface WebsocketRejectedProjectTitleEditRequestEventData {
  readonly id: number;
  readonly project_id: number;
  readonly new_title: string;
  readonly old_title: string;
}

export interface WebsocketRejectedProjectTitleEditRequestEvent
  extends BaseServerNotificationsData<WebsocketRejectedProjectTitleEditRequestEventData> {
  readonly type: 'rejectedProjectTitleEditRequest';
}

interface WebsocketRenamedProjectTitleEventData {
  readonly projectId: number;
  readonly newTitle: string;
  readonly oldTitle: string;
  readonly projectOwnerId: number;
}

export interface WebsocketRenamedProjectTitleEvent
  extends BaseServerNotificationsData<WebsocketRenamedProjectTitleEventData> {
  readonly type: 'renamedProjectTitle';
}

interface WebsocketProjectTipSentEventData {
  readonly projectId: number;
  readonly employerId: number;
  readonly amount: number;
}

export interface WebsocketProjectTipSentEvent
  extends BaseServerNotificationsData<WebsocketProjectTipSentEventData> {
  readonly type: 'projectTipSent';
}

interface WebsocketProjectBiddingClosingSoonEventData {
  readonly projectId: number;
  readonly projectSeoUrl: string;
  readonly projectTitle: string;
}

export interface WebsocketProjectBiddingClosingSoonEvent
  extends BaseServerNotificationsData<WebsocketProjectBiddingClosingSoonEventData> {
  readonly type: 'projectBiddingClosingSoon';
}

interface WebsocketIndianMandateMembershipRecurringReminderEventData {
  readonly historyLogId: number;
}

export interface WebsocketIndianMandateMembershipRecurringReminderEvent
  extends BaseServerNotificationsData<WebsocketIndianMandateMembershipRecurringReminderEventData> {
  readonly type: 'indianMandateMembershipRecurringReminder';
}

interface WebsocketIndianMandateMembershipVerificationReminderEventData {
  readonly historyLogId: number;
}

export interface WebsocketIndianMandateMembershipVerificationReminderEvent
  extends BaseServerNotificationsData<WebsocketIndianMandateMembershipVerificationReminderEventData> {
  readonly type: 'indianMandateMembershipVerificationReminder';
}

interface WebsocketProjectQuestionEventData {
  readonly projectQuestions: readonly ProjectQuestionApi[];
}

export interface WebsocketProjectQuestionEvent
  extends BaseServerNotificationsData<WebsocketProjectQuestionEventData> {
  readonly type: 'projectQuestion';
}

interface WebsocketAwardProjectCorporateTeamEventData {
  readonly bidAmount: number;
  readonly bidderId: number;
  readonly bidderName: string;
  readonly bidderUsername: string;
  readonly bidPeriod: number;
  readonly currencyCode: string;
  readonly projectId: number;
  readonly projectIsHourly: boolean;
  readonly projectSeoUrl: string;
  readonly projectTitle: string;
}

export interface WebsocketAwardProjectCorporateTeamEvent
  extends BaseServerNotificationsData<WebsocketAwardProjectCorporateTeamEventData> {
  readonly type: 'awardProjectCorporateTeam';
}

export interface WebsocketProjectSavedSearchMatchEventData {
  readonly project_id: number;
  readonly project_seo_url: string;
  readonly project_submit_time: number;
  readonly project_title: string;
  readonly saved_search_id: number;
  readonly saved_search_name: string;
}

export interface WebsocketSavedSearchEvent
  extends BaseServerNotificationsData<WebsocketProjectSavedSearchMatchEventData> {
  readonly type: 'projectSavedSearchMatch';
}

export interface WebsocketCustomFieldAddEventData {
  readonly resourceType: string;
  readonly resourceId: number;
  readonly enterpriseMetadataValues: readonly (EnterpriseMetadataValueApi & {
    readonly name: string;
  })[];
}

export interface WebsocketCustomFieldAddEvent
  extends BaseServerNotificationsData<WebsocketCustomFieldAddEventData> {
  readonly type: 'customFieldAdd';
}

interface WebsocketSendQuoteEventData extends WebsocketProjectData {
  readonly currencyId: number;
  readonly employerPublicName: string;
  readonly transId?: number;
}

export interface WebsocketSendQuoteEvent
  extends BaseServerNotificationsData<WebsocketSendQuoteEventData> {
  readonly type: 'sendQuote';
}

interface WebsocketUserRequiresPhoneVerificationEventData {
  readonly userRequiresPhoneVerification: boolean;
}

export interface WebsocketUserRequiresPhoneVerificationEvent
  extends BaseServerNotificationsData<WebsocketUserRequiresPhoneVerificationEventData> {
  readonly type: 'userRequiresPhoneVerification';
}

interface WebsocketGroupNotificationEventData {
  readonly content?: string | null;
  readonly contentType: string;
  readonly contentId: number;
  readonly creatorUserId?: number;
  readonly creatorUsername?: string;
  readonly groupId: number;
  readonly groupName: string;
  readonly imgUrl?: string;
  readonly isMentionedUser: boolean;
  readonly linkUrl: string;
  readonly projectTitle?: string;
}

export interface WebsocketGroupNotificationEvent
  extends BaseServerNotificationsData<WebsocketGroupNotificationEventData> {
  readonly type: 'groupNotification';
}

interface WebsocketGroupReactNotificationEventData {
  readonly contextType: GroupNotificationContextType;
  readonly contextId: number;
  readonly contextContent: string;
  readonly resourceId: string;
  readonly groupId: number;
  readonly groupName: string;
  readonly linkUrl: string;
  readonly commenters: readonly {
    readonly userId: number;
    readonly username: string;
    readonly imgUrl?: string;
  }[];
  readonly reactionsCount: number;
}

export interface WebsocketGroupReactNotificationEvent
  extends BaseServerNotificationsData<WebsocketGroupReactNotificationEventData> {
  readonly type: 'groupReactNotification';
}

interface WebsocketGroupReplyNotificationEventData {
  readonly parentType: GroupNotificationContextType;
  readonly parentContent: string;
  readonly parentId: string;
  readonly groupId: number;
  readonly groupName: string;
  readonly linkUrl: string;
  readonly commenters: readonly {
    readonly userId: number;
    readonly username: string;
    readonly imgUrl?: string;
  }[];
}

export interface WebsocketGroupReplyNotificationEvent
  extends BaseServerNotificationsData<WebsocketGroupReplyNotificationEventData> {
  readonly type: 'groupReplyNotification';
}

interface WebsocketCheckInReminderEventData {
  readonly date: number;
  readonly counts: {
    readonly [key: string]: number;
  };
}

export interface WebsocketCheckInReminderEvent
  extends BaseServerNotificationsData<WebsocketCheckInReminderEventData> {
  readonly type: 'checkInReminder';
}

interface WebsocketEarningTaxCollectedEventData {
  readonly imgUrl?: string;
  readonly linkUrl: string;
  readonly taxType: string;
  readonly transactionId: number;
  readonly taxAmount: number;
  readonly currency: string;
}

export interface WebsocketEarningTaxCollectedEvent
  extends BaseServerNotificationsData<WebsocketEarningTaxCollectedEventData> {
  readonly type: 'earningTaxCollected';
}

interface WebsocketAddProjectNoteEventData {
  readonly id: number;
  readonly project_id: number;
  readonly description: string;
  readonly username: string;
}

export interface WebsocketAddProjectNoteEvent
  extends BaseServerNotificationsData<WebsocketAddProjectNoteEventData> {
  readonly type: 'addProjectNote';
}

export interface WebsocketThreeDSecureCompletedEventData {
  readonly invoiceId: number;
  readonly threeDSState: string;
}

export interface WebsocketThreeDSecureCompletedEvent
  extends BaseServerNotificationsData<WebsocketThreeDSecureCompletedEventData> {
  readonly type: 'threeDSecureCompleted';
}
