/* eslint-disable no-console */

let debugEnabled = false;

export function debugConsoleDebug(
  message?: unknown,
  ...optionalParams: unknown[]
): void {
  if (debugEnabled) {
    console.debug(message, ...optionalParams);
  }
}

export function debugConsoleDir(obj: unknown): void {
  if (debugEnabled) {
    console.dir(obj);
  }
}

export function debugConsoleError(
  message?: unknown,
  ...optionalParams: unknown[]
): void {
  if (debugEnabled) {
    console.error(message, ...optionalParams);
  }
}

export function debugConsoleGroup(...label: unknown[]): void {
  if (debugEnabled) {
    console.group(...label);
  }
}

export function debugConsoleGroupCollapsed(...label: unknown[]): void {
  if (debugEnabled) {
    console.groupCollapsed(...label);
  }
}

export function debugConsoleGroupEnd(): void {
  if (debugEnabled) {
    console.groupEnd();
  }
}

export function debugConsoleInfo(
  message?: unknown,
  ...optionalParams: unknown[]
): void {
  if (debugEnabled) {
    console.info(message, ...optionalParams);
  }
}

export function debugConsoleLog(
  message?: unknown,
  ...optionalParams: unknown[]
): void {
  if (debugEnabled) {
    console.log(message, ...optionalParams);
  }
}

export function debugConsoleTable(
  tabularData: unknown,
  properties?: readonly string[],
): void {
  if (debugEnabled) {
    console.table(tabularData, properties);
  }
}

export function debugConsoleWarn(
  message?: unknown,
  ...optionalParams: unknown[]
): void {
  if (debugEnabled) {
    console.warn(message, ...optionalParams);
  }
}

export function isDebugMode(): boolean {
  return debugEnabled;
}

/** For test development only */
export function enableDebugMode(): void {
  debugEnabled = true;
}

/** For test development only */
export function disableDebugMode(): void {
  debugEnabled = false;
}
