import { InjectionToken } from '@angular/core';
import type { AuthServiceInterface } from '@freelancer/auth/interface';
import type {
  FreelancerHttpABTestOverridesInterface,
  FreelancerHttpConfig,
  FreelancerHttpTrackingInterface,
} from './freelancer-http.interface';

export const FREELANCER_HTTP_CONFIG = new InjectionToken<FreelancerHttpConfig>(
  'FreelancerHttpConfig',
);

export const FREELANCER_HTTP_AUTH_PROVIDERS = new InjectionToken<
  readonly AuthServiceInterface[]
>('FreelancerHttpAuthProviders');

export const FREELANCER_HTTP_ABTEST_OVERRIDES_PROVIDER = new InjectionToken<
  readonly FreelancerHttpABTestOverridesInterface[]
>('FreelancerHttpABTestOverridesProvider');

export const FREELANCER_HTTP_TRACKING_PROVIDER = new InjectionToken<
  readonly FreelancerHttpTrackingInterface[]
>('FreelancerHttpTrackingProvider');
