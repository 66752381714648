import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { TrackingConsentStatus } from './interface';
import { TrackingConsent } from './tracking-consent.service';
import { TRACKING_CONFIG } from './tracking.config';
import { TrackingConfig } from './tracking.interface';
import type { CustomTrackingEvent, TrackingEventData } from './tracking.model';

// These are predefined standard events that tiktok supports
// https://ads.tiktok.com/help/article/supported-standard-events
// Check the list for recommended parameters if needed.
export enum TiktokStandardEvents {
  ADD_PAYMENT_INFO = 'AddPaymentInfo',
  ADD_TO_CART = 'AddToCart',
  ADD_TO_WISHLIST = 'AddToWishlist',
  CLICK_BUTTON = 'ClickButton',
  COMPLETE_PAYMENT = 'CompletePayment',
  COMPLETE_REGISTRATION = 'CompleteRegistration',
  CONTACT = 'Contact',
  DOWNLOAD = 'Download',
  INITIATE_CHECKOUT = 'InitiateCheckout',
  PLACE_AN_ORDER = 'PlaceAnOrder',
  SEARCH = 'Search',
  SUBMIT_FORM = 'SubmitForm',
  SUBSCRIBE = 'Subscribe',
  VIEW_CONTENT = 'ViewContent',
}

// Tiktok pixel doesn't allow sending custom parameters,
// and we need these events fired in tracking. The value of the enum
// is the name of the custom event that will show up in tiktok ads manager.
export enum TiktokCustomEvents {
  EMPLOYER_SIGN_UP = 'Employer Sign Up',
}

@Injectable({
  providedIn: 'root',
})
export class TiktokPixelTracking {
  private isEnabled = false;
  private isInitialized = false;

  private methods = [
    'page',
    'track',
    'identify',
    'instances',
    'debug',
    'on',
    'off',
    'once',
    'ready',
    'alias',
    'group',
    'enableCookie',
    'disableCookie',
    'holdConsent',
    'revokeConsent',
    'grantConsent',
  ];

  constructor(
    private ngZone: NgZone,
    private trackingConsent: TrackingConsent,
    @Inject(TRACKING_CONFIG) private config: TrackingConfig,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  async init(): Promise<void> {
    const trackingConsentStatus =
      await this.trackingConsent.getThirdPartyStatus();
    if (
      this.config.tiktokPixelId &&
      isPlatformBrowser(this.platformId) &&
      trackingConsentStatus === TrackingConsentStatus.AUTHORIZED
    ) {
      this.isEnabled = true;
    }
    this.loadTrackingSnippet();
    this.isInitialized = true;
  }

  async trackPageView(): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }

    this.ngZone.runOutsideAngular(() => {
      window.ttq.page();
    });
  }

  async trackCustomEvent(
    payload: CustomTrackingEvent & TrackingEventData,
  ): Promise<void> {
    if (!this.isInitialized) {
      await this.init();
    }

    if (!this.isEnabled) {
      return;
    }

    if (
      payload.tiktokEventName &&
      payload.tiktokEventName === TiktokCustomEvents.EMPLOYER_SIGN_UP
    ) {
      payload.name = TiktokCustomEvents.EMPLOYER_SIGN_UP;
    }

    this.ngZone.runOutsideAngular(() => {
      window.ttq.track(
        this.formatCustomEventName(payload.name),
        payload.extra_params,
      );
    });
  }

  // Tiktok custom events cannot exceed 50 chars, and can only take
  // Alphabetic letters, arabic numerals, underscores, and dashes.
  // It should also only start with alphabetic character and not end in space.
  private formatCustomEventName(name: string): string {
    // Throw an error if the name doesn't start with an alphabetic character
    if (!/^[a-zA-Z]/.test(name)) {
      console.error(
        `Tiktok custom event name: '${name}' should start with an alphabetic character.`,
      );
    }

    // Remove leading and trailing spaces
    let formattedName = name.trim();

    // Replace not allowed characters with underscores
    formattedName = formattedName.replace(/[^a-zA-Z0-9_ -]/g, '_');

    if (formattedName.length > 50) {
      console.warn(
        `Tiktok custom event name: '${name}' exceeds 50 characters and will be truncated.`,
      );

      formattedName = formattedName.slice(0, 50);
    }
    return formattedName;
  }

  private setAndDefer(ttq: any, method: any): void {
    ttq[method] = (...args: any) => {
      ttq.push([method, ...args]);
    };
  }

  private loadTrackingSnippet(): void {
    if (window.ttq || !this.config.tiktokPixelId) {
      return;
    }

    window.ttq = [];
    window.TiktokAnalyticsObject = 'ttq';

    this.methods.forEach(method => {
      this.setAndDefer(window.ttq, method);
    });

    const tiktokScriptUrl = 'https://analytics.tiktok.com/i18n/pixel/events.js';
    const tiktokPixelScript = document.createElement('script');
    tiktokPixelScript.type = 'text/javascript';
    tiktokPixelScript.async = true;
    tiktokPixelScript.src = `${tiktokScriptUrl}?sdkid=${this.config.tiktokPixelId}&lib=ttq`;

    window.ttq._i = {};
    window.ttq._i[this.config.tiktokPixelId] = [];
    window.ttq._i[this.config.tiktokPixelId]._u = tiktokScriptUrl;
    window.ttq._t = {};
    window.ttq._t[this.config.tiktokPixelId] = Date.now();
    window.ttq._o = {};
    window.ttq._o[this.config.tiktokPixelId] = {};

    const scriptTag = document.getElementsByTagName('script')[0];
    (scriptTag.parentNode as Node).insertBefore(tiktokPixelScript, scriptTag);
  }
}
