import type { OnDestroy } from '@angular/core';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Auth } from '@freelancer/auth';
import type { AuthState } from '@freelancer/auth/interface';
import type {
  ApproximateTotalCountType,
  BackendPushResponse,
  DatastoreCollectionType,
  DatastoreDeleteCollectionType,
  DatastoreFetchCollectionType,
  DatastoreInterface,
  DatastorePushCollectionType,
  DatastoreSetCollectionType,
  DatastoreUpdateCollectionType,
  DocumentOptionsObject,
  DocumentQuery,
  NullQuery,
  Path,
  PushDocumentType,
  Query,
  QueryParam,
  QueryParams,
  Reference,
  RequestDataPayload,
  RequestStatus,
  UserCollectionStateSlice,
} from '@freelancer/datastore/core';
import {
  DatastoreCollection,
  DatastoreDocument,
  LOGGED_OUT_KEY,
  arrayIsShallowEqual,
  flattenQuery,
  getEqualOrInIdsFromQuery,
  isDocumentOptionsObject,
  isIdQuery,
  isNullRef,
  isSearchQuery,
  requestStatusesEqual,
  stringifyReference,
  uniq,
} from '@freelancer/datastore/core';
import { reemitWhen, select } from '@freelancer/operators';
import {
  QueueSubject,
  isArray,
  isDefined,
  isFieldDefined,
  toObservable,
} from '@freelancer/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ErrorCodeApi } from 'api-typings/errors/errors';
import type { Observable } from 'rxjs';
import {
  NEVER,
  asapScheduler,
  combineLatest,
  firstValueFrom,
  isObservable,
  of,
} from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  map,
  share,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import type { SimulatedFetchRequestFailure } from './backend';
import { StoreBackendFake } from './backend';
import type {
  DatastoreTestingController,
  IdOrIdsOrQuery,
} from './datastore-testing-controller';
import { debugConsoleLog, debugConsoleTable } from './datastore.helpers';
import { NonObservableQuery } from './non-observable-query';
import { fetchSingleDocument, selectDocumentsForQuery } from './store-helpers';
import type {
  DeleteRequestErrorCode,
  FakeUserCollectionStateSlice,
  FetchRequestErrorCode,
  MutationPropagator,
  PushRequestErrorCode,
  PushTransformer,
  SearchTransformer,
  SetRequestErrorCode,
  UpdateRequestErrorCode,
  UpdateTransformer,
} from './store.model';

@UntilDestroy({ className: 'DatastoreFake' })
@Injectable()
export class DatastoreFake
  implements DatastoreInterface, DatastoreTestingController, OnDestroy
{
  searchTransformers: Map<string, SearchTransformer<DatastoreCollectionType>> =
    new Map();

  isRefreshTest$ = of(true);

  private readonly refetchCollectionsQueue$ = new QueueSubject<
    readonly DatastoreCollectionType['Name'][]
  >();
  private readonly refetchCollections$: Observable<
    readonly DatastoreCollectionType['Name'][]
  > = this.refetchCollectionsQueue$.asObservable().pipe(share());

  constructor(
    @Inject(PLATFORM_ID) public platformId: Object,
    private storeBackend: StoreBackendFake,
    private auth: Auth,
  ) {}

  ngOnDestroy(): void {
    /** Nothing here */
  }

  isCollectionWhitelistedForRefetch(_: string): boolean {
    return true;
  }

  /**
   * Returns a collection of objects, customised by query.
   *
   * @param collectionName Collection name
   * @param queryFn Query to refine results
   */
  collection<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    queryFn?: (q: Query<C>) => Query<C> | Observable<Query<C> | NullQuery>,
  ): DatastoreCollection<C>;
  collection<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    ids$: Observable<readonly number[]> | Observable<readonly string[]>,
  ): DatastoreCollection<C>;
  collection<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    queryFnOrIds$?:
      | ((q: Query<C>) => Query<C> | Observable<Query<C> | NullQuery>)
      | Observable<readonly number[]>
      | Observable<readonly string[]>,
  ): DatastoreCollection<C> {
    const flattenedQuery$ = flattenQuery<C>(
      isObservable(queryFnOrIds$)
        ? query => query.where('id', 'in', queryFnOrIds$)
        : queryFnOrIds$,
    );

    const refStream$: Observable<Reference<C>> = combineLatest([
      toObservable(collectionName),
      toObservable(this.auth.authState$),
      flattenedQuery$,
    ]).pipe(
      map(
        ([
          collection,
          authState,
          { limit, queryParams, searchQueryParams, order },
        ]) => ({
          path: {
            collection,
            authUid: authState ? authState.userId : LOGGED_OUT_KEY,
          },
          query: {
            limit,
            queryParams,
            searchQueryParams,
            isDocumentQuery: false,
          },
          order,
        }),
      ),
      map(ref => {
        // If the query is just filtering by ids, then move the `ids` into the
        // path and fetch all the ids
        if (isIdQuery(ref.query)) {
          const newRef: Reference<C> = {
            ...ref,
            path: { ...ref.path, ids: getEqualOrInIdsFromQuery(ref.query) },
            query: undefined, // don't merge in the new query, just use `path.ids`
          };

          return newRef;
        }

        return ref;
      }),
      tap(ref => {
        const { path } = ref;
        // Don't log null queries
        if (!isNullRef(ref)) {
          debugConsoleLog(
            `datastore.collection ${path.collection}`,
            `Query: ${stringifyReference(ref)}`,
          );
        }
      }),
    );

    const refetch$ = this.refetchTriggered(collectionName);
    // Requests to the backend, further processed in `request-data.effect.ts`
    const requestStream$: Observable<RequestDataPayload<C>> = refStream$.pipe(
      reemitWhen(refetch$),
      map(([ref]) => {
        const request: RequestDataPayload<C> = {
          type: ref.path.collection,
          ref,
          clientRequestIds: [this.generateClientRequestId()],
        };

        return request;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    /**
     * Unlike the "real" datastore this isn't filtered for `undefined`
     * so that you can use this to define the status stream.
     */
    const sourceStream$ = requestStream$.pipe(
      switchMap((request: RequestDataPayload<C>) => {
        const {
          ref,
          ref: {
            path: { collection, authUid },
            query,
          },
        } = request;

        const errorState = this.getCollectionErrorState(request.ref);
        if (errorState) {
          debugConsoleLog(
            `datastore.collection: Making request to ${collection} fail/pending`,
            stringifyReference(request.ref),
            errorState,
          );

          return NEVER;
        }

        return query && !query.queryParams && !isSearchQuery(query)
          ? of({ documentsWithMetadata: [], request }) // immediately emit on null query
          : this.storeBackend.storeState$.pipe(
              // store$ emits on every action dispatched and store state change
              select(collection, authUid),
              switchMap(storeSlice => {
                // delay the fetch if the collection is configured to do so
                const delayTime =
                  this.storeBackend.collectionsToDelay.get(
                    `${collectionName}-fetch`,
                  ) ?? 0;

                return delayTime > 0
                  ? of(storeSlice).pipe(delay(delayTime, asapScheduler))
                  : of(storeSlice);
              }),
              map(storeSlice => storeSlice as FakeUserCollectionStateSlice<C>),
              map(storeSlice =>
                selectDocumentsForQuery(
                  storeSlice,
                  ref,
                  this.storeBackend.defaultOrder(collection),
                  this.searchTransformers,
                ),
              ),
              distinctUntilChanged(arrayIsShallowEqual),
              map(documentsWithMetadata => ({
                documentsWithMetadata,
                request,
              })),
            );
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const statusStream$ = combineLatest([
      requestStream$,
      sourceStream$.pipe(startWith(undefined)),
    ]).pipe(
      map(
        ([request, source]) =>
          /* While the real datastore will emit `false` then `true`,
           * doing this immediately can break Angular's change detection,
           * so let's derive this from the request and source streams
           * and only emit once.
           */
          this.getCollectionErrorState(request.ref) ?? {
            ready: source !== undefined,
          },
      ),
      distinctUntilChanged(requestStatusesEqual),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    return new DatastoreCollection(
      { dedupeWindowTime: 0, batchWindowTime: 0, maxBufferTime: 0 },
      refStream$,
      this.storeBackend,
      statusStream$,
      sourceStream$.pipe(
        map(({ documentsWithMetadata, request }) => ({
          documentsWithMetadata: documentsWithMetadata
            // Apply the limit here not selectDocumentsForQuery so we can do approximateTotalCount
            .slice(0, request.ref.query?.limit)
            .map(rawDocument => ({
              rawDocument,
              timeFetched: 0,
              timeUpdated: 0,
            })),
          request,
          // Explicitly setting these optional timestamp because
          // it is needed when using valueChangesLessOften().
          // Otherwise, valueChangesLessOften() will not emit.
          timeFetched: Date.now(),
          timeUpdated: Date.now(),
        })),

        distinctUntilChanged(),
      ),
      /**
       * This isn't 100% correct, it will always returns a value rather than
       * `undefined` for collections that shouldn't have an `approximateTotalCount$`,
       * but the type system should prohibit you from noticing.
       *
       * It is also different from the real datastore which returns the count
       * from the DB, not the count of documents in the store. Seeding with
       * enough data should be sufficient to emulate this though.
       */
      sourceStream$.pipe(
        filter(isFieldDefined('documentsWithMetadata')),
        map(
          ({ documentsWithMetadata }) =>
            documentsWithMetadata.length as ApproximateTotalCountType<C>,
        ),
        distinctUntilChanged(),
      ),
    );
  }

  /**
   * Returns a single document from a collection specified by id.
   * If `documentId` is an observable, new objects may be fetched from the
   * network and emitted as they arrive. Note that if it emits too quickly,
   * such that the network request has not completed before the id changes,
   * that object with the previous id will not be emitted.
   *
   * @param collectionName Collection name
   * @param documentId$ If not provided, defaults to the first document of the collection.
   * Omitting this is useful for logged-out requests when document ID is not applicable
   * @param query Only required if querying by a unique secondary ID
   */
  document<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    documentId$?: string | number | Observable<string> | Observable<number>,
  ): DatastoreDocument<C>;
  document<
    C extends DatastoreCollectionType,
    OtherId extends keyof C['DocumentType'],
  >(
    collectionName: C['Name'],
    documentSecondaryId$:
      | C['DocumentType'][OtherId]
      | Observable<C['DocumentType'][OtherId]>,
    documentQueryOrOptionObject$:
      | DocumentQuery<C, OtherId>
      | Observable<DocumentQuery<C, OtherId> | undefined>
      | DocumentOptionsObject<DocumentQuery<C, OtherId>, C['ResourceGroup']>,
  ): DatastoreDocument<C>;

  document<
    C extends DatastoreCollectionType,
    OtherId extends keyof C['DocumentType'],
  >(
    collectionName: C['Name'],
    documentId$?: string | number | Observable<string> | Observable<number>,
    documentQueryOrOptionObject$?:
      | DocumentQuery<C, OtherId>
      | Observable<DocumentQuery<C, OtherId> | undefined>
      | DocumentOptionsObject<DocumentQuery<C, OtherId>, C['ResourceGroup']>,
  ): DatastoreDocument<C> {
    // Extract query$ and resourceGroup from documentQueryOrOptionObject$
    // depending on what is given.
    const { query$, resourceGroup$ } =
      documentQueryOrOptionObject$ &&
      isDocumentOptionsObject<DocumentQuery<C, OtherId>, C['ResourceGroup']>(
        documentQueryOrOptionObject$,
      )
        ? documentQueryOrOptionObject$
        : { query$: documentQueryOrOptionObject$, resourceGroup$: undefined };

    const refStream$: Observable<Reference<C>> = combineLatest([
      toObservable(collectionName),
      toObservable(this.auth.authState$),
      toObservable<number | string | undefined>(documentId$).pipe(
        distinctUntilChanged(),
      ),
      toObservable(query$).pipe(
        distinctUntilChanged(
          (a, b) =>
            !!a &&
            !!b &&
            a.caseInsensitive === b.caseInsensitive &&
            a.index === b.index,
        ),
      ),
    ]).pipe(
      map(([collection, authState, id, documentQuery]) => {
        const path = {
          collection,
          authUid: authState ? authState.userId : LOGGED_OUT_KEY,
        };

        if (!id) {
          // Treat as a document query with no params
          return {
            path,
            query: {
              isDocumentQuery: true,
              limit: 1,
              queryParams: {},
              searchQueryParams: {},
            },
          };
        }

        return documentQuery
          ? {
              path,
              query: {
                isDocumentQuery: true,
                limit: 1,
                queryParams: {
                  [documentQuery.index]: [
                    {
                      name: documentQuery.index,
                      condition: documentQuery.caseInsensitive
                        ? 'equalsIgnoreCase'
                        : '==',
                      value: id,
                    } as QueryParam<C['DocumentType'], OtherId>,
                  ],
                  // FIXME: T267853 - shouldn't need the double cast
                } as unknown as QueryParams<C['DocumentType']>,
                searchQueryParams: {},
              },
            }
          : { path: { ...path, ids: [id.toString()] } };
      }),
      tap(ref => {
        const { path } = ref;
        debugConsoleLog(
          'datastore.document',
          path.collection,
          'ids' in path ? path.ids : stringifyReference(ref),
        );
      }),
    );

    const refetch$ = this.refetchTriggered(collectionName);
    const requestStream$ = combineLatest([
      refStream$,
      toObservable(resourceGroup$),
    ]).pipe(
      reemitWhen(refetch$),
      map(([[ref, resourceGroup]]) => ({
        type: ref.path.collection,
        ref,
        clientRequestIds: [this.generateClientRequestId()],
        resourceGroup,
      })),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const sourceStream$ = requestStream$.pipe(
      switchMap(request => {
        const {
          ref,
          ref: {
            path: { collection, authUid },
          },
        } = request;

        const errorState = this.getCollectionErrorState(request.ref);
        if (errorState) {
          debugConsoleLog(
            `datastore.document: Making request to ${collection} fail/pending`,
            stringifyReference(request.ref),
            errorState,
          );

          return NEVER;
        }

        return this.storeBackend.storeState$.pipe(
          select(collection, authUid),
          switchMap(storeSlice => {
            // delay the fetch if the collection is configured to do so
            const delayTime =
              this.storeBackend.collectionsToDelay.get(
                `${collectionName}-fetch`,
              ) ?? 0;

            return delayTime > 0
              ? of(storeSlice).pipe(delay(delayTime, asapScheduler))
              : of(storeSlice);
          }),
          map(storeSlice => storeSlice as UserCollectionStateSlice<C>), // FIXME: T267853 - ?
          map(state =>
            fetchSingleDocument(
              state,
              ref,
              this.storeBackend.defaultOrder(collection),
              this.searchTransformers,
            ),
          ),
        );
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    const statusStream$ = combineLatest([
      requestStream$,
      sourceStream$.pipe(startWith(undefined)),
    ]).pipe(
      map(
        ([request, source]) =>
          /* While the real datastore will emit `false` then `true`,
           * doing this immediately can break Angular's change detection,
           * so let's derive this from the request and source streams
           * and only emit once.
           */
          this.getCollectionErrorState(request.ref) ??
          this.getCollectionErrorStateWhenEmpty(request.ref, source) ?? {
            ready: source !== undefined,
          },
      ),
      distinctUntilChanged(requestStatusesEqual),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    return new DatastoreDocument(
      refStream$,
      this.storeBackend,
      statusStream$,
      sourceStream$.pipe(filter(isDefined), distinctUntilChanged()),
    );
  }

  /**
   * Creates a single object.
   */
  createDocument<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(
    collectionName: C['Name'],
    document: PushDocumentType<C> & { readonly id?: number | string },
    extra?: { readonly [index: string]: string | number },
  ): Promise<BackendPushResponse<C>> {
    return firstValueFrom(
      combineLatest([toObservable(collectionName), this.auth.authState$]).pipe(
        map(([collection, authState]: [C['Name'], AuthState | undefined]) => {
          const path: Path<C> = {
            collection,
            authUid: authState ? authState.userId : LOGGED_OUT_KEY,
          };
          return { path };
        }),

        switchMap(ref => this.storeBackend.push<C>(ref, document, extra)),
        untilDestroyed(this),
      ),
    );
  }

  /**
   * Creates an object directly in the store.
   */
  createRawDocument<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(
    collectionName: C['Name'],
    document: C['DocumentType'],
  ): Promise<BackendPushResponse<any>> {
    return firstValueFrom(
      combineLatest([toObservable(collectionName), this.auth.authState$]).pipe(
        map(([collection, authState]: [C['Name'], AuthState | undefined]) => {
          const path: Path<C> = {
            collection,
            authUid: authState ? authState.userId : LOGGED_OUT_KEY,
          };
          return { path };
        }),

        switchMap(ref => this.storeBackend.pushRaw<C>(ref, document)),
        untilDestroyed(this),
      ),
    );
  }

  /**
   * Clears the state, push transformers and resets errors.
   */
  resetState<C extends DatastoreCollectionType>(
    collectionName?: C['Name'],
  ): Promise<void> {
    return firstValueFrom(
      this.auth.authState$.pipe(
        tap(authState => {
          if (!collectionName) {
            this.searchTransformers.clear();
          } else {
            this.searchTransformers.delete(collectionName);
          }

          this.storeBackend.reset(
            authState ? authState.userId : LOGGED_OUT_KEY,
            collectionName,
          );
        }),
        untilDestroyed(this),
      ),
    ).then(_ => undefined);
  }

  printRawState(): Promise<void> {
    return firstValueFrom(
      this.storeBackend.storeState$.pipe(untilDestroyed(this)),
    ).then(state => debugConsoleLog('Store state:', state));
  }

  /**
   * Print the datastore state in a pretty format,
   * either the whole state or just for a collection (or list of collections).
   */
  async printState(collectionName?: string): Promise<void>;
  async printState(collectionNames: readonly string[]): Promise<void>;
  async printState(
    collectionNames?: string | readonly string[],
  ): Promise<void> {
    const isLoggedIn = await firstValueFrom(
      this.auth.isLoggedIn().pipe(untilDestroyed(this)),
    );

    const authUid = isLoggedIn
      ? await firstValueFrom(this.auth.getUserId().pipe(untilDestroyed(this)))
      : LOGGED_OUT_KEY;

    const state = await firstValueFrom(
      this.storeBackend.storeState$.pipe(untilDestroyed(this)),
    );

    const userIds = uniq(
      Object.values(state).flatMap(stateSlice => Object.keys(stateSlice)),
    );

    debugConsoleLog(
      `Current User ID: ${authUid}. Datastore has data for users: [${userIds.join(
        ', ',
      )}]`,
    );

    userIds.forEach(userId => {
      const stateForUser = Object.fromEntries(
        Object.entries(state)
          .map(([collectionName, stateSlice]) => {
            const collectionStateSlice = stateSlice[userId];
            if (collectionStateSlice === undefined) {
              return undefined;
            }

            const rawDocuments = Object.values(
              collectionStateSlice.documents,
            ).map(documentWithMetadata => documentWithMetadata.rawDocument);
            const documentMap = Object.fromEntries(
              rawDocuments.map(rawDocument => [rawDocument.id, rawDocument]),
            );

            if (
              isArray(collectionNames)
                ? collectionNames.includes(collectionName)
                : collectionNames === collectionName
            ) {
              debugConsoleLog(
                `Documents for user %c"${authUid}"%c, collection %c"${collectionName}"`,
                'font-weight:bold',
                'font-weight:normal',
                'font-weight:bold',
              );
              debugConsoleTable(rawDocuments);
            }
            return [collectionName, documentMap] as const;
          })
          .filter(isDefined),
      );

      if (!collectionNames) {
        debugConsoleLog(`Store state for user ${userId}`, stateForUser);
      }
    });
  }

  /**
   * Returns if the datastore has no state for that user.
   */
  isStateEmpty(): Promise<boolean> {
    return firstValueFrom(
      combineLatest([this.auth.authState$, this.storeBackend.storeState$]).pipe(
        map(([authState, storeState]) => {
          const authUid = authState ? authState.userId : LOGGED_OUT_KEY;
          return !Object.values(storeState).some(
            stateSlice => stateSlice[authUid] !== undefined,
          );
        }),
        untilDestroyed(this),
      ),
    );
  }

  /**
   * Since the real datastore supports fields that are computed in the backend
   * the fake needs explicit functions to do this computation to be registered.
   */
  addPushTransformer<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(collectionName: C['Name'], transformer: PushTransformer<C>): void {
    this.storeBackend.pushTransformers.set(
      collectionName,
      transformer as unknown as PushTransformer<
        DatastoreCollectionType & DatastorePushCollectionType
      >,
    );
  }

  /**
   * Since the real datastore's reducers can use the backend response to merge
   * documents for updates, the fake needs functions to replicate this.
   */
  addUpdateTransformer<
    C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
  >(collectionName: C['Name'], transformer: UpdateTransformer<C>): void {
    this.storeBackend.updateTransformers.set(
      collectionName,
      transformer as unknown as UpdateTransformer<
        DatastoreCollectionType & DatastoreUpdateCollectionType
      >,
    );
  }

  addMutationPropagator<
    C1 extends DatastoreCollectionType & DatastorePushCollectionType,
    C2 extends DatastoreCollectionType & DatastorePushCollectionType,
  >(propagator: MutationPropagator<C1, C2>): void {
    if (propagator.to === propagator.from) {
      throw new Error(
        'Mutation propagators between the same collection are not allowed. Use a push/update transformer instead.',
      );
    }

    if (
      this.storeBackend.mutationPropagators.find(
        p => propagator.from === p.from && propagator.to === p.to,
      )
    ) {
      throw new Error(
        `Mutation propagator from '${propagator.from}' to '${propagator.to}' already exists, add any logic to that instead.`,
      );
    }

    this.storeBackend.mutationPropagators = [
      ...this.storeBackend.mutationPropagators,
      propagator,
    ];
  }

  addSearchTransformer<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    transformer: SearchTransformer<C>,
  ): void {
    this.searchTransformers.set(
      collectionName,
      transformer as unknown as SearchTransformer<DatastoreCollectionType>,
    );
  }

  /**
   * Make all fetch requests to a particular datastore collection fail.
   */
  makeCollectionFailFetch<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(
    collectionName: C['Name'],
    errorCode: FetchRequestErrorCode<C> = 'UNKNOWN_ERROR',
  ): void {
    return this.makeFetchCollectionError(collectionName, {
      status: 'error',
      errorCode,
    });
  }

  /**
   * Clear fetch request fails set by makeCollectionFailFetch.
   */
  clearCollectionFailFetch(): void {
    this.storeBackend.fetchCollectionsToFail.clear();
  }

  /**
   * Make all push requests to a particular datastore collection fail.
   */
  makeCollectionFailPush<
    C extends DatastoreCollectionType & DatastorePushCollectionType,
  >(
    collectionName: C['Name'],
    errorCode: PushRequestErrorCode<C> = 'UNKNOWN_ERROR',
  ): void {
    this.storeBackend.pushCollectionsToFail.set(collectionName, {
      status: 'error',
      errorCode,
    });
  }

  /**
   * Clear push request fails set by makeCollectionFailPush.
   */
  clearCollectionFailPush(): void {
    this.storeBackend.pushCollectionsToFail.clear();
  }

  /**
   * Make all push requests to a particular datastore collection fail.
   */
  makeCollectionFailSet<
    C extends DatastoreCollectionType & DatastoreSetCollectionType,
  >(
    collectionName: C['Name'],
    errorCode: SetRequestErrorCode<C> = 'UNKNOWN_ERROR',
  ): void {
    this.storeBackend.setCollectionsToFail.set(collectionName, {
      status: 'error',
      errorCode,
    });
  }

  /**
   * Clear set request fails set by makeCollectionFailSet.
   */
  clearCollectionFailSet(): void {
    this.storeBackend.setCollectionsToFail.clear();
  }

  /**
   * Make all update requests to a particular datastore collection fail.
   */
  makeCollectionFailUpdate<
    C extends DatastoreCollectionType & DatastoreUpdateCollectionType,
  >(
    collectionName: C['Name'],
    errorCode: UpdateRequestErrorCode<C> = 'UNKNOWN_ERROR',
  ): void {
    this.storeBackend.updateCollectionsToFail.set(collectionName, {
      status: 'error',
      errorCode,
    });
  }

  /**
   * Clear update request fails set by markCollectionFailUpdate.
   */
  clearCollectionFailUpdate(): void {
    this.storeBackend.updateCollectionsToFail.clear();
  }

  /**
   * Make all delete requests to a particular datastore collection fail.
   */
  makeCollectionFailDelete<
    C extends DatastoreCollectionType & DatastoreDeleteCollectionType,
  >(
    collectionName: C['Name'],
    errorCode: DeleteRequestErrorCode<C> = 'UNKNOWN_ERROR',
  ): void {
    this.storeBackend.deleteCollectionsToFail.set(collectionName, {
      status: 'error',
      errorCode,
    });
  }

  /**
   * Clear delete request fails set by makeCollectionFailDelete.
   */
  clearCollectionFailDelete(): void {
    this.storeBackend.deleteCollectionsToFail.clear();
  }

  /**
   * Make a request to a collection fail when the collection is empty.
   */
  makeCollectionFailWhenEmpty<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name']): void {
    return this.makeCollectionErrorWhenEmpty(collectionName, {
      status: 'error',
      errorCode: ErrorCodeApi.NOT_FOUND,
    });
  }

  /**
   * Clear request fails set by makeCollectionFailWhenEmpty.
   */
  clearCollectionFailWhenEmpty(): void {
    this.storeBackend.collectionsToFailWhenEmpty.clear();
  }

  /**
   * Make requests to the given collection delayed by the given amount of milliseconds.
   */
  makeCollectionDelayed<C extends DatastoreCollectionType>(
    collectionName: C['Name'],
    delayMilliseconds: number,
    requestType: 'fetch' | 'push' | 'set' | 'update' | 'delete',
  ): void {
    this.storeBackend.collectionsToDelay.set(
      `${collectionName}-${requestType}`,
      delayMilliseconds,
    );
  }

  /**
   * Clear request delays set by makeCollectionDelayed.
   */
  clearCollectionDelayed(): void {
    this.storeBackend.collectionsToDelay.clear();
  }

  /**
   * Make a specific fetch request to the datastore fail.
   */
  makeRequestFail<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(
    collectionName: C['Name'],
    idOrIdsOrQuery: IdOrIdsOrQuery<C>,
    errorCode: FetchRequestErrorCode<C> = 'UNKNOWN_ERROR',
  ): void {
    return this.makeRequestError(collectionName, idOrIdsOrQuery, {
      status: 'error',
      errorCode,
    });
  }

  /**
   * Clear fetch request fails set by makeRequestFail.
   */
  clearRequestFail(): void {
    this.storeBackend.requestsToFail.clear();
  }

  /**
   * Make all fetch requests to a particular datastore collection never return.
   */
  makeCollectionPending<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name']): void {
    return this.makeFetchCollectionError(collectionName, { status: 'pending' });
  }

  /**
   * Clear fetch request fails set by makeCollectionPending.
   */
  clearCollectionPending(): void {
    this.storeBackend.fetchCollectionsToFail.clear();
  }

  /**
   * Make a specific fetch request to the datastore never return.
   */
  makeRequestPending<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name'], idOrIdsOrQuery: IdOrIdsOrQuery<C>): void {
    this.makeRequestError(collectionName, idOrIdsOrQuery, {
      status: 'pending',
    });
  }

  /**
   * Clear fetch request fails set by makeRequestPending.
   */
  clearRequestPending(): void {
    this.storeBackend.requestsToFail.clear();
  }

  refetch(collectionNames: readonly DatastoreCollectionType['Name'][]): void {
    this.refetchCollectionsQueue$.next(collectionNames);
  }

  /**
   * Makes a fetch datastore call to a particular collection error in the specified way
   */
  private makeFetchCollectionError<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name'], error: SimulatedFetchRequestFailure<C>): void {
    this.storeBackend.fetchCollectionsToFail.set(collectionName, error);
  }

  private makeCollectionErrorWhenEmpty<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(collectionName: C['Name'], error: SimulatedFetchRequestFailure<C>): void {
    this.storeBackend.collectionsToFailWhenEmpty.set(collectionName, error);
  }

  private makeRequestError<
    C extends DatastoreCollectionType & DatastoreFetchCollectionType,
  >(
    collectionName: C['Name'],
    idOrIdsOrQuery: IdOrIdsOrQuery<C>,
    error: SimulatedFetchRequestFailure<C>,
  ): void {
    if (isArray(idOrIdsOrQuery)) {
      this.storeBackend.requestsToFail.set(
        this.generateRefKey({
          path: {
            collection: collectionName,
            authUid: '', // Let's keep things simple and not make this user dependent
            ids: idOrIdsOrQuery.map(id => id.toString()),
          },
        }),
        error,
      );
    } else if (
      typeof idOrIdsOrQuery === 'number' ||
      typeof idOrIdsOrQuery === 'string'
    ) {
      this.storeBackend.requestsToFail.set(
        this.generateRefKey({
          path: {
            collection: collectionName,
            authUid: '', // Let's keep things simple and not make this user dependent
            ids: [idOrIdsOrQuery.toString()],
          },
        }),
        error,
      );
    } else {
      const {
        queryParams = {},
        searchQueryParams = {},
        limitValue: limit,
        orderByValue: order,
      } = idOrIdsOrQuery(NonObservableQuery.newQuery());
      this.storeBackend.requestsToFail.set(
        this.generateRefKey({
          path: { collection: collectionName, authUid: '' },
          query: {
            queryParams,
            searchQueryParams,
            isDocumentQuery: false, // This is ignored
            limit,
          },
          order,
        }),
        error,
      );
    }
  }

  /**
   * A request should fail if either:
   *  - the whole collection should fail, OR
   *  - if that specify request should fail
   *
   * The `retry` method is set to clear the error on being called.
   */
  private getCollectionErrorState<C extends DatastoreCollectionType>(
    ref: Reference<C>,
  ): RequestStatus<C> | undefined {
    const collectionFailure = this.storeBackend.fetchCollectionsToFail.get(
      ref.path.collection,
    );
    const requestFailure = this.storeBackend.requestsToFail.get(
      this.generateRefKey(ref),
    );
    const failure = collectionFailure ?? requestFailure;

    if (failure?.status === 'error') {
      return {
        ready: false,
        error: {
          errorCode: failure.errorCode,
          retry: () => {
            if (collectionFailure) {
              this.storeBackend.fetchCollectionsToFail.delete(
                ref.path.collection,
              );
            } else {
              this.storeBackend.requestsToFail.delete(this.generateRefKey(ref));
            }
          },
        } as RequestStatus<C>['error'],
      };
    }

    if (failure?.status === 'pending') {
      return { ready: false };
    }
    return undefined;
  }

  /**
   * A request should fail if:
   *  - the collection should fail when it is empty
   *
   * The `retry` method is set to clear the error on being called.
   */
  private getCollectionErrorStateWhenEmpty<C extends DatastoreCollectionType>(
    ref: Reference<C>,
    source: C['DocumentType'] | undefined,
  ): RequestStatus<C> | undefined {
    const collectionFailureWhenEmpty =
      this.storeBackend.collectionsToFailWhenEmpty.get(ref.path.collection);
    const failure = collectionFailureWhenEmpty;

    if (failure?.status === 'error' && !source) {
      return {
        ready: false,
        error: {
          errorCode: failure.errorCode,
          retry: () => {
            if (collectionFailureWhenEmpty) {
              this.storeBackend.collectionsToFailWhenEmpty.delete(
                ref.path.collection,
              );
            }
          },
        } as RequestStatus<C>['error'],
      };
    }
    if (failure?.status === 'pending') {
      return { ready: false };
    }
    return undefined;
  }

  private generateRefKey<C extends DatastoreCollectionType>(
    ref: Reference<C>,
  ): string {
    return `${ref.path.collection};${stringifyReference(ref)}`;
  }

  private generateClientRequestId(): string {
    return Math.random()
      .toString(36)
      .substring(2, 2 + 16);
  }

  private refetchTriggered(
    collectionName: DatastoreCollectionType['Name'],
  ): Observable<void> {
    return this.refetchCollections$.pipe(
      filter(collections => collections.includes(collectionName)),
      map(() => undefined),
    );
  }
}
