import { HttpClientXsrfModule } from '@angular/common/http';
import { APP_ID, ErrorHandler, NgModule, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { ABTEST_WHITELIST_COOKIE, ABTestOverrides } from '@freelancer/abtest';
import { APPLE_PAY_CONFIG } from '@freelancer/apple-pay';
import { AUTH_CONFIG, Auth, AuthModule } from '@freelancer/auth';
import { BILLING_CONFIG } from '@freelancer/billing';
import { CANVAS_CONFIG } from '@freelancer/canvas';
import { CAPTCHA_CONFIG } from '@freelancer/captcha';
import { ComponentsModalsModule } from '@freelancer/components/components-modals.module';
import {
  APPS_DOMAINS_MAP,
  APP_NAME,
  BLANK_STATE,
  ENVIRONMENT_NAME,
  SITE_NAME,
  USE_FAKES,
} from '@freelancer/config';
import { datastoreActionSanitizer } from '@freelancer/datastore';
import { datastoreInitializer } from '@freelancer/datastore/collections-testing';
import { DatastoreModule } from '@freelancer/datastore/core/datastore.module';
import { DatastoreFakeModule } from '@freelancer/datastore/testing';
import { FACEBOOK_CONFIG } from '@freelancer/facebook';
import { FileDownloadModule } from '@freelancer/file-download';
import { FileUploadLegacyModule } from '@freelancer/file-upload-legacy';
import {
  FREELANCER_HTTP_ABTEST_OVERRIDES_PROVIDER,
  FREELANCER_HTTP_AUTH_PROVIDERS,
  FREELANCER_HTTP_CONFIG,
  FREELANCER_HTTP_TRACKING_PROVIDER,
  FreelancerHttp,
} from '@freelancer/freelancer-http';
import { BackgroundGeolocation } from '@freelancer/geolocation';
import { GOOGLE_MAPS_CONFIG } from '@freelancer/google-maps';
import { GOOGLE_SIGN_IN_CONFIG } from '@freelancer/google-sign-in';
import { InAppBrowser, InAppBrowserModule } from '@freelancer/in-app-browser';
import {
  NATIVE_LANGUAGE_COOKIE,
  WEBAPP_LANGUAGE_COOKIE,
} from '@freelancer/localization';
import {
  FREELANCER_LOCATION_AUTH_PROVIDER,
  FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER,
  FREELANCER_LOCATION_IN_APP_BROWSER_PROVIDER,
  FREELANCER_LOCATION_PWA_PROVIDER,
  FREELANCER_LOCATION_TRACKING_CONSENT_PROVIDER,
  Location,
} from '@freelancer/location';
import { LOGIN_CONFIG } from '@freelancer/login';
import { LoginSignupModule } from '@freelancer/login-signup';
import { MESSAGING_CONFIG } from '@freelancer/messaging-chat';
import { FREELANCER_PWA_TRACKING_PROVIDER, Pwa } from '@freelancer/pwa';
import { FlRouteReuseStrategy } from '@freelancer/route-reuse-strategy';
import { SEO_CONFIG } from '@freelancer/seo';
import { ThreatmetrixModule } from '@freelancer/threatmetrix';
import {
  CustomErrorHandler,
  TRACKING_CONFIG,
  Tracking,
  TrackingConsent,
  TrackingModule,
} from '@freelancer/tracking';
import { UiModule } from '@freelancer/ui';
import { FileViewerModule } from '@freelancer/ui/file-viewer';
import { ModalModule } from '@freelancer/ui/modal';
import { UiModalsModule } from '@freelancer/ui/ui-modals.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import type { ActionSanitizer } from '@ngrx/store-devtools/src/config';
import { getDomainsMap } from 'applications';
import { COOKIE_OPTIONS, CookieModule } from 'ngx-cookie';
import { environment } from '../environments/environment';
import { fakeProviders } from '../environments/fakes';
import { AppModalsModule } from './app-modals.module';
import { cookieOptionsFactory } from './cookie-options.factory';
/* The type for ActionSanitizer doesn't allow a custom action type.
 * ActionSanitizer probably should be
 *  export declare type ActionSanitizer<A extends Action = Action> = (
 *    action: A,
 *    id: number,
 *  ) => A;
 * but it isn't so let's cast through `any` for now.
 */

/* The type for ActionSanitizer doesn't allow a custom action type.
 * ActionSanitizer probably should be
 *  export declare type ActionSanitizer<A extends Action = Action> = (
 *    action: A,
 *    id: number,
 *  ) => A;
 * but it isn't so let's cast through `any` for now.
 */
const actionSanitizer: ActionSanitizer = datastoreActionSanitizer as any;

const disableAnimations =
  (globalThis.document &&
    !('animate' in (globalThis.document as any).documentElement)) ||
  (globalThis.navigator &&
    /iPhone OS (8|9|10|11|12|13)_/.test(
      (globalThis.navigator as any).userAgent,
    ));

// /!\ DO NOT ADD ANYTHING IN THERE WITHOUT TALKING TO FRONTEND INFRA FIRST /!\
// Application-wide code should be avoided as much as possible; does your code
// needs:
// - to be loaded on ALL logged-in pages?
// - AND to be loaded on ALL logged-out pages?
// - AND to be loaded on the Admin?
// - ...
// Probably not.
@NgModule({
  imports: [
    AuthModule,
    BrowserAnimationsModule.withConfig({ disableAnimations }),
    FormsModule,
    HttpClientXsrfModule.disable(), // no part of the app should rely on that
    AppModalsModule,
    ComponentsModalsModule,
    InAppBrowserModule,
    UiModalsModule,
    GoogleMapsModule,
    UiModule.initialize({
      assetsBaseUrl: environment.uiConfig.assetsBaseUrl,
      theme: environment.uiConfig.theme,
      lightGalleryLicenseKey: environment.lightGallery.licenseKey,
    }),
    ModalModule,
    CookieModule.withOptions(),
    ThreatmetrixModule.initialize({
      profilingDomain: environment.threatmetrixConfig.profilingDomain,
      orgId: environment.threatmetrixConfig.orgId,
    }),
    environment.useFakes
      ? environment.blankState
        ? DatastoreFakeModule.initialize({
            debug: true,
            initializer: datastoreInitializer({ blankState: true }),
          })
        : DatastoreFakeModule.initialize({
            debug: true,
            initializer: datastoreInitializer(),
          })
      : DatastoreModule.initialize({
          webSocketUrl: environment.datastoreConfig.webSocketUrl,
          enableStoreFreeze: environment.datastoreConfig.enableStoreFreeze,
          httpAdapter: FreelancerHttp,
          requestData: {},
        }),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 150,
          actionSanitizer,
          actionsBlocklist: ['LOCAL_CACHE_FETCH_SUCCESS'],
        })
      : [],
    TrackingModule,
    FileUploadLegacyModule,
    LoginSignupModule,
    FileDownloadModule,
    FileViewerModule,
  ],
  providers: [
    { provide: APP_ID, useValue: 'webapp' },
    {
      provide: RouteReuseStrategy,
      useClass: FlRouteReuseStrategy,
    },
    {
      provide: AUTH_CONFIG,
      useValue: {
        baseUrl: environment.authConfig.baseUrl,
        switchAccountHackyEndpoint:
          environment.authConfig.switchAccountHackyEndpoint,
        authHashCookie: environment.authConfig.authHashCookie,
        userIdCookie: environment.authConfig.userIdCookie,
        csrfCookie: environment.authConfig.csrfCookie,
        gdprCookie: environment.authConfig.gdprCookie,
        authHeaderName: environment.authConfig.authHeaderName,
        referralCookie: environment.authConfig.referralCookie,
        authIosKeychainToken: environment.authConfig.authIosKeychainToken,
      },
    },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: CAPTCHA_CONFIG,
      useValue: {
        recaptchaPublicKey: environment.captchaConfig.recaptchaPublicKey,
      },
    },
    {
      provide: COOKIE_OPTIONS,
      deps: [PLATFORM_ID, Location],
      useFactory: cookieOptionsFactory,
    },
    { provide: USE_FAKES, useValue: environment.useFakes },
    { provide: BLANK_STATE, useValue: environment.blankState },
    { provide: SITE_NAME, useValue: environment.siteName },
    {
      provide: SEO_CONFIG,
      useValue: {
        linkTags: {
          rss: {
            rel: environment.seoConfig.linkTags.rss.rel,
            type: environment.seoConfig.linkTags.rss.type,
            title: environment.seoConfig.linkTags.rss.title,
            href: environment.seoConfig.linkTags.rss.href,
          },
        },
        metaTags: {
          robots: {
            name: environment.seoConfig.metaTags.robots.name,
            content: environment.seoConfig.metaTags.robots.content,
          },
          alexa: {
            name: environment.seoConfig.metaTags.alexa.name,
            content: environment.seoConfig.metaTags.alexa.content,
          },
          bing: {
            name: environment.seoConfig.metaTags.bing.name,
            content: environment.seoConfig.metaTags.bing.content,
          },
        },
        defaultMetaImage: environment.seoConfig.defaultMetaImage,
      },
    },
    {
      provide: GOOGLE_MAPS_CONFIG,
      useValue: {
        apiKey: environment.mapsConfig.apiKey,
      },
    },
    {
      provide: APPS_DOMAINS_MAP,
      useValue: getDomainsMap(environment.environmentName),
    },
    { provide: ENVIRONMENT_NAME, useValue: environment.environmentName },
    { provide: APP_NAME, useValue: environment.appName },
    {
      provide: FREELANCER_HTTP_CONFIG,
      useValue: {
        baseUrl: environment.freelancerHttpConfig.baseUrl,
        apiBaseUrl: environment.freelancerHttpConfig.apiBaseUrl,
        ajaxBaseUrl: environment.freelancerHttpConfig.ajaxBaseUrl,
        authBaseUrl: environment.freelancerHttpConfig.authBaseUrl,
        fileServiceBaseUrl: environment.freelancerHttpConfig.fileServiceBaseUrl,
      },
    },
    { provide: FREELANCER_HTTP_AUTH_PROVIDERS, useExisting: Auth, multi: true },
    {
      provide: FREELANCER_HTTP_ABTEST_OVERRIDES_PROVIDER,
      useExisting: ABTestOverrides,
    },
    {
      provide: FREELANCER_HTTP_TRACKING_PROVIDER,
      useExisting: Tracking,
    },
    {
      provide: FREELANCER_LOCATION_AUTH_PROVIDER,
      useExisting: Auth,
    },
    {
      provide: FREELANCER_LOCATION_HTTP_BASE_URL_PROVIDER,
      useValue: environment.freelancerHttpConfig.baseUrl,
    },
    {
      provide: FREELANCER_LOCATION_PWA_PROVIDER,
      useExisting: Pwa,
    },
    {
      provide: FREELANCER_LOCATION_TRACKING_CONSENT_PROVIDER,
      useExisting: TrackingConsent,
    },
    {
      provide: FREELANCER_PWA_TRACKING_PROVIDER,
      useExisting: Tracking,
    },
    {
      provide: FREELANCER_LOCATION_IN_APP_BROWSER_PROVIDER,
      useExisting: InAppBrowser,
    },
    { provide: ABTEST_WHITELIST_COOKIE, useValue: environment.whitelistCookie },
    {
      provide: FACEBOOK_CONFIG,
      useValue: {
        appId: environment.facebookConfig.appId,
      },
    },
    {
      provide: GOOGLE_SIGN_IN_CONFIG,
      useValue: {
        clientId: environment.googleSignInConfig.clientId,
      },
    },
    {
      provide: BILLING_CONFIG,
      useValue: {
        baseUrl: environment.billingConfig.baseUrl,
      },
    },
    {
      provide: WEBAPP_LANGUAGE_COOKIE,
      useValue: environment.languageCookies.webappCookie,
    },
    {
      provide: NATIVE_LANGUAGE_COOKIE,
      useValue: environment.languageCookies.nativeCookie,
    },
    {
      provide: LOGIN_CONFIG,
      useValue: {
        loggedOutRestricted: environment.loginConfig.loggedOutRestricted,
        defaultLogoutRedirectUrl:
          environment.loginConfig.defaultLogoutRedirectUrl,
        ssoIntegration: environment.loginConfig.ssoIntegration,
      },
    },
    {
      provide: MESSAGING_CONFIG,
      useValue: {
        aiChatUserId: environment.messaging.aiChatUserId,
      },
    },
    {
      provide: TRACKING_CONFIG,
      useValue: {
        trackingEndpoint: environment.trackingConfig.trackingEndpoint,
        linkSessionsEndpoint: environment.trackingConfig.linkSessionsEndpoint,
        sessionCookie: environment.trackingConfig.sessionCookie,
        linkedSessionCookie: environment.trackingConfig.sessionCookie,
        clientKey: environment.trackingConfig.clientKey,
        navigationPerformanceSamplingRate:
          environment.trackingConfig.navigationPerformanceSamplingRate,
        enableErrorTracking: environment.trackingConfig.enableErrorTracking,
        sentryDsn: environment.trackingConfig.sentryDsn,
        kibanaBaseUrl: environment.trackingConfig.kibanaBaseUrl,
        gaTrackingId: environment.trackingConfig.gaTrackingId,
        ga4TrackingId: environment.trackingConfig.ga4TrackingId,
        awGoogleConversionId: environment.trackingConfig.awGoogleConversionId,
        facebookPixelId: environment.trackingConfig.facebookPixelId,
        tracesSampleRate: environment.trackingConfig.tracesSampleRate,
        gaCustomDimensionMap: {
          dimension1:
            environment.trackingConfig.gaCustomDimensionMap.dimension1,
          dimension2:
            environment.trackingConfig.gaCustomDimensionMap.dimension2,
          dimension3:
            environment.trackingConfig.gaCustomDimensionMap.dimension3,
        },
        gAdsTrackingId: environment.trackingConfig.gAdsTrackingId,
        gAdsAllowedDomain: environment.trackingConfig.gAdsAllowedDomain,
        linkedInPixelId: environment.trackingConfig.linkedInPixelId,
        quoraPixelId: environment.trackingConfig.quoraPixelId,
        hotjarId: environment.trackingConfig.hotjarId,
        tiktokPixelId: environment.trackingConfig.tiktokPixelId,
      },
    },
    {
      provide: CANVAS_CONFIG,
      useValue: {
        canvasHost: environment.canvasHost,
      },
    },
    {
      provide: APPLE_PAY_CONFIG,
      useValue: {
        merchantIdentifier: environment.applePayConfig.merchantIdentifier,
      },
    },
    BackgroundGeolocation,
    // override services when UseFakes
    // This should be kept mostly consistent with the service overrides in
    // `ui-tests-common/src/setup.ts`.
    ...fakeProviders,
  ],
  exports: [BrowserModule, ModalModule, TrackingModule, UiModule],
})
export class AppCommonModule {}
